import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import {
  Container,
  Grid,
  Box,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import "./ImpressYourClient.css";

import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import CodeIcon from "@mui/icons-material/Code";
import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import SouthEastIcon from "@mui/icons-material/SouthEast";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import SwipeLeftIcon from "@mui/icons-material/SwipeLeft";
import PublicIcon from "@mui/icons-material/Public";

const useStyle = makeStyles((theme) => ({
  WebDevWrapper: {
    marginTop: "8rem",
  },
  WebDevLandingContainer: {
    display: "flex",
    alignItems: "center",
  },
  WebDevLandingHeadingContainer: {
    maxWidth: "50%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "92%",
    },
  },
  WebDevLandingParaContainer: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: "85%",
    },
  },
  HireButton: {
    marginTop: "20px",
    borderRadius: "20px",
  },
  WebDevLandingImg: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  WebDevServiceContainer: {
    // margin: "2rem 0 0 0",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    marginTop: "50px",
    marginBottom: "50px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  WebDevImpressClientContainer: {
    maxWidth: "42%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },

  impresNewClientFlowImg: {
    width: "82vw",
    maxWidth: "1028px",
  },
  Imagebox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "1rem 0rem 2rem",
  },
  WebDevServiceList: {
    marginTop: "1rem",
    paddingLeft: "1rem",
    // listStyle: 'none',
  },
  accordian: {
    margin: "0 0 1rem 0",
  },
  hiredHeader: {
    fontFamily: " 'Ubuntu', sans-serif",
    marginLeft: "10px",
  },
  hiredHeaderSerialNum: {
    color: "#3D8ED7",
    fontFamily: " 'Ubuntu', sans-serif",
  },
  webDevSolnContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    margin: "3rem 0 0 0",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },

  webDevSolnAccordianBlock: {
    maxWidth: "40%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  WebDevSolnList: {
    fontFamily: " 'Poppins', serif",
    fontSize: "14px",
  },
  WebDevListitem: {
    fontWeight: "bold",
    padding: "0.5rem",
    // cursor: "pointer",
  },
  mainContainerWrapper: {
    padding: "3rem 0rem 3rem 0rem",
    background: "#3D8ED7",
  },
  ImageWrapper: {
    display: "flex",
    padding: "0rem 7rem",
    justifyContent: "space-around",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  Image: {
    width: "100%",
    height: "auto",
    objectFit: "contain",
  },
  MobileViewContainer: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-around",
    },
  },
  contentWraper: {
    color: "White",
    paddingTop: "3rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
      padding: "1rem 0rem",
      textAlign: "center",
    },
  },
  mainWrapper: {
    padding: "2rem 7rem",
    [theme.breakpoints.down("sm")]: {
      padding: "2rem",
    },
  },
  title: {
    fontFamily: "'Ubuntu', sans-serif",
    paddingBottom: "4rem",
  },
  wrapper: {
    fontSize: "2rem",
    fontFamily: " 'Ubuntu', sans-serif",
    textAlign: "justify",
    padding: "0px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  newDesk: {
    width: "90%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  parawrappar: {
    fontSize: "14px",
    textAlign: "justify",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
}));

const ImpressYourClient = ({
  ImpressYourClientTitle,
  ImpressYourClientPara,
  ImpressYourClientPoints,
  DevelopmentSolutionsList,
  ImpressYourClientImage,
  DevelopmentSolutionsTitle,
}) => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  const classes = useStyle();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <Container maxWidth="lg" className={classes.mainWrapper}>
        <div className="header_Specialised">
          <h2>Our Highly Specialised Outsourcing Categories</h2>
          <p>
            Our tailor-made business software solutions comprehensively offers
            services to multiple businesses
          </p>
        </div>

        <Box className={classes.Imagebox}>
          <img
            src={ImpressYourClientImage}
            alt="impression"
            title="Impress Your Client"
            className={classes.impresNewClientFlowImg}
          />
        </Box>

        <section>
          <div className="container soft-container">
            <div className="row soft-row">
              <div className="col-lg-4 col-md-6 col-sm-12 soft-col">
                <div className="card">
                  <CodeIcon
                    style={{ height: "51px", width: "60px", color: "gray" }}
                  />
                  <h3 className="header-h3">Web Development Outsourcing</h3>
                  <p>
                    Senwell Group Private Limited Leading web development
                    company delivering top-notch solutions. Our team excels in
                    robust website design, development, and SEO-friendly
                    features. Enhance your brand reputation and online presence
                    with us, the trusted web development outsourcing company.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 soft-col">
                <div className="card">
                  <DeveloperModeIcon
                    style={{ height: "51px", width: "60px", color: "gray" }}
                  />
                  <h3 className="header-h3">Digital Marketing Outsourcing</h3>
                  <p>
                    Senwell Group Private Limited Expert digital marketing
                    outsourcing. Boost revenue by converting targets from
                    awareness to purchase. Services: SEO, PPC, email marketing,
                    reputation management, social media. Increase online sales
                    by reaching consumers searching for your products/services.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 soft-col">
                <div className="card">
                  <SouthEastIcon
                    style={{ height: "51px", width: "60px", color: "gray" }}
                  />
                  <h3 className="header-h3">Software Outsourcing</h3>
                  <p>
                    Senwell Group Private Limited Leading Pune outsourcing
                    company offering tailored software solutions. Overcome
                    challenges, establish relationships. Services include ERP,
                    enhancing online presence. Trusted in diverse industries.
                    Expertise in delivery teams, staff augmentation, and
                    software outsourcing. Unlock sales potential with us
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 soft-col">
                <div className="card">
                  <PhoneAndroidIcon
                    style={{ height: "51px", width: "60px", color: "gray" }}
                  />
                  <h3 className="header-h3">
                    Mobile App Development Outsourcing
                  </h3>
                  <p>
                    Senwell Group Private Limited excels in native Android,
                    hybrid app, and gaming app development. With expertise in
                    location-based apps like Uber, we have 10+ years of
                    experience, serving 500+ global clients. As a leading mobile
                    app development company in Pune,We deliver top-notch
                    solutions to elevate your business.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 soft-col">
                <div className="card">
                  <SwipeLeftIcon
                    style={{ height: "51px", width: "60px", color: "gray" }}
                  />
                  <h3 className="header-h3">
                    Strategic Consulting Outsourcing{" "}
                  </h3>
                  <p>
                    Senwell Group Private Limited Global consulting firm
                    specializing in strategic outsourcing. We optimize ROI,
                    speed, and risk mitigation. With expertise in app
                    development, web, cloud, BI, QA, IoT, and more, we help
                    upgrade your business productivity. Trust our strategic
                    solutions to drive success.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 soft-col">
                <div className="card">
                  <PublicIcon
                    style={{ height: "51px", width: "60px", color: "gray" }}
                  />
                  <h3 className="header-h3">
                    Servers and Hosting Outsourcing{" "}
                  </h3>
                  <p>
                    Senwell Group Private Limited Pune's leading web server
                    hosting company offering outsourced server management and
                    support. Lower costs, higher ROI, and up-to-date expertise.
                    Trust our experienced IT professionals for full technical
                    and network support, reducing operational costs.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Box className={classes.WebDevServiceContainer}>
          <Box className={classes.WebDevImpressClientContainer}>
            <Typography variant="h4" className={classes.wrapper}>
              {ImpressYourClientTitle}
            </Typography>
            <p className={classes.parawrappar} style={{ margin: "1rem 0 0" }}>
              {ImpressYourClientPara}
            </p>
          </Box>
          <Box>
            <ul className={classes.WebDevServiceList}>
              {ImpressYourClientPoints?.map((val) => (
                <>
                  <Typography className={classes.WebDevListitem}>
                    <li>{val.li}</li>
                  </Typography>
                </>
              ))}
            </ul>
          </Box>
        </Box>
      </Container>
      <Container maxWidth="lg" className={classes.mainWrapper}>
        <div className={classes.newDesk}>
          <Typography align="center" variant="h4" className={classes.title}>
            {DevelopmentSolutionsTitle}
          </Typography>
          <Grid container spacing={2} style={{ width: "100%" }}>
            {DevelopmentSolutionsList?.map((value, index) => (
              <>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Accordion
                    expanded={expanded === index}
                    onChange={handleChange(index)}
                    className={classes.accordian}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === index ? (
                          <RemoveIcon style={{ color: "#3D8ED7" }} />
                        ) : (
                          <AddIcon
                            style={{ color: "#3D8ED7" }}
                            className={classes.Icons}
                          />
                        )
                      }
                      aria-controls={value.ariaControls}
                      id={value.id}
                    >
                      <Typography>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span className={classes.hiredHeaderSerialNum}>
                            {value.Number}
                          </span>
                          <Typography
                            variant="h6"
                            className={classes.hiredHeader}
                          >
                            {value.AccordianSummary}
                          </Typography>
                        </div>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography className={classes.WebDevSolnList}>
                        {value.AccordianDetails}
                        <ul className={classes.WebDevServiceList}>
                          {value.List1 && (
                            <a
                              href="#"
                              style={{
                                textDecoration: "underline",
                                fontWeight: 400,
                                fontFamily: " 'Lora', serif",
                                fontSize: "14px",
                                lineHeight: "24px",
                              }}
                            >
                              <li style={{ fontWeight: 400 }}>{value.List1}</li>
                            </a>
                          )}

                          {value.list2 && (
                            <a
                              href="#"
                              style={{
                                textDecoration: "underline",
                                fontWeight: 400,
                                fontFamily: " 'Lora', serif",
                                fontSize: "16px",
                              }}
                            >
                              <li
                                style={{
                                  fontWeight: "bold",
                                  fontFamily: " 'Lora', serif",
                                  fontSize: "14px",
                                }}
                              >
                                {value.List2}
                              </li>
                            </a>
                          )}

                          {value.list3 && (
                            <a
                              href="#"
                              style={{
                                textDecoration: "underline",
                                fontWeight: 400,
                                fontFamily: " 'Lora', serif",
                                fontSize: "14px",
                              }}
                            >
                              <li
                                style={{
                                  fontWeight: 400,
                                  fontFamily: " 'Lora', serif",
                                  fontSize: "14px",
                                }}
                              >
                                {value.List3}
                              </li>
                            </a>
                          )}
                        </ul>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </>
            ))}
          </Grid>
        </div>
      </Container>
    </>
  );
};

export default ImpressYourClient;
