import React from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import "../../../Modules/Company/Careers/Growth.css";
import People1 from "../../../images/Careers/People3.png";
import People2 from "../../../images/Careers/People4.png";
import LifeAt1 from "../../../images/Careers/LifeAt1.png";
import LifeAt2 from "../../../images/Careers/LifeAt2.png";
import "./styles.css";
import NewImage1 from "../../../images/Careers/NewImage1.webp";

const useStyles = makeStyles((theme) => ({
  mainContWrapper: {
    margin: "1rem 0",
    background: "#f6f6f6",
  },
  mainContainer: {
    padding: "0rem 5rem 4rem 5rem",
    paddingTop: "2rem",
    width: "90%",
    margin: "auto",
    // border: "1px solid #3D8ED7",
    marginBottom: "1rem",
    [theme.breakpoints.down("sm")]: {
      padding: "2rem",
      width: "100%",
    },
  },
  ImageWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  Image: {
    width: "100%",
    // height: "295px",
    objectFit: "cover",
    backgroundPosition: "center",
  },

  container: {
    border: "1px solid #3D8ED7",
    margin: "8px",
  },

  subtitle: {
    padding: "1rem",
    fontFamily: "'Manrope', sans-serif",
    fontSize: "14px",
  },
  overlaytitle: {
    padding: "1rem 0 0 0 ",
    color: "#000",
    fontFamily: "'Manrope', sans-serif",
    fontSize: "20px",
    margin: "0",
    textAlign: "center",
  },
  Details: {
    color: "white",
  },
  MainRoot: {
    backgroundColor: "#15315e",
    padding: "1rem 4rem ",
  },
  StatusInformation: {
    paddingTop: "1rem",
  },

  title: {
    // padding:"1rem",
    fontFamily: "'Manrope', sans-serif",
    textAlign: "center",
  },
  para: {
    fontFamily: "'Manrope', sans-serif",
    fontSize: "16px",
    textAlign: "center",
  },
  sectionpara: {
    fontFamily: "'Manrope', sans-serif",
    fontSize: "16px",
    textAlign: "left,",
    paddingRight: "1rem",
  },
}));

const Growth = () => {
  const classNamees = useStyles();
  return (
    <>
      <section style={{ backgroundColor: "#f6f6f6", padding: "50px 0px" }}>
        <div
          id="meetus"
          className="container-fluid"
          style={{ left: "0", border: "none", width: "80%" }}
        >
          <h4 className="text-center Adventure-heading">
            Let's Join Us In This Adventure
          </h4>
          <p className="heading-p">
          Senwell Group Private Limited is one of the best places to start your career and
            develop your skills. We welcome fresh graduates, people who are
            passionate even if they do not have any work experience.
          </p>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <h1 className="firstCalture-head">People First Culture</h1>
              <p className="firstCulture-para">
                <b>
                  Shining Star of Success: Meet Our First-Ever Employee of the
                  Year Awardee!
                </b>
                On November 26, 2022, as we marked the inspiring milestone of
                our 2nd Foundation Day, the spirit of achievement and commitment
                was palpable at Senwell Group Private Limited. Amidst the joyous celebration
                of two years of progress and innovation, we had the distinct
                honor of recognizing and applauding an exceptional individual
                who has truly exemplified our company's ethos of excellence and
                dedication.
              </p>

              <p className="firstCulture-para">
                In a gesture that reflects our appreciation for outstanding
                contributions, we presented our distinguished 'Employee of the
                Year' with the keys to a brand-new Swift Car. Just as the Swift
                Car is renowned for its speed, efficiency, and reliability, our
                awardee has consistently exhibited these qualities in their
                journey with us. As they embark on new roads of success, we are
                delighted to provide them with a vehicle that mirrors their
                drive and determination.
              </p>
              <p className="firstCulture-para">
                For our 3rd foundation day festivities, 2 of our employees were
                gifted bikes for their outstanding dedication, exceptional
                contributions, and unwavering commitment, during the Award
                Ceremony for 2023. This prestigious accolade is bestowed upon
                them who have not only demonstrated professional excellence but
                has also significantly enriched our workplace with their
                remarkable skills, leadership, and positive influence. Their
                journey exemplifies the spirit of excellence that defines our
                organization, and we look forward to witnessing their continued
                impact on our collective success.
              </p>
              <p className="firstCulture-para">
                It's moments like these that remind us of the strength of our
                team. We have proven that when we work together, there's no
                limit to what we can achieve. Our success is not only a
                testament to our individual skills but also to the spirit of
                teamwork and unity that defines us. We carry this momentum
                forward as we embark on new challenges and opportunities every
                year. We have no doubt that the same spirit of collaboration and
                determination that led to our current success will guide us to
                even greater heights in the future.
              </p>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 sportsMain">
              <div className="image-card">
                <img
                  src={NewImage1}
                  style={{
                    width: "90%",
                    borderRadius: "0.5rem",
                    marginBottom: "1.5rem",
                  }}
                  alt="NewImage1"
                  title="swift car gift"
                />
                <img
                  src={People1}
                  style={{
                    width: "90%",
                    borderRadius: "0.5rem",
                    marginBottom: "1.5rem",
                  }}
                  alt="People1"
                  title="royal enfield gift"
                />
                <img
                  src={People2}
                  style={{ width: "90%", borderRadius: "0.5rem" }}
                  alt="People2"
                  title="developer car gift"
                />
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: "100px" }}>
            <div className="col-lg-6 col-md-12 col-sm-12 ">
              <div className="lifeatSenwell-card">
                <img
                  src={LifeAt1}
                  style={{
                    width: "90%",
                    borderRadius: "0.5rem",
                    marginBottom: "1.5rem",
                  }}
                  title="group photo"
                  alt="NewImage3"
                />
                <img
                  src={LifeAt2}
                  style={{ width: "90%", borderRadius: "0.5rem" }}
                  alt="NewImage2"
                  title="tree plantation group photo"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 lifeStyleSenwell">
              <div className="header" style={{ width: "88%" }}>
                <h1
                  className="firstCalture-head"
                  style={{ textAlign: "start !important" }}
                >
                  Life at Senwell
                </h1>
              </div>
              <p className="lifeatSenwell-para">
              Senwell Group Private Limited  firmly believes in the correlation between the
                company's progress and the personal growth of its team. We
                foster an open-door culture that encourages self-improvement for
                every member of our family. Life at Senwell is a delightful
                blend of productive work and enjoyable moments.
              </p>
              <p className="lifeatSenwell-para">
                In this vibrant ecosystem, life at Senwell is a delightful
                fusion of meaningful work and enjoyable experiences. We
                understand that when our team members thrive and develop their
                skills, the company flourishes as well. With this shared vision,
                we embark on each new project, ensuring that it not only
                delivers value to our clients but also provides our team with
                ample opportunities to learn, grow, and excel.
              </p>
              <p className="lifeatSenwell-para">
                Join us at Senwell Group Private Limited, where you'll find the perfect
                balance between work and fun, and where every project is a
                stepping stone to unleash your potential and maximize your
                impact.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Grid className={classNamees.MainRoot}>
        <Container maxWidth="lg">
          <Grid container className={classNamees.StatusInformatio}>
            <Grid
              xl={12}
              sm={4}
              md={4}
              lg={4}
              className={classNamees.StatusInformation}
            >
              <div className={classNamees.Details}>
                <h2 className={classNamees.heading}>1000+</h2>
                <Typography variant="h6" className={classNamees.sectionpara}>
                  Completed project
                </Typography>
                <p className={classNamees.sectionpara}>
                  19+ years of working on exciting projects to ensure the
                  engagement and development of our engineers.
                </p>
              </div>
            </Grid>
            <Grid
              xl={12}
              sm={4}
              md={4}
              lg={4}
              className={classNamees.StatusInformation}
            >
              <div className={classNamees.Details}>
                <h2 className={classNamees.heading}>500+</h2>
                <Typography variant="h6" className={classNamees.sectionpara}>
                  Dedicated experts
                </Typography>
                <p className={classNamees.sectionpara}>
                  Join a diverse team of experienced professionals with broad
                  skills and knowledge in various domains.
                </p>
              </div>
            </Grid>
            <Grid
              xl={12}
              sm={4}
              md={4}
              lg={4}
              className={classNamees.StatusInformation}
            >
              <div className={classNamees.Details}>
                <h2 className={classNamees.heading}>87%</h2>
                <Typography variant="h6" className={classNamees.sectionpara}>
                  Employee satisfaction
                </Typography>
                <p className={classNamees.sectionpara}>
                  We help our employees build thriving, long-term careers. 40%
                  of our team has been with us for 5+ years!
                </p>
              </div>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </>
  );
};

export default Growth;
