import React from 'react';
import "./DigitalTask.css"
const DigitalTask = () => {
  return (
    <section style={{ backgroundColor: 'rgb(21,49,94)' }}>
      <div className="container marketing-container">
        <h3 className='headingName'>How Digital Marketing Agency Works</h3>
        <div className="row marketing-row" style={{ columnGap: '38px' }}>
          <div className="col-lg-2 col-md-4 col-sm-6 marketing-col">
            <div className="card">
              <img src="https://freerangestock.com/sample/65224/business-and-financial-research-reports-and-statistics--concept.jpg" alt="Research" className="card-img" title='Research'/>
              <h5 className="card-title">Research</h5>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 marketing-col">
            <div className="card">
              <img src="https://img.freepik.com/premium-photo/graphic-designer-woman-working-laptop_72104-3294.jpg" alt="Design" className="card-img" title='Design'/>
              <h5 className="card-title">Design</h5>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 marketing-col">
            <div className="card">
              <img src="https://www.acquisition-international.com/wp-content/uploads/2021/11/ERP-System.jpg" alt="Implement" className="card-img" title='Implement'/>
              <h5 className="card-title">Implement</h5>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 marketing-col">
            <div className="card">
              <img src="https://www.apexon.com/wp-content/uploads/2021/06/Software-Quality-Metrics-Matter-Blog-Img.jpg" alt="Measure" className="card-img" title='Measure'/>
              <h5 className="card-title">Measure</h5>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 marketing-col">
            <div className="card">
              <img src="https://thumbs.dreamstime.com/b/saas-software-as-service-cloud-internet-search-engine-optimization-result-web-vector-98179725.jpg" alt="Result" className="card-img" title='Result'/>
              <h5 className="card-title">Result</h5>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DigitalTask;