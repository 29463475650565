import React from "react";
import "./DevOps.css";
import { DevopsData } from "../../../API/ServicesApi";
import GetInTouch from "../../../SharedModule/GetInTouch";
import HeroBanner from "../../../Modules/Bannars/Mobile";
import ImpressYourClient from "../../../SharedModule/ImpressYourClient";
import TechnologiesExperties from "../../../SharedModule/TechnologiesExperties";
import GetInTouchSecondBanner from "../../../SharedModule/GetInTouchSecondBanner";
import Lottie from "react-lottie";
import animationData from "../../../assets/DevopsAnimation.json";
import Managed from "../../../images/Services/Managed-DevOps.svg";
import Infrastructure from "../../../images/Services/DevOps-Infrastructure-Automation.svg";
import Cloude from "../../../images/Services/Cloud-Computing.svg";
import Continuous from "../../../images/Services/integration.svg";
import Automation from "../../../images/Services/DevOps-Automation-Service.svg";
import MicroService from "../../../images/Services/Microservices-in-DevOps.svg";
import DeveopsProcess from "../../../images/Services/DevOps-Process.png";
import { Link } from "react-router-dom";

const DevOps = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div>
      <section className="DevopsHeader-sections">
        <div className="container" style={{ left: "0", border: "none" }}>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 Devops-banner-col">
              <div className="Devops-banner-content">
                <h1>Devops Consulting</h1>
                <p className="sub-p">
                  Elevate your company's productivity with our DevOps
                  implementation service. Streamline workflows, boost
                  collaboration, and deliver high-quality software faster.
                  Experience improved efficiency and reduced time-to-market.
                  Contact us now for transformative DevOps solutions!.
                </p>
                <div className="Devop-banner-button">
                  <Link to="/contact">
                    <a className="Devops-theme-btn">Let's Talk</a>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 devops-image">
              <div className="image">
                <Lottie options={defaultOptions} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section style={{ padding: "50px 0px" }}>
        <div
          className="container web-container"
          style={{ left: "0", border: "none" }}
        >
          <h2 className="text-center Dev-header">
            DevOps Engineering Services
          </h2>
          <p
            style={{ textAlign: "justify", width: "90%" }}
            className="Devops-web-para"
          >
            At Senwell Group Private Limited, we excel in DevOps development, combining
            software expertise with automation. Opting for DevOps brings reduced
            project time, cost savings, reliability, stability, scalability, and
            security. Our capable team optimizes, monitors, and adjusts
            functionalities swiftly using cutting-edge tools. Experience a
            unified approach to software development, empowering your business
            for growth and success. Contact us to unlock DevOps' potential, gain
            efficiency, and stay ahead in the market.
          </p>
        </div>
      </section>

      <section
        className="section_part"
        style={{ height: "100%", padding: "50px 0px" }}
      >
        <div
          className="container container1"
          style={{ left: "0", border: "none" }}
        >
          <div className="row" style={{ rowGap: "20px" }}>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="service-widget" style={{ height: "52vh" }}>
                <div className="sw-head">
                  <div className="sw-logo">
                    <img src={Managed} alt="logo" title="services" />
                  </div>
                  <div className="sw-title">Managed DevOps Services</div>
                </div>
                <div className="sw-content">
                  <p>
                    Explore the wonders of DevOps with our leading solutions
                    company. We merge software and IT practices to scale your
                    business and better serve customers. Focus on your clients
                    while we optimize your operations.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="service-widget">
                <div className="sw-head">
                  <div className="sw-logo">
                    <img src={Infrastructure} alt="Infrastructure" title="devops Infrastructure"/>
                  </div>
                  <div className="sw-title">
                    DevOps Infrastructure Automation
                  </div>
                </div>
                <div className="sw-content">
                  <p>
                    Our DevOps services optimize software development and
                    integration for your infrastructure. We monitor performance,
                    making necessary improvements to enhance functionality and
                    productivity.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="service-widget">
                <div className="sw-head">
                  <div className="sw-logo">
                    <img src={Cloude} alt="cloud" title="cloud computing"/>
                  </div>
                  <div className="sw-title">Cloud Computing</div>
                </div>
                <div className="sw-content">
                  <p>
                    In the USA, our DevOps services harness cloud capabilities
                    effectively. We enhance time-to-market without compromising
                    reliability or stability. Our cloud experts handle
                    migration, optimization, development, and maintenance.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="service-widget">
                <div className="sw-head">
                  <div className="sw-logo">
                    <img src={Continuous} alt="integration" title="integration and development" />
                  </div>
                  <div className="sw-title">
                    Continuous Integration and Continuous Deployment
                  </div>
                </div>
                <div className="sw-content">
                  <p>
                    In the USA, our DevOps outsourcing company integrates
                    top-tier CI/CD tools to automate development, QA, and
                    production. Our techies are fully engaged in experimenting,
                    testing, and delivering products.{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="service-widget">
                <div className="sw-head">
                  <div className="sw-logo">
                    <img src={Automation} alt="automation" title="DevOps Automation Service" />
                  </div>
                  <div className="sw-title">DevOps Automation Service</div>
                </div>
                <div className="sw-content">
                  <p>
                    Attain software stability and consistency through process
                    automation. Our DevOps team automates deployments, test
                    workflows, configuration management, and more. Experience
                    accelerated processes, scaled environments, and safer
                    iterations with CI/CD workflows.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="service-widget">
                <div className="sw-head">
                  <div className="sw-logo">
                    <img src={MicroService} alt="Microservices" title="Microservices in DevOps"/>
                  </div>
                  <div className="sw-title">Microservices in DevOps</div>
                </div>
                <div className="sw-content">
                  <p>
                    DevOps's Microservice unifies teams, ensuring 100%
                    effectiveness in deployment, scaling & modifiability.
                    Seamless collaboration across departments achieves targets &
                    resolves issues promptly. Vital for DevOps solutions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {DevopsData?.map((data) => (
        <>
          <GetInTouch GetInTouch={data.GetInTouch} />
          <TechnologiesExperties
            TechnologiesExperties={data.TechnologiesExperties}
            title={data.TechnologiesExpertiesTitle}
            para={data.TechnologiesExpertiesPara}
          />
          <GetInTouchSecondBanner
            GetInTouchSecondBanner={data.GetInTouchSecondbanner}
          />
        </>
      ))}
      <section style={{ padding: "50px 0px" }}>
        <div className="container" style={{ left: "0", border: "none" }}>
          <h2 className="text-center devops-h2">DevOps Process</h2>
          <p className="text-center devops-p">
            Hidden Brains provides flexible engagement models, prioritizing
            client value and specific needs. Our pricing is mutually beneficial,
            ensuring optimal value for money.
          </p>
          <div className="row" style={{ rowGap: "30px", marginTop: "30px" }}>
            <div className="col-lg-6 col-md-6 col-sm-12 lifecycle-image">
              <img
                src={DeveopsProcess}
                alt="DeveopsProcess"
                title="Deveops Process infrastructure"
                style={{ width: "80%" }}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 devops-col2">
              <h4 className="devops-h4">DevOps Implementation Practices</h4>
              <p className="devops-p2">
                DevOps is vital for every product, uniting developers and
                operations in an agile product development cycle. Our
                specialization lies in setting up this architecture, enabling
                automated test acceptance and a streamlined CI/CD process. To
                fully leverage DevOps services, it's vital to grasp its essence
                as more than just an organizational structure—it's a
                transformative mindset. Embracing this mindset empowers
                businesses to optimize efficiency, accelerate deployments, and
                elevate product quality, driving success in today's fast-paced
                digital landscape.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default DevOps;
