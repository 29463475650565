import React from "react";
import {
  Container,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import { HiringWay } from "../../../images/images";
import { makeStyles } from "@material-ui/core/styles";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
const useStyle = makeStyles((theme) => ({
  HiringContainer: {
    display: "flex",
    alignItems: "center",
    padding: "0rem 5rem",
    width: "90%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      padding: "2rem",
    },
  },
  GetHired: {
    fontFamily: "Manrope, sans-serif",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "24px",
      fontSize: "27px",
    },
  },
  HiringBlock: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "37rem",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "none",
    },
  },
  hiredHeader: {
    margin: "0 10px 0 10px",
    color: "#000",
    fontFamily: "'Manrope', sans-serif",
  },
  hiredHeaderSerialNum: {
    margin: "0 10px 0 10px",
    color: "#3D8ED7",
    fontFamily: "'Manrope', sans-serif",
  },
  HiringWayBlock: {
    marginLeft: "10%",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  accordian: {
    margin: "0 0 1rem 0",
    padding: "0.5rem 0rem",
    boxShadow: "0 0 11px rgba(33,33,33,.2)",
    backgroundColor: "none",
    "&::before": {
      backgroundColor: "none",
      height: "0px",
    },
  },
  hiredDesc: {
    fontFamily: "'Manrope', sans-serif",
    paddingLeft: "3rem !important",
  },
}));

const HiringProcess = () => {
  const classNamees = useStyle();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Container maxWidth="lg" className={classNamees.HiringContainer}>
        <Box className={classNamees.HiringBlock}>
          <Box>
            <Typography variant="h4" className={classNamees.GetHired}>
              Get Hired in Easy Steps
            </Typography>
            <p
              style={{
                fontFamily: "'Manrope', sans-serif",
                fontSize: "16px",
                paddingBottom: "1rem",
              }}
            >
              Our optimized hiring and recruitment process shows how much we
              appreciate and value our applicant’s personal time.
            </p>
          </Box>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            className={classNamees.accordian}
          >
            <AccordionSummary
              expandIcon={
                expanded === "panel1" ? (
                  <RemoveIcon style={{ color: "#3D8ED7" }} />
                ) : (
                  <AddIcon style={{ color: "#3D8ED7" }} />
                )
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                <div>
                  <span className={classNamees.hiredHeaderSerialNum}>01</span>
                  <span className={classNamees.hiredHeader}>Send Your CV</span>
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classNamees.hiredDesc}>
                Send us your CV by applying to the job
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
            className={classNamees.accordian}
          >
            <AccordionSummary
              expandIcon={
                expanded === "panel2" ? (
                  <RemoveIcon style={{ color: "#3D8ED7" }} />
                ) : (
                  <AddIcon style={{ color: "#3D8ED7" }} />
                )
              }
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>
                <div>
                  <span className={classNamees.hiredHeaderSerialNum}>02</span>
                  <span className={classNamees.hiredHeader}>
                    Complete the initial screening
                  </span>
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                className={classNamees.hiredDesc}
                style={{ fontFamily: "'Manrope', sans-serif" }}
              >
                If your qualification meets our requirements, our recruiter will
                shortly connect with you for further steps..
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
            className={classNamees.accordian}
          >
            <AccordionSummary
              expandIcon={
                expanded === "panel3" ? (
                  <RemoveIcon style={{ color: "#3D8ED7" }} />
                ) : (
                  <AddIcon style={{ color: "#3D8ED7" }} />
                )
              }
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography>
                <div>
                  <span className={classNamees.hiredHeaderSerialNum}>03</span>
                  <span className={classNamees.hiredHeader}>
                    Attend Interview
                  </span>
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classNamees.hiredDesc}>
                You will get to meet and discuss your skills, background, and
                expectations in detail directly with our colleagues.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
            className={classNamees.accordian}
          >
            <AccordionSummary
              // expandIcon={<Add  />}
              expandIcon={
                expanded === "panel4" ? (
                  <RemoveIcon style={{ color: "#3D8ED7" }} />
                ) : (
                  <AddIcon style={{ color: "#3D8ED7" }} />
                )
              }
              aria-controls="panel4a-content"
              id="panel4a-header"
            >
              <Typography>
                <div>
                  <span className={classNamees.hiredHeaderSerialNum}>04</span>
                  <span className={classNamees.hiredHeader}>
                    Complete the test
                  </span>
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classNamees.hiredDesc}>
                We'll arrange a little test task for you, if required based on
                the specified position and role.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
            className={classNamees.accordian}
          >
            <AccordionSummary
              // expandIcon={<Add  />}
              expandIcon={
                expanded === "panel5" ? (
                  <RemoveIcon style={{ color: "#3D8ED7" }} />
                ) : (
                  <AddIcon style={{ color: "#3D8ED7" }} />
                )
              }
              aria-controls="panel5a-content"
              id="panel5a-header"
            >
              <Typography>
                <div>
                  <span className={classNamees.hiredHeaderSerialNum}>05</span>
                  <span className={classNamees.hiredHeader}>Get Hired</span>
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classNamees.hiredDesc}>
                After deciding if we are a good match for each other, we’ll
                agree upon the terms & conditions of you starting, your career
                at Senwell Group Private Limited.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box className={classNamees.HiringWayBlock}>
          <img src={HiringWay} alt="hiring" style={{ maxHeight: "27rem" }} title="hired good match candidates" />
        </Box>
      </Container>
    </>
  );
};

export default HiringProcess;
