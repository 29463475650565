import React from "react";
import "./SoftwareProduct.css";
import { Tabs, Tab } from "react-bootstrap";
import client9 from "../../../images/Pastproject/client9.webp";
import client10 from "../../../images/Pastproject/client10.webp";
import client11 from "../../../images/Pastproject/CiscoLogo.png";
import ArchitectureD from "../../../images/SoftwareDev/Architecture design.png";
import DiscoverDesign from "../../../images/SoftwareDev/discovery and design service.png";
import ProductConsulting from "../../../images/SoftwareDev/product consulting.png";
import Maintenance1 from "../../../images/SoftwareDev/maintenance and support.png";
import Uiuxdesing from "../../../images/SoftwareDev/ui ux design.png";
import SoftwareProTesting from "../../../images/SoftwareDev/software product testing.png";
import CustomeProduct from "../../../images/SoftwareDev/ProductD.png";
import js from "../../../images/SoftwareDev/javascript-logo.svg";
import css3 from "../../../images/SoftwareDev/css3-colored-logo.svg";
import Html from "../../../images/SoftwareDev/html5-colored-logo.svg";
import emberjs from "../../../images/SoftwareDev/Emberjs-colored.svg";
import nestjs from "../../../images/Technologies/Nest-bgremove.png";
import java from "../../../images/SoftwareDev/Java-icon.svg";
import msdotnet from "../../../images/SoftwareDev/Microsoft_.NET-colored-logo.svg";
import Ionic from "../../../images/SoftwareDev/Ionic-colored-logo.svg";
import Android from "../../../images/SoftwareDev/Android-sdk-icon.svg";
import Progressive from "../../../images/SoftwareDev/pwa-colored-icon.svg";
import flutter from "../../../images/SoftwareDev/flutter-colored-logo.svg";
import angular from "../../../images/SoftwareDev/AngularJS.webp";
import react1 from "../../../images/SoftwareDev/react-colored.svg";
import nodejs from "../../../images/SoftwareDev/nodejs-colored.svg";
import InsightSection from "./InsightSection";
import shape2 from "../../../images/SoftwareDev/shape2.svg";
import shape3 from "../../../images/SoftwareDev/shape3.svg";
import shape4 from "../../../images/SoftwareDev/shape4.svg";
import { Helmet } from "react-helmet";

const SoftwareProduct = () => {
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "@id": "https://senwellsys.com/softwareproduct",
    url: "https://senwellsys.com/softwareproduct",
    name: "Senwell Group Pvt Ltd | Software Product Development",
    description:
      "Explore Senwell Group's expertise in software product development, offering innovative and tailored software solutions to meet the unique needs of businesses across various industries.",
    inLanguage: "en-US",
    organization: {
      "@type": "Organization",
      "@id": "https://senwellsys.com/#organization",
      name: "Senwell Group Pvt Ltd | Software Company | Pune",
      url: "https://senwellsys.com/",
      logo: {
        "@type": "ImageObject",
        url: "https://senwellsys.com/static/media/finalsenwellgrouplogo.fbb0ce6431b7262166b7.png",
        caption: "Senwell Group Pvt Ltd | Software Company | Pune",
      },
      potentialAction: [
        {
          "@type": "SearchAction",
          target:
            "https://senwellsys.com/softwareproduct?s={search_term_string}",
          "query-input": "required name=search_term_string",
        },
      ],
    },
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Senwell Group Private Limited | Software Product Development
        </title>
        <meta
          name="description"
          content="Explore Senwell Group Pvt Ltd's expertise in software product development, delivering innovative and tailored software solutions to drive business success."
        />
        <link rel="canonical" href="https://senwellsys.com/softwareproduct" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Software Product Development" />
        <meta
          property="og:description"
          content="Discover Senwell Group's software product development services, providing custom software solutions that meet business-specific needs with scalability, innovation, and efficiency."
        />
        <meta
          property="og:url"
          content="https://senwellsys.com/softwareproduct"
        />
        <meta property="og:site_name" content="Senwell Group Private Limited" />
        <meta
          property="og:image"
          content="https://senwellsys.com/static/media/ProductD.c9756eb3ac6911374a49.png"
        />
        <meta property="og:image:width" content="376" />
        <meta property="og:image:height" content="360" />
        <meta property="og:image:type" content="image/png" />

        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
      </Helmet>

      <div className="ProductDev">
        <section>
          <div className="main-banner">
            <div className="container" style={{ left: "0", border: "none" }}>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <h1 className="Product-title">
                    Software Product Development
                  </h1>
                </div>
                <div className="col-lg-6 col-md-12">
                  <p className="content-para">
                    Guiding you from ideation to maintenance, we transform
                    visions into reality. As a leading software product
                    development company, we strategically navigate risks, manage
                    costs, and ensure high-quality, stable products. Our
                    collaborative approach prioritizes user experience, robust
                    cloud integration, efficient data handling, and top-tier
                    security, creating products that stand out in the market
                  </p>
                </div>
              </div>
            </div>

            <div class="shape2 rotateme">
              <img src={shape2} alt="cross" title="cross symbol" />
            </div>

            <div className="shape3">
              <img src={shape3} alt="circular" title="circular symbol" />
            </div>
            <div className="shape4">
              <img src={shape4} alt="traingle" title="traingle symbol" />
            </div>

            <div className="shape5">
              <img src={shape4} alt="traingle" title="traingle symbol"/>
            </div>

            <div className="shape6 rotateme">
              <img src={shape4} alt="traingle" title="traingle symbol" />
            </div>

            <div className="shape7">
              <img src={shape4} alt="traingle" title="traingle symbol"/>
            </div>

            <div className="shape8 rotateme">
              <img src={shape2} alt="cross" title="cross symbol"/>
            </div>
          </div>
        </section>

        <section className="section-services">
          <div className="container" style={{ left: "0", border: "none" }}>
            <div className="row" style={{ marginBottom: "60px" }}>
              <div className="col-lg-12 col-md-12 text-center">
                <h2 className="section-head">
                  Software Product Development Services
                </h2>
                <p className="section-para">
                  Leverage our diverse capabilities in providing comprehensive
                  end-to-end product engineering services.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-4 work-grid">
                <div className="work-itemProduct">
                  <div className="icon">
                    <img src={ProductConsulting} alt="consulting" title="product consulting" />
                  </div>
                  <h3>Prototyping & MVP Development</h3>

                  <p>
                    Identify, pinpoint, and describe the demands unique to a
                    product by developing a prototype based on consumer input
                    and current market trends. Use our MVP development service
                    and prototyping methodology to test your  product in
                    real-world markets and reduce risk.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 work-grid">
                <div className="work-itemProduct">
                  <div className="icon">
                    <img src={DiscoverDesign} alt="img" title="Design service"/>
                  </div>
                  <h3>Product Architecture Design Service</h3>

                  <p>
                    Adapt to emerging trends by thoroughly analyzing the market
                    and creatively converting consumer demands into feature sets
                    for your products. To provide your company a competitive
                    edge, our knowledgeable software product developers
                    thoroughly examine every product architectural layer and
                    extract valuable insights.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 work-grid">
                <div className="work-itemProduct">
                  <div className="icon">
                    <img src={ArchitectureD} alt="design" title="UI/UX Design Service"/>
                  </div>
                  <h3>UI/UX Design Service</h3>

                  <p>
                    With a well-defined UI/UX strategy, business workflows may
                    be tracked by developing information architecture,
                    wireframing, and UX design to engage more users. Our
                    designers for UI/UX  Modify the design thinking methodology
                    to provide a novel product. designs that are captivating,
                    logical, and easy to use in keeping with the state of the
                    market.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 work-grid">
                <div className="work-itemProduct">
                  <div className="icon">
                    <img src={Uiuxdesing} alt="development" title="Product Development"/>
                  </div>
                  <h3>Product Development</h3>

                  <p>
                    We provide end-to-end services for developing software
                    products that are in line with particular business
                    requirements. We use various development and operational
                    methodologies, such as SCRUM, Agile, DevOps, Waterfall, and
                    Prototyping. Our skillful technical leads offer product
                    innovation, use cutting-edge technology solutions, improve
                    time to market, and increase profitability.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 work-grid">
                <div className="work-itemProduct">
                  <div className="icon">
                    <img src={SoftwareProTesting} alt="tesing" title="Software Testing"/>
                  </div>
                  <h3>Product Testing</h3>

                  <p>
                    We adhere to rigorous quality analysis from Validation,
                    Functional, Performance, Smoke, and UAT testing in
                    accordance with standards of quality assurance to ensure the
                    developed product is secure, scalable, and stable. Our
                    skilled quality assurance team makes sure that all levels of
                    integration, resilience, and easy customization are present
                    in our software products.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 work-grid">
                <div className="work-itemProduct">
                  <div className="icon">
                    <img src={Maintenance1} alt="fixes" title="fixes logo"/>
                  </div>
                  <h3>Maintenance & Support Services</h3>

                  <p>
                    To enhance workflows and boost overall productivity, our
                    software maintenance and support teams continuously update
                    the product with the newest technologies. For our clients,
                    our skilled software developers guarantee prompt assistance
                    and hassle-free services, which may include problem solving,
                    integration, migration, and enhancement.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-padding-product">
          <div className="container" style={{ left: "0", border: "none" }}>
            <div className="row align-items-lg-center pt-md-3 pb-2 mb-2 mb-lg-4 mb-xl-2">
              <div className="col-md-12 col-lg-5 mb-4 mb-md-0 ">
                <img src={CustomeProduct} alt="CustomeProduct" title="Custome Product" className="leftimg" />
              </div>

              <div className="col-md-12 col-lg-6 offset-lg-1">
                <div className="pe-xl-2 me-md-2 me-lg-2">
                  <h2 className="h4 mb-3 mb-sm-2 design-and-content-header">
                    Best Software Product Development
                  </h2>

                  <p class="mb-sm-2 mb-0 f-16 text-justify design-and-content-para">
                    Senwell Group Private Limited, a top Pune-based software
                    development firm, provides clients with comprehensive
                    product development tools. Our talented global team ensures
                    flexibility and delivers high-quality results with
                    dedication and professionalism.
                  </p>

                  <p className="mb-sm-2 mb-0 f-16 text-justify design-and-content-para">
                    In our role as a prominent company specializing in product
                    development, we possess the expertise in conceptualizing,
                    constructing, sustaining, and enhancing a wide range of
                    software solutions. We firmly adhere to the belief that a
                    product signifies a vital component of your business,
                    serving as a reflection of your brand's image and
                    establishing trust among your target audience. We recognize
                    the significance of individuality for each product and
                    therefore undertake a tailored approach to align it with
                    your specific requirements and objectives. Our commitment
                    lies in creating customized solutions that effectively cater
                    to your needs, ensuring optimal satisfaction and success in
                    your endeavors.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="section-padding clients-section ">
          <div class="container" style={{ left: "0", border: "none" }}>
            <div class="row align-items-center">
              <div class="col-lg-4 pr-lg-5">
                <h2 class="section-heading">
                  Our Leading Multinational Clients
                </h2>
                <p>
                  Join our diverse client base for a digital transformation
                  journey
                </p>
              </div>
              <div className="col-lg-8">
                <div className="clients-logo-list" style={{ display: "flex" }}>
                  <div className="logo-col">
                    <div className="logo-image">
                      <img
                        width="250"
                        height="102"
                        src={client9}
                        alt="MindLift Industries Logo"
                        title="MindLife"
                        class="entered lazyloaded"
                      />
                    </div>
                  </div>
                  <div class="logo-col">
                    <div class="logo-image">
                      <img
                        width="250"
                        height="102"
                        src={client10}
                        alt="worldbank Logo | senwell"
                        title="worldbank"
                        data-ll-status="loaded"
                        class="entered lazyloaded"
                      />
                    </div>
                  </div>
                  <div class="logo-col">
                    <div class="logo-image">
                      <img
                        width="250"
                        height="102"
                        src={client11}
                        alt="client11"
                        title="cisco"
                        data-ll-status="loaded"
                        class="entered lazyloaded"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="industry-section section-padding-tech">
          <div className="container" style={{ left: "0", border: "none" }}>
            <div className="mb-30">
              <h2 className="section-title text-center">
                Technologies & Platforms We Work With
              </h2>
              <p className="text-center">
                We understand the importance of technical competency and
                business acumen for building unique software products. We go
                beyond set standards and norms to meet expectations
              </p>
            </div>
            <div className="row">
              <div className="col-12">
                <Tabs
                  defaultActiveKey="frontend"
                  id="industry-tab"
                  className="mb-3 border-0"
                >
                  <Tab eventKey="frontend" title="Frontend">
                    <ul>
                      <li>
                        <img
                          src={js}
                          alt="javascript"
                          title="javascript"
                          className="w-100"
                          style={{ height: "50px" }}
                        />
                        <span>JavaScript</span>
                      </li>
                      <li>
                        <img
                          src={Html}
                          alt="html"
                          title="html"
                          className="w-100"
                          style={{ height: "50px" }}
                        />
                        <span>Html5</span>
                      </li>
                      <li>
                        <img
                          src={css3}
                          alt="css"
                          title="css"
                          className="w-100"
                          style={{ height: "50px" }}
                        />
                        <span>Css3</span>
                      </li>
                      <li>
                        <img
                          src={angular}
                          alt="angular"
                          title="angular"
                          className="w-100"
                          style={{ height: "50px" }}
                        />
                        <span>Angular</span>
                      </li>
                      <li>
                        <img
                          src={react1}
                          alt="react"
                          title="react"
                          className="w-100"
                          style={{ height: "50px" }}
                        />
                        <span>React</span>
                      </li>
                      <li>
                        <img
                          src={emberjs}
                          alt="emberjs"
                          title="ember js"
                          className="w-100"
                          style={{ height: "50px" }}
                        />
                        <span>EmberJs</span>
                      </li>
                    </ul>
                  </Tab>
                  <Tab eventKey="backend" title="Backend">
                    <ul>
                      <li>
                        <img
                          src={java}
                          alt="java"
                          title="java"
                          className="w-100"
                          style={{ height: "50px" }}
                        />
                        <span>Java</span>
                      </li>
                      <li>
                        <img src={nestjs} alt="nestjs" title="nest js" className="w-100" />
                        <span>Nest Js</span>
                      </li>
                      <li>
                        <img
                          src={nodejs}
                          alt="nodejs"
                          title="node js"
                          className="w-100"
                          style={{ height: "50px" }}
                        />
                        <span>NodeJs</span>
                      </li>
                      <li>
                        <img
                          src={msdotnet}
                          alt="dotnet"
                          title="dot net"
                          className="w-100"
                          style={{ height: "50px" }}
                        />
                        <span>.NET</span>
                      </li>
                    </ul>
                  </Tab>
                  <Tab eventKey="mobile" title="Mobile">
                    <ul>
                      <li>
                        <img
                          src={flutter}
                          alt="flutter"
                          title="flutter"
                          className="w-100"
                          style={{ height: "50px" }}
                        />
                        <span>Flutter</span>
                      </li>
                      <li>
                        <img
                          src={react1}
                          alt="react"
                          title="react"
                          className="w-100"
                          style={{ height: "50px" }}
                        />
                        <span>React</span>
                      </li>
                      <li>
                        <img
                          src={Ionic}
                          alt="ionic"
                          title="ionic"
                          className="w-100"
                          style={{ height: "50px" }}
                        />
                        <span>Ionic</span>
                      </li>
                      <li>
                        <img
                          src={Android}
                          alt="Android"
                          title="Android sdk"
                          className="w-100"
                          style={{ height: "50px" }}
                        />
                        <span>Android SDK</span>
                      </li>
                      <li>
                        <img
                          src={Progressive}
                          alt="pwa"
                          title="Progressive Web Apps"
                          className="w-100"
                          style={{ height: "50px" }}
                        />
                        <span>Progressive Web Apps</span>
                      </li>
                    </ul>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </section>

        <InsightSection />
      </div>
    </>
  );
};

export default SoftwareProduct;
