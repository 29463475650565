import React from "react";
import { Grid, Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  bannerWrapper: {
    width: '95%',
    margin: 'auto',
    // background: 'linear-gradient(90deg, hsla(200, 69%, 50%, 1) 24%, hsla(216, 62%, 39%, 1) 83%)',S
      // padding:'0rem 2rem 2rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingLeft: '0',
      paddingRight: '0',
    },
  },
  ImageWrapper: {
    marginTop: "2rem",
    display: "flex",
    
    justifyContent: "center",
    alignItems: "center",
    padding: "3rem",
    [theme.breakpoints.only("sm")]: {
      padding: "0rem",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: '0'
    },
    
  },
  Image: {
    width: "100%",
    height: "auto",
    objectFit: "contain",
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;',
    [theme.breakpoints.only("sm")]: {
      width: "400px",
      height: "400px",
    },
  },
  ContentWraper: {
    marginTop: "2rem",
    padding: "3rem",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0rem",
      padding: "0rem 1rem 0rem 1rem",
    },
  },
  WorkflowTitle: {
    color: "#000",
    fontSize: "36px",
    lineHeight: "56px",
    fontWeight: "600",
    margin: "0px",
    fontFamily: " 'Ubuntu', sans-serif",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      lineHeight: "40px",
      textAlign: 'center'
    },
  },
  WorkflowPara: {
    color: "#000",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "1.2rem",
    fontFamily: "'Poppins', serif",
    textAlign: "justify",
  },
  WorkflowsubPara: {
    color: "#1c1c1c",
    fontSize: "17px",
    fontWeight: 400,
    padding: "0px 5px",
    fontFamily: "'Lora', serif",
    fontSize: "14px",
  },
  StartFastText: {
    color: "#3D8ED7",
  },
  Mainwrapper: {
    marginBottom:'1rem',
    width: '90%',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  },
}));
const Workflow = ({ IndustriesBanner }) => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  const classNamees = useStyles();
  return (
    <>
      {IndustriesBanner?.map((value) => (
        <>
          <Container maxWidth="xl" className={classNamees.bannerWrapper}>
            <div data-aos="fade-up" data-aos-duration="2000">
              <Container maxWidth="lg" className={classNamees.Mainwrapper}>
                <Grid container style={{ alignItems:'center', justifyContent: 'center' }} >
                  <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div className={classNamees.ImageWrapper}>
                      <img src={value.image} alt={value.title} title={value.title} className={classNamees.Image} />
                    </div>
                  </Grid>
                  <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div className={classNamees.ContentWraper}>
                      <div className="ms-4">
                      <Typography
                        variant="h4"
                        className={classNamees.WorkflowTitle}
                      >
                        {value.title}
                      </Typography>
                      <p className={classNamees.WorkflowPara}>{value.para1} </p>
                      <p className={classNamees.WorkflowPara}> {value.para2}</p>
                      <p className={classNamees.WorkflowPara}> {value.para3}</p>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Container>
            </div>
          </Container>
        </>
      ))}
    </>
  );
};

export default Workflow;
