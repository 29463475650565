import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Container,
  Typography,
  Button,
  Modal,
  Backdrop,
  Fade,
} from "@material-ui/core";
import Aos from "aos";
import "aos/dist/aos.css";
// import getintouch from '../images/Services/Testing/getintouch.jpg'
import test from "../images/Services/Testing/test2.jpg";
import { useNavigate } from "react-router-dom";

import { useEffect } from "react";
const useStyles = makeStyles((theme) => ({
  mainContainerWrapper: {
    marginTop:"0.5rem",
    padding: "2rem 5rem ",
    background:"#15315e",
    marginBottom: '0.5rem',
    fontFamily: "'Manrope', sans-serif",
    [theme.breakpoints.down("sm")]: {
      padding: "2rem",
    },
  },
  ImageWrapper: {
    display: "flex",
    padding: "3rem",
    justifyContent: "space-around",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  Image: {
    width: "100%",
    height: "auto",
    objectFit: "contain",
  },
  GetInTouch: {
    padding: "0.7rem 3rem",
    fontWeight: "600",
    color: "#3D8ED7",
    backgroundColor: "#fff",
    borderRadius: "25rem",
    border: "2px solid #fff",
    marginTop: "2rem",
    "&:hover": {
      backgroundColor: "transparent",
      border: "2px solid #fff",
      color: "#fff",
    },
    [theme.breakpoints.down("sm")]: {
      margin: '0 0 1rem 0'
    },
  },
  GetInTouchButtonwrapper: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingTop: "2rem",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "0.5rem",
      alignItems: 'center',
      justifyContent: 'center'
    },
  },
  MobileViewContainer: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-around",
    },
  },
  contentWraper: {
    color: "White",
    padding: "3rem 0rem 3rem 0rem",
    fontFamily: '"Manrope", sans-serif',

    [theme.breakpoints.down("sm")]: {
      fontSize: "1.1rem",
      padding: "1rem 0rem",
      textAlign: "justify",
      wordSpacing: "-0.1em",
      hyphens: "auto",
      wordSpacing: "-0.1em",
      hyphens: "auto",
      lineHeight: "1.4"  
      },
  },
  mobView: {
    [theme.breakpoints.down('sm')]: {
      padding: '0 8px'
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
const GetInTouch = ({ GetInTouch }) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  const classNamees = useStyles();
  return (
    <>
      {GetInTouch?.map((val) => (
        <div
          className={classNamees.mainContainerWrapper}
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <Container className={classNamees.mobView} maxWidth="lg">
            <Grid container className={classNamees.MobileViewContainer} spacing={3}>
              {val.GetInTouchImage ? (
                <Grid xs={8} md={4} lg={4}>
                  
                  <div className={classNamees.ImageWrapper}>
                    <img alt="get in touch" title="get in touch" src={val.GetInTouchImage} className={classNamees.Image} />
                  </div>
                </Grid>
              ) : (
                <Grid xs={8} md={4} lg={4} style={{ display: "none" }}>
                  <div className={classNamees.ImageWrapper}>
                    <img alt="get in touch" title="get in touch" src={val.GetInTouchImage} className={classNamees.Image} />
                  </div>
                </Grid>
              )}
              {val.GetInTouchImage ? (
                <Grid xs={10} md={4} lg={4}>
                  <Typography variant="h4" className={classNamees.contentWraper}>
                    {val.GetInTouchTitle}
                  </Typography>
                </Grid>
              ) : (
                <Grid xs={10} md={8} lg={8}>
                  <Typography variant="h4" className={classNamees.contentWraper}>
                    {val.GetInTouchTitle}
                  </Typography>
                </Grid>
              )}
              <Grid xs={8} md={4} lg={4}>
                <div className={classNamees.GetInTouchButtonwrapper}>
                  <Button className={classNamees.GetInTouch}  onClick={()=>navigate("/contact")}>
                    {val.Button}
                  </Button>
                
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      ))}
    </>
  );
};

export default GetInTouch;
