import React from "react";
import "../../Pages/Blogs/ReactPopular.css";
import newlogoImage from "../../images/Blogs/finalsenwellgrouplogo.png";
import { Helmet } from "react-helmet";
import Partnerwith from "../../images/Blogs/Partnerwith.png";
import { useNavigate } from "react-router-dom";
import VideoComponent from "../../Modules/Company/Careers/VideoComponent";
import LogoChangeImage from "../../images/Blogs/logochangeimg.png";

const ReactPopular = () => {
  const newlogoId = `m9HXfrjkcA0`;
  const navigate = useNavigate();
  const goToContact = () => {
    navigate("/contact");
  };
  return (
    <div>
      <Helmet>
        <title>Announcement of Our New Logo & Brand Identity</title>
        <meta
          property="og:title"
          content="Announcement of Our New Logo & Brand Identity"
        />
        <meta property="og:type" content="article" />
        <meta
          property="og:description"
          content="In the ever-evolving landscape of business, adaptation is not just a necessity......."
        />
        <meta
          property="og:image"
          content="https://senwellsys.com/static/media/javablog2.4d97a6ca3c01011d190e.webp"
        />
        <meta
          property="og:url"
          content="https://senwellsys.com/react-future-blog"
        />
      </Helmet>
      <section style={{ marginTop: "77px", padding: "50px 0px" }}>
        <div
          className="container blog-container"
          style={{ left: "0", border: "none" }}
        >
          <h1 className="blogMain-header">
            Announcement of Our New Logo & Brand Identity
          </h1>
          <p className="blog-container-date"> | 19 April, 2024 | </p>

          <div className="content-blog">
            <h2 className="entry-sub-title">Introduction:</h2>
            <p className="ReactAppDevelopment">
              In the ever-evolving landscape of business, adaptation is not just
              a necessity, but the cornerstone of growth and resilience. At{" "}
              <a
                href="https://senwellexports.com/"
                target="_blank"
                className="link-blog"
              >
                Senwell Group Private Limited
              </a>
              , formerly known as Senwell Solutions, we recognize the pivotal
              role of adaptation in navigating the currents of change and
              thriving in today's dynamic market. It is with great excitement
              that we unveil our new logo and brand identity—a testament to our
              evolution, innovation, and unwavering commitment to excellence.
            </p>
            <br />
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "200px",
                  height: "200px",
                }}
              >
                <img
                  src={newlogoImage}
                  style={{ width: "100%", height: "100%" }}
                  alt="logo"
                  title="senwell logo"
                />
              </div>
            </div>
            <h2 className="entry-sub-title">Embracing Change:</h2>
            <p className="ReactAppDevelopment">
              As our company has flourished and diversified its offerings across
              various sectors including,
              <a
                href="https://senwellexports.com/"
                target="_blank"
                className="link-blog"
              >
                Manufacturing{" "}
              </a>{" "}
              ,
              <a
                href="https://isearch.co.in/"
                target="_blank"
                className="link-blog"
              >
                Digital marketing{" "}
              </a>{" "}
              , and{" "}
              <a
                href="https://commoditymasters.co/"
                target="_blank"
                className="link-blog"
              >
                Commodity management
              </a>
              , we realized the imperative of a brand identity that resonates
              with our forward-looking vision. Our new logo, marked by its
              modern, minimalist design and vibrant gradient of colors, embodies
              our progressive spirit and agility in meeting the evolving demands
              of our clients and partners.
            </p>
            <br />
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                className="image-blog-heaeder"
                style={{
                  width: "80%",
                  height: "60%",
                }}
              >
                <img src={LogoChangeImage} alt="logo change" title="old and new logo symbols"/>
              </div>
            </div>

            <h2 className="entry-sub-title">Empowered by Innovation: </h2>
            <p className="ReactAppDevelopment">
              At the heart of our rebranding initiative lies our core ethos:
              "Empowered by innovation." This guiding principle fuels our
              relentless pursuit of groundbreaking solutions across every facet
              of our operations. From pioneering advancements in{" "}
              <a
                href="https://senwellsys.com/softwareproduct"
                target="_blank"
                className="link-blog"
              >
                IT services{" "}
              </a>{" "}
              to revolutionizing digital marketing strategies, innovation
              propels us to redefine boundaries and deliver unparalleled value
              that surpasses expectations.
            </p>
            <br />
            <h2 className="entry-sub-title">A Phased Approach: </h2>
            <p className="ReactAppDevelopment">
              Transitioning to a new brand identity is a meticulous journey that
              demands deliberate planning and execution. Recognizing the
              importance of ensuring a seamless transition for our stakeholders,
              we have embraced a phased approach. From updating our digital
              footprint to refreshing physical assets such as uniforms and
              business cards, we are committed to maintaining coherence and
              clarity in our brand narrative.
            </p>
            <br />
            <h2 className="entry-sub-title">Acknowledging Our Subsidiary: </h2>
            <p className="ReactAppDevelopment">
              We are pleased to reaffirm that{" "}
              <a
                href="https://senwellexports.com/"
                target="_blank"
                className="link-blog"
              >
                Senwell Exports Private Limited{" "}
              </a>
              remains a valued subsidiary of Senwell Group Private Limited. This
              subsidiary serves as a vital conduit for expanding our horizons
              and enriching our capabilities in the export industry. While our
              logo undergoes a transformation, our dedication to delivering
              exceptional service through Senwell Exports Private Limited
              remains resolute.
            </p>
            <br />
            <h2 className="entry-sub-title">Looking Ahead: </h2>
            <p className="ReactAppDevelopment">
              As we embark on this transformative chapter in our company's
              journey, we are filled with anticipation for the possibilities
              that lie ahead. Armed with our revitalized brand identity and an
              unyielding commitment to innovation and excellence, we are poised
              to unlock new avenues of value creation for our clients, spearhead
              positive change in the industries we serve, and chart a course for
              sustainable growth and prosperity.
            </p>
            <br />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="video-container">
                <VideoComponent
                  videoId={newlogoId}
                  style={{ width: "500px", height: "315px" }}
                />
              </div>
            </div>
            <h2 className="entry-sub-title">Conclusion: </h2>
            <p className="ReactAppDevelopment">
              In conclusion, the unveiling of our new logo and brand identity
              heralds a significant milestone in our evolution as a company. As
              Senwell Group Private Limited, we are emboldened by a renewed
              sense of purpose and determination to embrace the future with
              confidence, creativity, and a steadfast pursuit of innovation. We
              extend a heartfelt invitation to join us on this exhilarating
              journey as we push boundaries, challenge conventions, and make a
              profound impact in the world around us.
            </p>

            <p className="ReactAppDevelopment">
              You'll notice our logo change right away on our digital platforms,
              and over the coming months, we'll be gradually updating all our
              physical assets too. Changing a logo involves several steps and
              may take some time, so we're taking a phased approach to ensure a
              smooth transition.
            </p>

            <p className="ReactAppDevelopment">
              We're excited about our new look and hope you are too! If you've
              featured the Century logo in any of your marketing materials, we
              kindly ask for your assistance in updating them. Feel free to
              reach out for further details or to share any feedback with us.
            </p>

            <img
              src={Partnerwith}
              alt="HireDeveloper"
              className="HireDev"
              title="contact us"
              onClick={goToContact}
            />
          </div>
        </div>
      </section>
    </div>
  );
};
export default ReactPopular;
