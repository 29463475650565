import { DesignHeroBanner } from "../images/images";
import { SiAdobephotoshop } from "react-icons/si";
import { huffpostImg } from "../images/images";
import { Dashlane } from "../images/images";
import { Code_Better } from "../images/images";
import { Distractify } from "../images/images";

import figma1 from "../images/Technologies/figma.webp";
import photoshop1 from "../images/Technologies/photoshop.png";
import sketch1 from "../images/Technologies/sketch.png";
import indesign1 from "../images/Technologies/indesign.png";
import { DiPhotoshop } from "react-icons/di";
import { DiIllustrator } from "react-icons/di";
import { AiOutlineSketch } from "react-icons/ai";
import { FiFigma } from "react-icons/fi";
import { SiAdobeindesign } from "react-icons/si";

const DesignData = [
  {
    HeroBanner: [
      {
        Page: "UI/UX Design",
        section: "Services",
        Link: "/services",
        title: "UI/UX Design ",
        Breadcrumbs: "services",
        title2: "Services",
        para: "Stunning visuals and well-crafted user experience achieved through deep understanding of design and synergy with technical aspects of development. ",
        button: "Hire Now",
        img: DesignHeroBanner,
      },
    ],
    skill: [
      {
        PerformanceTitle: "Engaging Concepts and Lasting Impressions ",
        PerformancePara:
          "With about years of experience in developing custom design solutions, UI / UX designers, business analysts and product managers develop cutting-edge talent for superior design, comprehensive in analysis, creativity and technology. Equipped with skills. We are experts in expressing the most complex ideas through simple, aesthetically pleasing forms and a clear information architecture.",
        PerformanceList: [
          {
            Numbers: "",
            PerformanceListTitle: "Years of experience",
            PerformanceListPara:
              "Extensive experience in equipping complex systems with intuitive, engaging UI/UX design solutions.",
          },
          {
            Numbers: "",
            PerformanceListTitle: "Accomplished professionals",
            PerformanceListPara:
              "Our engineers are highly skilled, experienced in a wide range of technologies and domains.",
          },
          {
            Numbers: "",
            PerformanceListTitle: "Success stories",
            PerformanceListPara:
              "With hundreds of projects completed, our UI/UX design team is prepare for jobs of any complexity and scale.",
          },
        ],
      },
    ],
    GetInTouch: [
      {
        GetInTouchTitle:
          "Outperform your competitors with the unique image and immersive experience your customers want.",
        Button: "Get In Touch",
      },
    ],
    Benefits: [
      {
        title: "Expert UI/UX Design ",
        Benefitstitle: "Services",
        BenefitsofDevelopmentWithSenwell: [
          {
            Number: "01",
            title: "UI/UX Audit",
            description:
              "We have in depth user analysis, expertise, review and excellent evaluation, we can suggest you’re the best product change and impactful changes to raise your website at top notch.",
          },
          {
            Number: "02",
            title: "Brand/Logo Design",
            description:
              "We help you initiate dialogue with your customers and ensure that the image and perception of your product is deeply embedded in their minds and minds with a unique identity and clear message.",
          },
          {
            Number: "03",
            title: "Research Prototypes",
            description:
              "We start by conducting comprehensive user research to understand your clients' demands, and then we create a great visual representation of your idea, ranging from a simple sketch to a full-fledged prototype.",
          },
          {
            Number: "04",
            title: "UX/UI Design",
            description:
              "It combines scrutiny and design best practices and tools to provide the product with the interaction experience, visual appeal, and emotional connection that customers really want.",
          },
          {
            Number: "05",
            title: "Product Design",
            description:
              "We'll look at your company's objectives, the market, and your target audience to help you create an enticing value proposition backed by engaging visual aesthetics and a data-driven user experience.",
          },
          {
            Number: "06",
            title: "App Design",
            description:
              "We mix user-centered design approaches with significant customer behaviour research to provide your web or mobile application a visually appealing implementation and memorable experiences.",
          },
        ],
      },
    ],
    TechnologiesExpertiesTitle: "UI/UX Design Tools We Use ",
    TechnologiesExpertiesPara:
      "We will select tools for your UI/UX design project depending on the amount of stakeholders engaged, the project's complexity, and your personal preferences. Our design team is adaptable, capable of working in Figma, Sketch, and other Adobe CC programmes. Whatever tools you choose, our internal systems are set up to provide quick feedback gathering, management of all version history, and smooth transfer of design artefacts to developers.",

    TechnologiesExperties: [
      {
        icon: <img src={figma1} className="icon" style={{ i: "#4eb7ff"}} alt="figma1" title= "Figma" />,
        title: "Figma",
      },
      {
        icon: <img src={photoshop1} className="icon" style={{ i: "#4eb7ff" }} alt="photoshop1" title="Photoshop" />,
        title: "Photoshop",
      },
      {
        icon: <img src={sketch1} className="icon" style={{ i: "#4eb7ff" }} alt="sketch1" title="Sketch"/>,
        title: "Sketch",
      },
      {
        icon: <img src={indesign1} className="icon" style={{ i: "#4eb7ff" }} alt="indesign1" title="Indesign"/>,
        title: "Indesign",
      },
    ],

    Forms: [
      {
        formTitle: "Write to us.",
        formSubtitle: "Tell us about your project.",
      },
    ],
  },
];
export { DesignData };
