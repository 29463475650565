import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Typography,
  Button,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PeopleAlt from "@material-ui/icons/PeopleAlt";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Work from "@material-ui/icons/Work";
import { Link, useParams } from "react-router-dom";
import { collection, doc, getDoc, getDocs } from "firebase/firestore/lite";
import db from "../Components/firebase.config";
import { AiOutlineClose } from "react-icons/ai";
import { Close } from "@material-ui/icons";
import Resumedata from "../resume/Resumedata";

const useStyle = makeStyles((theme) => ({
  careerMainHeading: {
    maxWidth: "665px",
    fontFamily: " 'Ubuntu', sans-serif",
    textAlign: "center",
    margin: "0 auto",
    paddingBottom: "1rem",
  },
  jobbox: {
    paddingLeft: "15px",
    // backgroundColor: "#fafafa",
    width: "100%",
    // minWidth: "312px",
    minHeight: "250px",
    // boxShadow: "7px 7px 16px 0px #e1d8d8",
    // margin: "20px 16px 20px 16px ",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  jobmainbox: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    width: "100%",
  },

  HireNowButton: {
    padding: "0.7rem 2rem",
    color: "#fff",
    marginBottom: "2rem",
    letterSpacing: "2.5px",
    fontSize: "1rem",
    marginTop: "1.5rem",
    fontWeight: "600",
    fontFamily: "'Manrope', sans-serif",
    border: "2px solid rgb(31 142 203)",
    borderRadius: "25rem",

    background:
      "linear-gradient(90deg, hsla(200, 69%, 50%, 1) 24%, hsla(216, 62%, 39%, 1) 83%)",
    "&:hover": {
      borderRadius: "25rem",
      border: "2px solid #fff",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0.3rem 1rem",
      border: "none",
      fontSize: "11px",
      marginTop: "0rem",
    },
  },
  jobtTitleHeading: {
    fontSize: "2.125rem",
    fontWeight: 500,
    paddingTop: "0rem",
    lineHeight: 1.235,
    fontFamily: "'Poppins', sans-serif",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  jobdesciption: {
    fontFamily: " 'Lora', serif",
    lineHeight: 1.5,
    fontFamily: "'Poppins', sans-serif",
    [theme.breakpoints.up("md")]: {
      padding: "2rem 5rem",
    },
    fontSize: "16px",
    textAlign: "justify",
    backgroundColor: "#F2F2F2",
    padding: "20px 16px 20px 16px ",
    borderRadius: "20px",
  },

  mainWrapper: {
    marginTop: "3rem!important",
    paddingTop: "5rem !important",
    paddingBottom: "2rem !important",
    marginBottom: "2rem !important",
    backgroundColor: "#FAFAFA",
  },
  applybtn: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const JobPosition = () => {
  const classNamees = useStyle();
  const params = useParams();

  const [jobposition, setjobposition] = useState([]);
  const postref = collection(db, "jobpost");
  useEffect(() => {
    const getdata = async () => {
      const data = await getDocs(postref);
      const newdd = data.docs?.map((doc) => ({ ...doc.data(), id: doc.id }));
      const foundJob = newdd.filter((job) => job.id === params.id);
      setjobposition(foundJob[0]);
    };
    getdata();
  }, []);

  return (
    <>
      <Container maxWidth="md" className={classNamees.mainWrapper}>
        <Box className={classNamees.jobmainbox}>
          {/* <Box > */}
          <Box className={classNamees.jobbox} sx={{ p: 1 }}>
            {/* <Box > */}

            <div className={classNamees.applybtn}>
              <Typography variant="h5" className={classNamees.jobtTitleHeading}>
                <p> {jobposition.designation} </p>
              </Typography>

              <Link
                style={{ textDecoration: "none" }}
                to={{
                  pathname: "/job/resume",
                }}
                state={{ designation: jobposition.designation }}
              >
                <Button
                  style={{ marginBottom: "15px" }}
                  className={classNamees.HireNowButton}
                >
                  Apply Now
                </Button>
              </Link>
            </div>
            <Box>
              <p className={classNamees.jobdesciption}>
                {jobposition.intoduction}
              </p>
            </Box>

            <h3> Requirements </h3>
            <div className={classNamees.jobdesciption}>
              {jobposition?.requirements?.map((item) => (
                <ul>
                  <li className={classNamees.list}>{item}</li>
                </ul>
              ))}
            </div>

            <h3> Responsibilities </h3>
            <div className={classNamees.jobdesciption}>
              {jobposition?.responsibilities?.map((item) => (
                <ul>
                  <li className={classNamees.list}>{item}</li>
                </ul>
              ))}
            </div>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default JobPosition;
