import React, { useState } from "react";
import {
  Container,
  Grid,
  Typography,
  Box,
  Breadcrumbs,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Userhappy from "../images/Technologies/happy.png";
import { height } from "@mui/system";
const useStyles = makeStyles((theme) => ({
  QualitymainContainer: {
    padding: "2rem 0rem",
    // marginTop:'6rem',
    [theme.breakpoints.down("sm")]: {
      padding: "2rem",
    },
  },
  QualityfirstsectionTitleWrapper: {
    // color: "#000",
    textAlign: "center",
    display: "flex",

    justifyContent: "center",
    alignItems: "flex-start",
  },
  gridContainer: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    margin: "auto",
  },
  QualityfirstsectionParaWrapper: {
    color: "#000",
    lineHeight: "24px",
    margin: "0rem",
    [theme.breakpoints.down("sm")]: {
      padding: "0rem 0rem",
      lineHeight: "20px",
    },
  },
  QualityfirstsectionWrapper: {
    paddingBottom: "0.5rem",
    [theme.breakpoints.up("sm")]: {
      width: "95%",
      margin: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "4px",
    },
  },
  TextWrapper: {
    fontFamily: '"Manrope", sans-serif',
    textAlign: "left",
    marginTop: "0.5rem",
    marginBottom: "1rem",

    [theme.breakpoints.down("md")]: {
      width: "80% !important",
      fontSize: "35px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      fontSize: "30px",
      textAlign:"center",
    },
  },
  QualitysecondsectionWrapper: {
    color: "#000",
    padding: "2rem 5rem",
    marginTop: '1rem',
    boxShadow: "1px 1px 10px 1px #f2f2f2",
    backgroundColor: "#f2f2f2",
    [theme.breakpoints.down("sm")]: {
      padding: "0rem",
    },
  },
  persetText: {
    paddingBottom: "0.5rem",
    fontWeight: "600",

  },

  QualitysecondsectionParaWrapper: {
    padding: "0rem 2rem",
    [theme.breakpoints.down("sm")]: {
      padding: "0rem",
    },
  },
  newMob: {
    [theme.breakpoints.up('sm')]: {
      width: '90%',
      margin: 'auto'
    },
  },

  newDesk: {
    [theme.breakpoints.down("sm")]: {
      padding: "1rem",
    },
  },

  titleText: {
    fontFamily: '"Manrope", sans-serif',
  },
  paraText: {
    marginTop: "2rem",
    fontFamily: '"Manrope", sans-serif',
    textAlign: "justify",
    fontSize: "17px",
    wordSpacing: "-0.1em",
    hyphens: "auto",

    [theme.breakpoints.down("sm")]: {
      fontSize:"15px",
    },
  },
  
}));
const Skill = ({ Skill }) => {
  const classNamees = useStyles();
  return (
    <>
      {Skill?.map((val) => (
        <>
          <Container className={classNamees.QualitymainContainer} maxWidth={false}>
            <Container
              className={classNamees.QualityfirstsectionWrapper}
              maxWidth="lg"
            >
              <Grid container className={classNamees.gridContainer}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  lg={4}
                  className={classNamees.QualityfirstsectionTitleWrapper}
                >
                  <img
                  alt="User happy"
                  title={val.PerformanceTitle}
                    src={Userhappy}
                    style={{
                      width: "100%",
                      marginTop: "1rem",
                      marginBottom: "1.5rem",
                      marginLeft: "2rem",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  className={classNamees.QualityfirstsectionParaWrapper}
                >
                  <Typography variant="h4" className={classNamees.TextWrapper}>
                    {val.PerformanceTitle}
                  </Typography>
                  <p className={classNamees.paraText}>{val.PerformancePara} </p>
                </Grid>
              </Grid>
            </Container>

            <Container
              className={classNamees.QualitysecondsectionWrapper}
              maxWidth="lg"
            >
              <div className={classNamees.newMob}>
                <Grid container className={classNamees.newDesk}>
                  {val.PerformanceList?.map((listitem) => (
                    <>
                      <Grid xs={12} sm={12} md={4} lg={4}>
                        <div
                          className={classNamees.QualitysecondsectionParaWrapper}
                        >
                          <Typography
                            variant="h6"
                            className={classNamees.persetText}
                          >
                            {listitem.Numbers}
                          </Typography>
                          <Typography
                            variant="h6"
                            className={classNamees.titleText}
                          >
                            {listitem.PerformanceListTitle}
                          </Typography>
                          <p
                            className={classNamees.paraText}
                            style={{ width: "auto" }}
                          >
                            {listitem.PerformanceListPara}
                          </p>
                        </div>
                      </Grid>
                    </>
                  ))}
                </Grid>
              </div>
            </Container>
          </Container>
        </>
      ))}
    </>
  );
};

export default Skill;
