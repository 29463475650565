import React from 'react';
import './OurWork.css';
import img11 from "../../images/OurWork/image11.webp";
import img2 from "../../images/OurWork/image2.webp";
import img3 from "../../images/OurWork/image3.webp";
import img4 from "../../images/OurWork/image4.webp";
import img5 from "../../images/OurWork/image5.webp";
import img6 from "../../images/OurWork/image6.webp";


const OurWork = () => {

  const ourClients = [
    {img:img11,description:"lumiere", title:"work with lumiere"},
    {img:img2,description:"virus geeks", title:"work with virus geeks"},
    {img:img3,description:"parsl", title:"work with parsl"},
    {img:img4,description:"lotto social", title:"work with lotto social"},
    {img:img5,description:"fork freight", title:"work with fork freight"},
    {img:img6,description:"fooz", title:"work with fooz"},
  ]
  return (
    <div>
      <div className="our-work-container" id="portfolio">
        <div className="our-work-content m-m-90">
          <div className="text-center top-header">Our Work</div>
          <div className="tab-container Work-Para">
            <div className="sub-text">At Senwell Group Private Limited, we don't just provide IT and Digital Marketing services; we craft digital experiences that elevate your business in the modern landscape. Our Portfolio is a testament to the transformative Power of Technology and Creativity, merging seamlessly to drive success. Join us in shaping the digital narrative - Where Technology meets Creativity, and Solutions pave the path to success. Transforming Today, Powering Tomorrow.</div>
          </div>
          <div id="workImg" className="work-view row">
           {ourClients.map((data, index)=>( 
            <div key={index} className="work-item col-md-4">
              <div className="display-image">
                <img width="100%" src={data.img} alt={data.description} title={data.title} />
              </div>
            </div>))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurWork
