import React from "react";
import { Link } from "react-router-dom";
import "./pageNotFound.css";

const PageNotFound = () => {
  // const classNamees = useStyles();
  return (
    <div style={{ marginTop: "77px", height: "100vh" }}>
      <div className="err_page">
        <div className="err_page_left">
          <img
            src="https://blogger.googleusercontent.com/img/a/AVvXsEi_td6QYAIZlb1Bhoo7it7f1OI8XGXQIdJ51xpYTJrk-94frvU0DZwK5AJn1luY60aKIAcw9J9theq3NVi9evrs329TtdPav30jw-E1lEjsp_T1QeAITbO4R0JxphTPTL4i0774-pNIM8RtmpVGujKrFWZbVt_W-zr8DAbJ0Btsg7anUXvgmNoolkD6"
            width="360px"
            height="250px"
            alt="page not found"
            title="page not found"
          />
        </div>
        <div className="err_page_right">
          <h1>404</h1>
          <h4>OOPS. Looks like the page you're looking for no longer exists</h4>
          <p>
            Don't worry. Since you're valuable to us we will bring you back to
            safety
          </p>
          <Link to={"/"}>
            <button className="err_btn">Back to home</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
