import React from "react";
import { Link } from "react-router-dom";

const InsightCard = ({ img, tagline, title, des, url }) => {
  return (
    <div className="col-md-6 col-lg-4 insight-col">
      <div className="insight-card h-100 px-1">
        <div className="image mb-3">
          <Link to={url}>
            <img src={img} alt="Blog" className="w-100" title={title}/>
          </Link>
        </div>
        <p className="tagline mb-2">{tagline}</p>
        <h3 className="card-title">
          <Link to={url}>{title}</Link>
        </h3>
        <p className="des">{des}</p>
      </div>
    </div>
  );
};

export default InsightCard;
