import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../Home/Carousel.style.css";
import img1 from "../../images/Industries/1.webp";
import img2 from "../../images/Industries/2.webp";
import img3 from "../../images/Industries/3.webp";
import img4 from "../../images/Industries/4.webp";
import img5 from "../../images/Industries/5.webp";
import img6 from "../../images/Industries/6.webp";
import img7 from "../../images/Industries/7.webp";
import img8 from "../../images/Industries/8New.png";
import img9 from "../../images/Industries/9.webp";
import img10 from "../../images/Industries/10.webp";
import img11 from "../../images/Industries/11.webp";
import img12 from "../../images/Industries/12.webp";

function Carousel() {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    initialSlide: 0,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="container" style={{ left: "0", border: "none" }}>
        <div className="industries-header">Industries We Serve</div>
        <div className="App1">
          <Slider {...settings}>
            <div className="card-top">
              <img src={img1} alt="Aerospace-image" title="Aerospace" />
              <h3 className="text-center indurstry-para">Aerospace</h3>
            </div>

            <div className="card-top">
              <img src={img2} alt="Entertainment-img" title="Entertainment" />
              <h3 className="text-center indurstry-para">Entertainment</h3>
            </div>

            <div className="card-top">
              <img
                src={img3}
                alt="Retail-img"
                title="Retail & Ecommerce Industry"
              />
              <h3 className="text-center indurstry-para">
                {" "}
                Retail & Ecommerce
              </h3>
            </div>

            <div className="card-top">
              <img src={img4} alt="Communications-img" title="Communication" />
              <h3 className="text-center indurstry-para">Communications</h3>
            </div>

            <div className="card-top">
              <img src={img5} alt="StartUps-img" title="Start Ups" />
              <h3 className="text-center indurstry-para">StartUps</h3>
            </div>

            <div className="card-top">
              <img src={img6} alt="Automotive" title="Automotive Industry" />
              <h3 className="text-center indurstry-para">Automotive</h3>
            </div>

            <div className="card-top">
              <img
                src={img7}
                alt="Platforms and software"
                title="Platforms and software Indurstry"
              />
              <h3 className="text-center indurstry-para">
                Platforms and software
              </h3>
            </div>

            <div className="card-top">
              <img src={img8} alt="Block Chain" title="Block Chain Industry" />
              <h3 className="text-center indurstry-para">Block Chain</h3>
            </div>

            <div className="card-top">
              <img src={img9} alt="Healthcare" title="Healthcare Industry" />
              <h3 className="text-center indurstry-para">Healthcare</h3>
            </div>

            <div className="card-top">
              <img src={img10} alt="Education" title="Education Industry" />
              <h3 className="text-center indurstry-para">Education</h3>
            </div>

            <div className="card-top">
              <img src={img11} alt="Fintech" title="Fintech Industry" />
              <h3 className="text-center indurstry-para">Fintech</h3>
            </div>

            <div className="card-top">
              <img
                src={img12}
                alt="Transport & Logistics"
                title="Transport & Logistics Industry"
              />
              <h3 className="text-center indurstry-para">
                Transport & Logistics
              </h3>
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
}

export default Carousel;
