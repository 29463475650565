import React from "react";
import "./Testing.css";
import { TestingData } from "../../../API/ServicesApi";
import TypeOfTesting from "../../../Modules/Services/Testing&QA/TypesofTesting";
import QuickStart from "../../../Modules/Services/Testing&QA/QuickStart";
import GetInTouch from "../../../SharedModule/GetInTouch";
import OurQAProcess from "../../../Modules/Services/Testing&QA/OurQAProcess";
import TechnologiesExperties from "../../../SharedModule/TechnologiesExperties";
import Lottie from "react-lottie";
import animationData from "../../../assets/TestingAnimation.json";
import Automation from "../../../images/Services/Automation-Testing.svg";
import Manual from "../../../images/Services/Manual-Testing.svg";
import Security from "../../../images/Services/Security-Software-Testing.svg";
import Performance from "../../../images/Services/Performance-Testing.svg";
import Compactibility from "../../../images/Services/Compatibility-Software-Testing.svg";
import ApiTesting from "../../../images/Services/API-Testing.svg";
import { Link } from "react-router-dom";

const Testing = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      {TestingData?.map((value) => (
        <>
          <section className="Testing-Header-sections">
            <div className="container" style={{ left: "0", border: "none" }}>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12 Testing-banner-col">
                  <div className="Testing-banner-content">
                    <h1>Testing & Quality Assurance</h1>
                    <p className="sub-p">
                      Fast & reliable testing solutions from a top testing
                      company. We have 10,000+ test cases & 1000+ automation
                      scripts for superior quality. Elevate your project
                      efficiency & dependability, Partner with us!.
                    </p>
                    <div className="Testing-banner-button">
                      <Link to="/contact">
                        {" "}
                        <a className="Testing-theme-btn">Let's Talk</a>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 testing-image">
                  <div className="image">
                    <Lottie options={defaultOptions} />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section style={{ padding: "50px 0px" }}>
            <div
              className="container web-container"
              style={{ left: "0", border: "none" }}
            >
              <h2 className="text-center Testing-comapny-header">
                Software Testing Company
              </h2>
              <p
                style={{ textAlign: "justify", width: "90%" }}
                className="Testing-comapny-para"
              >
                Senwell Group Private Limited excels in software testing
                services, delivering unparalleled performance-oriented
                solutions. Our manual and automated testing services guarantee
                100% client satisfaction through advanced methodologies, latest
                tools & techniques.
              </p>
            </div>
          </section>

          <section
            className="section_part"
            style={{ height: "100%", padding: "50px 0px" }}
          >
            <div
              className="container container1"
              style={{ left: "0", border: "none" }}
            >
              <div className="row" style={{ rowGap: "20px" }}>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="service-widget">
                    <div className="sw-head">
                      <div className="sw-logo">
                        <img src={Automation} alt="Automation" title="Automation Testing" />
                      </div>
                      <div className="sw-title">Automation Testing</div>
                    </div>
                    <div className="sw-content">
                      <p>
                        We empower businesses with affordable and efficient
                        automation testing services, ensuring bug-free products
                        through web and mobile automation testing
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="service-widget">
                    <div className="sw-head">
                      <div className="sw-logo">
                        <img src={Manual} alt="testing" title="Manual Testing"/>
                      </div>
                      <div className="sw-title">Manual Testing</div>
                    </div>
                    <div className="sw-content">
                      <p>
                        Our manual software testing service covers functional,
                        usable, performance, API, and security testing for web &
                        mobile apps. We perform GUI, responsive, cross-browser
                        testing for web and UI/UX testing for mobile.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="service-widget">
                    <div className="sw-head">
                      <div className="sw-logo">
                        <img src={Security} alt="Security" title="Security Software Testing"/>
                      </div>
                      <div className="sw-title">Security Software Testing</div>
                    </div>
                    <div className="sw-content">
                      <p>
                        In today's cyber-threat landscape, safeguarding your
                        systems from vulnerabilities is crucial. Choose Senwell
                        Group Private Limited for penetration testing services
                        to fortify your applications against cyberattacks and
                        data phishing.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="service-widget">
                    <div className="sw-head">
                      <div className="sw-logo">
                        <img src={Performance} alt="Performance" title="Performance Testing"/>
                      </div>
                      <div className="sw-title">Performance Testing</div>
                    </div>
                    <div className="sw-content">
                      <p>
                        Our performance testing solution delivers innovative
                        value with load testing and stress testing. Our software
                        application testing services analyze responsiveness,
                        stability, and pinpoint performance bottlenecks.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="service-widget">
                    <div className="sw-head">
                      <div className="sw-logo">
                        <img src={Compactibility} alt="Compatibility" title="Compatibility Software Testing"/>
                      </div>
                      <div className="sw-title">
                        Compatibility Software Testing
                      </div>
                    </div>
                    <div className="sw-content">
                      <p>
                        Our software testing consultancy offers comprehensive
                        compatibility testing solutions, including browser,
                        application, network, and version compatibility testing
                        services.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="service-widget">
                    <div className="sw-head">
                      <div className="sw-logo">
                        <img src={ApiTesting} alt="testing" title="API Testing"/>
                      </div>
                      <div className="sw-title">API Testing</div>
                    </div>
                    <div className="sw-content">
                      <p>
                        Leverage our quality assurance software testing
                        services, including API testing like Functionality,
                        Reliability, Load, Security, and Negative Testing.
                        Enhance app functionality and drive seamless user
                        engagement.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <TypeOfTesting />
          <QuickStart />
          <GetInTouch GetInTouch={value.GetInTouch} />
          <OurQAProcess />
          <TechnologiesExperties
            TechnologiesExperties={value.TechnologiesExperties}
            title={value.TechnologiesExpertiesTitle}
            para={value.TechnologiesExpertiesPara}
          />
        </>
      ))}
    </>
  );
};
export default Testing;
