import { ReactBannerImg } from "../images/images";
import { CmmiImage } from "../images/images";
import { Union } from "../images/images";
import { LogoImage } from "../images/images";
import { ReactGetinTouchBanner } from "../images/images";
import { AngularGetinTouchBanner } from "../images/images";
import { NodeBanner } from "../images/images";
import Dotnet from "../images/Technologies/net-logo.png";
import Postman from "../images/Technologies/postman.png";
import { netBanner } from "../images/images";
import { Mobile } from "../images/images";
import { reactnativeBanner } from "../images/images";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import SearchIcon from "@material-ui/icons/Search";
import StarIcon from "@material-ui/icons/Star";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { IoLogoJavascript } from "react-icons/io";
import { FaAngular } from "react-icons/fa";
import { SiTypescript } from "react-icons/si";
import { FaReact } from "react-icons/fa";
import { FaNodeJs } from "react-icons/fa";
import Java from "../images/Technologies/java2.jpg";
import { FaPhp } from "react-icons/fa";
import { DiJqueryLogo } from "react-icons/di";
import { FaAndroid } from "react-icons/fa";
import { DiSwift } from "react-icons/di";
import { DiIonic } from "react-icons/di";
import javaNew from "../images/Technologies/Java1.png";
import springBoot from "../images/Technologies/spring1.png";
import Hibernate from "../images/Technologies/Hibernaet1.png";
import Nestjs from "../images/Technologies/Nest js.png";
import Nextjs from "../images/Technologies/NextjsNew.png";
import Flutter from "../images/Technologies/Flutter.png";
import Dart from "../images/Technologies/Dart.png";

import { FrontendBanner } from "../images/images";
import { Backend } from "../images/images";
import { angularjs } from "../images/images";
import frontdevImg from "../images/Technologies/frontdevImg.png";
import FeaturedreactImg from "../images/Technologies/FeaturedreactImg.png";
import backendImage from "../images/Technologies/backendImage.png";
import MobileImg from "../images/Technologies/MobileImg.png";
import QATesting from "../images/Technologies/QATesting.png";
import dotnetImg from "../images/Technologies/dotnetImg.png";
import javaBanner from "../images/SoftwareDev/Java.png";
import NestBanner from "../images/SoftwareDev/Nest.png";
import NextBanner from "../images/SoftwareDev/Next.png";
import IosBanner from "../images/SoftwareDev/Ios.png";
import AndroidBanner from "../images/SoftwareDev/Android1.png";
import FlutterBanner from "../images/SoftwareDev/Flutter.png";

import Csharp from "../images/Technologies/csharp.png";
import Vue from "../images/Technologies/vue.png";
import Python from "../images/Technologies/python3.png";
import Kotlin1 from "../images/Technologies/Kotlin.png";
import Selenium1 from "../images/Technologies/Selenium.png";
import Jmeter from "../images/Technologies/jmeter.jpg";
import junit from "../images/Technologies/junit.webp";
import "../App.css";
import NodejsImg from "../images/Technologies/NodejsImg.png";
import reactnativeImg from "../images/Technologies/reactnativeImg.png";
import Cronage from "../images/Pastproject/Cronage.png";
import Fooz from "../images/Pastproject/Fooz.png";
import Fork from "../images/Pastproject/Fork1.png";
import Lotto from "../images/Pastproject/Lotto.png";
import Lumiere from "../images/Pastproject/Lumiere.png";
import parsl from "../images/Pastproject/parsl.JPG";
import Virus from "../images/Pastproject/Virus.png";

import cronage from "../images/logo/cronage.png";
import fooz from "../images/logo/fooz.png";
import GreekVirous from "../images/logo/GreekVirous.png";
import Lottosocialogo from "../images/logo/Lottosocialogo.png";
import Lumieres from "../images/logo/Lumieres.png";
import parslImg from "../images/logo/parslImg.png";
import fork from "../images/logo/fork.png";
import Forkfreight from "../images/logo/Forkfreight.png";

const TechnologiesData = [
  {
    id: 2,
    name: "react",
    HeroBanner: [
      {
        name: "react",
        Page: "React",
        breadcrums: "front-end",
        Link: "/technologies",
        title: "Hiring React Development",
        button: "Apply Now",
        img: ReactBannerImg,
        image1: CmmiImage,
        image2: Union,
        image3: LogoImage,
        para: "Equip your website or web application with a responsive and intuitive user interface built by React outsourcing professionals.",
      },
    ],

    skill: [
      {
        PerformanceTitle: "Flawless Performance.Great User Experience",
        PerformancePara:
          "From start-ups to well-established companies, almost everyone these days uses ReactJS to build native apps. This open-source JavaScript framework specializes in building UIs and has been designed to help create apps for both Android and iOS platforms. ReactJS is considered one of the best front-end development frameworks as it enables developers to build robust & complex products. Utilize our React outsourcing capacity and build a robust solution for your own business!",
        PerformanceList: [
          {
            Numbers: "250+",
            PerformanceListTitle: "Skilled Engineers",
            PerformanceListPara:
              "Whether you hire one or 10 React developers, we guarantee that you will be working only with Middle and Senior level engineers",
          },
          {
            Numbers: "275+",
            PerformanceListTitle: "React Projects",
            PerformanceListPara:
              "With years of experience delivering top-notch React development services, our skilled developers can handle projects of any complexity.",
          },
          {
            Numbers: "8+",
            PerformanceListTitle: "Years of Experience",
            PerformanceListPara:
              "Our most experienced React engineers were early adopters of the library and started implementing its features as early as 2013.",
          },
        ],
      },
    ],
    TechnologiesExpertiesTitle: "Tech Stack",
    TechnologiesExpertiesPara:
      "Our knowledge in React development outsourcing does not end there. Our talents and experience encompass practically every programming language, framework, and library, both those that have been around for a long time and others that have been launched recently by industry leaders like Google or Microsoft.",
    TechnologiesExperties: [
      {
        icon: (
          <IoLogoJavascript
            className="icon"
            style={{ i: "#4eb7ff", backgroundColor: "yellow", color: "black" }}
          />
        ),
        title: "JavaScript",
      },

      {
        icon: <FaReact className="icon" style={{ i: "#4eb7ff" }} />,
        title: "React",
      },
      {
        icon: (
          <SiTypescript
            className="icon"
            style={{ i: "#4eb7ff", backgroundColor: "black", color: "yellow" }}
          />
        ),
        title: "TypeScript",
      },
      {
        icon: (
          <img
            src={Vue}
            className="icon"
            style={{ color: "green" }}
            alt="Vue"
            title="Java"
          />
        ),
        title: "c",
      },
    ],
    DevelopmentStep: [
      {
        DevelopmentSteptitle: "React development step by step",
        Developmentstep: [
          {
            icon: "",
            title: "Write to Us",
            description:
              "Tell us about your front-end development needs and we will take care of the rest.",
          },
          {
            icon: "",
            title: "Define requirements",
            description:
              "We will talk through all the necessary details, identify all tech specs and requests.",
          },
          {
            icon: "",
            title: "Meet your team",
            description:
              "We will handpick a development team that fits your needs best and start on your project at once.",
          },

          {
            icon: "",
            title: "Prepare to deploy",
            description:
              "Your project will be delivered on time and within proper budget and systematic Implementation and will continue to prosper!",
          },
        ],
      },
    ],
    GetInTouch: [
      {
        GetInTouchTitle:
          "Conduct a trial project to confirm that Senwell Group Private Limited ReactJS outsourcing knowledge is exactly what your company requires.",
        Button: "Get In Touch",
      },
    ],

    Benefits: [
      {
        title: "Benefits of React",
        Benefitstitle: "React development step by step",
        BenefitsofDevelopmentWithSenwell: [
          {
            Number: "01",
            title: "Internal Educational Program",
            description:
              "Thanks to our internal educational courses, our front-end developers are always at the top of their game. Their knowledge goes far beyond keeping up with the latest technological innovations.",
          },
          {
            Number: "02",
            title: "Instant resource replacement ",
            description:
              "We carefully select the finest developers for every project, but with almost 150+ engineers at work, we're always ready to try something different.",
          },
          {
            Number: "03",
            title: "Transparent Code Monitoring ",
            description:
              "We have a quality assurance system that really is quick, accessible, and transparent. You could relax knowing that every piece of code will be inspected.",
          },
          {
            Number: "04",
            title: "Tailoring Processes if Needed",
            description:
              "Every project and organization demands a different strategy. We embrace Agile, but we've also collaborated with Waterfall, V-Model, Spiral, and other approaches.",
          },
          {
            Number: "05",
            title: "System Administration Included",
            description:
              "We deliver system administration services at no incremental cost. Your business functions will work smoothly, and your IT management expenses will be slashed.",
          },
          {
            Number: "06",
            title: "Trial Periods for New Employees",
            description:
              "When you bring in new developers, you might execute a short pilot project with them just to ensure that they can match your aspirations and the project's functional characteristics.",
          },
        ],
      },
    ],

    ProjectPortfollio: [
      {
        title: "Fooz",
        image1: Fooz,
        technologies: [" React", "Redux", "Node.js", "Bootstrap"],
        para: "The UAE-born, play-to-win online platform introduced by Arab Millionaire is offering participants the chance to “Dream Big, Give More” with the largest prizes in the Arab World",
      },
      {
        title: "LottoSocial",
        image1: Lotto,
        technologies: [" Angular", "Ionic", "Node.js", "Firebase"],
        para: "Web Application to create and manage online lottery syndicates. Increase your chances of winning big and join a lottery syndicate. We made it simple to create and manage lottery syndicates online, offering a combination of ticket types.",
      },

      {
        title: "Lumiere32- B2B Supplier Marketplace",
        image1: Lumiere,
        technologies: [" Angular", ", NestJs", "MySQL"],
        para: " We developed an effective web application that helps developers to write semantically correct code and form useful coding habits with better practices, superior tools, and proven methodologies.The application provides thorough data analysis and real-time statistics as well as features such as sorting errors by priority. The app is compatible with all popular desktop and mobile devices.",
      },

      {
        title: "VirusGeeks",
        image1: Virus,
        technologies: [" Angular", "NextJs", "NestJs"],
        para: "Healthcare Platform VirusGeeks, a BioHealth Technology platform, which provide health tech solutions such as end-to-end Covid-19 testing.",
      },
      {
        title: "Parsl",
        image1: parsl,
        technologies: [" Angular", "Node.js"],
        para: "Parsl is a platform that combines blockchain, NFC and IoT to create a track and trace technology that not only automates compliance efficiently and accurately but offers a range of tangible business benefits for every stage of the cannabis supply chain. ",
      },
      {
        title: "Cronage",
        image1: Cronage,
        technologies: [" Angular", "Bootstrap", "HTML", "CSS"],
        para: "  New businesses must strike while the iron is hot. Chasing after a fast time-to-market can make your business vulnerable to performance risks. We helped Distractify perform a fast but extremely thorough Quality Assurance process before they hit “publish” on their first viral story.",
      },
    ],
    OurClients: [
      {
        Image: cronage,
      },

      {
        Image: GreekVirous,
      },

      {
        Image: Lottosocialogo,
      },
      {
        Image: fooz,
      },
      {
        Image: Lumieres,
      },
      {
        Image: parslImg,
      },
      {
        Image: fork,
      },
    ],
    Testimonials: [
      {
        label1:
          "“They are incredibly client-focused, so we saw them as partners rather than service suppliers. Applying industry cutting edge- practices and knowledge of travel, real estate, technology, and software business domain they have managed to deliver the project on time and of the highest quality.”",
        img: "",
        name: "Derick, Hatchster Ltd. UK",
        post: "",
      },
      {
        label2:
          "“I’ve been working with Senwell Group Private Limited for several outsourcing projects for clients in Canada and US, and I  have to say that the guys here deliver really great work and oftentimes outperform the clients’ own  development teams. I’ve never met such productivity before and that’s probably one of the biggest,  although not the only one advantage of the company..”",
        img: "",
        name: "Kevin Atom, Property Hatch Ltd, United State",
        post: "",
      },
      {
        label3:
          "“We are enormously satisfied with Senwell Group Private Limited as contractor. Highly responsive team with  profound understanding of subject matter and really very, very fast code delivery that is particularly  important for our travel business. . ”",
        img: "",
        name: "Nicolo F, Italy",
        post: "",
      },
    ],
    GetInTouchSecondbanner: [
      {
        GetInTouchTitle: "Looking for a reliable React outsourcing partner?",
        Button: "Get a Quote",
        image: FeaturedreactImg,
        para: "We’re ready to put our best foot forward! Whether you’re looking for a single developer or an entire full-cycle team, Senwell Group Private Limited is where you get your project delivered on time and within budget.",
      },
    ],
    WhyChooseUs: [
      {
        WhyChooseUsTitle: "Why Choose Us",
        WhyChooseUs: [
          {
            icons: "",
            title: "Start with confidence.",
            para: "Our goal is to help you launch quickly and make sure you’ll want to work with us again.",
          },
          {
            icons: "",
            title: "100% Transparency",
            para: "Clear communication, flexible pricing, and full dedication to your success.",
          },
          {
            icons: "",
            title: "Excellent Code Quality",
            para: "We check the quality of our code at every step of development to ensure best results.",
          },
          {
            icons: "",
            title: "Cross-domain expertise",
            para: "Need services beyond front-end development? We will have you covered.",
          },
        ],
      },
    ],
    Forms: [
      {
        formTitle: "Write to us.",
        formSubtitle: "Tell us about your project.",
      },
    ],
  },
  {
    id: 3,
    name: "angular",
    Technologies: "front-end",
    HeroBanner: [
      {
        name: "angular",
        Page: "Angular",
        breadcrums: "front-end",
        Link: "/technologies",
        title: "Hiring Angular Development",
        img: angularjs,
        button: "Apply Now",
        image1: CmmiImage,
        image2: Union,
        image3: LogoImage,
        para: "We know how to build lean and fast-responding user interfaces for websites and applications with the Angular web framework by Google.",
      },
    ],

    skill: [
      {
        PerformanceTitle: "Proven Development Tools.",
        PerformancePara:
          "The Google-maintained AngularJS framework is an open-source JavaScript framework. You can use JavaScript, CSS, and HTML to make dynamic content. On a single page, it dramatically increases your potential to give interactive material that sticks out to company customers, clients, or readers. AngularJS is presently one of the most popular programming environments. Our Angular development outsourcing service will guarantee that your project is developed fast and without faults.",
        PerformanceList: [
          {
            Numbers: "225+",
            PerformanceListTitle: "Engineers",
            PerformanceListPara:
              "Our company employs some of the most experienced and skilled Angular developers in Eastern Europe.",
          },
          {
            Numbers: "255+",
            PerformanceListTitle: " Projects",
            PerformanceListPara:
              "Angular development outsourcing has filled our portfolio with lots of projects worthy of your attention.",
          },
          {
            Numbers: "10+",
            PerformanceListTitle: "Years of Experience",
            PerformanceListPara:
              "Being in the game that long, we didn’t lose our passion for innovative tools such as Angular framework.",
          },
        ],
      },
    ],
    TechnologiesExpertiesTitle: "Tech Stack",
    TechnologiesExpertiesPara:
      "Our knowledge in Angular development outsourcing does not end there. Our talents and experience encompass practically every programming language, framework, and library, both those that have been around for a lot longer and others that have been launched recently by industry leaders like Google or Microsoft.",

    TechnologiesExperties: [
      {
        icon: (
          <IoLogoJavascript
            className="icon"
            style={{ i: "#4eb7ff", backgroundColor: "yellow", color: "black" }}
          />
        ),
        title: "JavaScript",
      },
      {
        icon: (
          <FaAngular className="icon" style={{ i: "#4eb7ff", color: "red" }} />
        ),
        title: "Angular",
      },

      {
        icon: (
          <SiTypescript
            className="icon"
            style={{ i: "#4eb7ff", backgroundColor: "black", color: "yellow" }}
          />
        ),
        title: "TypeScript",
      },
    ],
    DevelopmentStep: [
      {
        DevelopmentSteptitle: "How to Get Started",

        Developmentstep: [
          {
            icon: "",
            title: "One click away",
            description:
              "Just fill in the intake form here on this website, and we’ll start estimating your project right away.",
          },
          {
            icon: "",
            title: "Goals setting",
            description:
              "Together with our manager, you will define your project objectives for the long term.",
          },
          {
            icon: "",
            title: "Dedicated team",
            description:
              "We’ll form the team to work on your project and even hire AngularJS developers if needed",
          },

          {
            icon: "",
            title: "Let’s go!",
            description:
              "The work will start immediately after you meet and approve your software development team.",
          },
        ],
      },
    ],
    GetInTouch: [
      {
        GetInTouchTitle:
          "Conduct a trial project to sure that Senwell Group Private Limited' Angular outsourcing knowledge is precisely what your company requires.",
        Button: "Get In Touch",
      },
    ],

    Benefits: [
      {
        title: "Benefits of Angular",
        Benefitstitle: " Development with Senwell",
        BenefitsofDevelopmentWithSenwell: [
          {
            Number: "01",
            title: "Quality first",
            description:
              "We always prefer code quality over deadlines and budget constraints.You'll always get the most out of your project plan when you work with Senwell Group Private Limited.",
          },
          {
            Number: "02",
            title: "Customized solutions",
            description:
              "We build software from the scratch, so you don't have to care about project planning, business analysis, UI/UX design, technical support, or anything else.",
          },
          {
            Number: "03",
            title: "Honesty and Integrity",
            description:
              "We encourage all of our clients to speak with us in a non-bureaucratic manner, and we set up our working process so that they may easily and immediately access the outcomes of our full-time work.",
          },
          {
            Number: "04",
            title: "Global reputation",
            description:
              "We've been working on making our name a globally recognized brand for the past 15 years.It wasn't for naught, as shown by our industry accolades and projects alongside Fortune 500 business",
          },
          {
            Number: "05",
            title: "Software development teams",
            description:
              "Our Angular development operations, unlike freelance, give specialized teams that can cover all stages of software development. You won't have to look for a designer or QA analyst since we'll do it for you.",
          },
          {
            Number: "06",
            title: "Post-production assistance",
            description:
              "The development stage, while it may appear to be the end of your adventure, is far from it. In truth, post-production assistance can be stressful and costly; the good news is that we can provide it for all of our productions.",
          },
        ],
      },
    ],
    ProjectPortfollio: [
      {
        title: "Fooz",
        image1: Fooz,
        technologies: [" React", "Redux", "Node.js", "Bootstrap"],
        para: "The UAE-born, play-to-win online platform introduced by Arab Millionaire is offering participants the chance to “Dream Big, Give More” with the largest prizes in the Arab World",
      },
      {
        title: "LottoSocial",
        image1: Lotto,
        technologies: [" Angular", "Ionic", "Node.js", "Firebase"],
        para: "Web Application to create and manage online lottery syndicates. Increase your chances of winning big and join a lottery syndicate. We made it simple to create and manage lottery syndicates online, offering a combination of ticket types.",
      },

      {
        title: "Lumiere32- B2B Supplier Marketplace",
        image1: Lumiere,
        technologies: [" Angular", ", NestJs", "MySQL"],
        para: " We developed an effective web application that helps developers to write semantically correct code and form useful coding habits with better practices, superior tools, and proven methodologies.The application provides thorough data analysis and real-time statistics as well as features such as sorting errors by priority. The app is compatible with all popular desktop and mobile devices.",
      },

      {
        title: "VirusGeeks",
        image1: Virus,
        technologies: [" Angular", "NextJs", "NestJs"],
        para: "Healthcare Platform VirusGeeks, a BioHealth Technology platform, which provide health tech solutions such as end-to-end Covid-19 testing.",
      },
      {
        title: "Parsl",
        image1: parsl,
        technologies: [" Angular", "Node.js"],
        para: "Parsl is a platform that combines blockchain, NFC and IoT to create a track and trace technology that not only automates compliance efficiently and accurately but offers a range of tangible business benefits for every stage of the cannabis supply chain. ",
      },
      {
        title: "Cronage",
        image1: Cronage,
        technologies: [" Angular", "Bootstrap", "HTML", "CSS"],
        para: "  New businesses must strike while the iron is hot. Chasing after a fast time-to-market can make your business vulnerable to performance risks. We helped Distractify perform a fast but extremely thorough Quality Assurance process before they hit “publish” on their first viral story.",
      },
    ],
    OurClients: [
      {
        Image: cronage,
      },

      {
        Image: GreekVirous,
      },

      {
        Image: Lottosocialogo,
      },
      {
        Image: fooz,
      },
      {
        Image: Lumieres,
      },
      {
        Image: parslImg,
      },
      {
        Image: fork,
      },
    ],
    Testimonials: [
      {
        label1:
          "“They are incredibly client-focused, so we saw them as partners rather than service suppliers. Applying industry cutting edge- practices and knowledge of travel, real estate, technology, and software business domain they have managed to deliver the project on time and of the highest quality.”",
        img: "",
        name: "Derick, Hatchster Ltd. UK",
        post: "",
      },
      {
        label2:
          "“I’ve been working with Senwell Group Private Limited for several outsourcing projects for clients in Canada and US, and I  have to say that the guys here deliver really great work and oftentimes outperform the clients’ own  development teams. I’ve never met such productivity before and that’s probably one of the biggest,  although not the only one advantage of the company..”",
        img: "",
        name: "Kevin Atom, Property Hatch Ltd, United State",
        post: "",
      },
      {
        label3:
          "“We are enormously satisfied with Senwell Group Private Limited as contractor. Highly responsive team with  profound understanding of subject matter and really very, very fast code delivery that is particularly  important for our travel business. . ”",
        img: "",
        name: "Nicolo F, Italy",
        post: "",
      },
    ],
    GetInTouchSecondbanner: [
      {
        GetInTouchTitle: "Follow best Angular development standards",
        Button: "Estimate a project",
        image: AngularGetinTouchBanner,
        para: "The client-facing side of an app determines its success. After all, if it doesn’t look and work well, who’s going to use it? Our Angular development services pair developers with designers to create the most inviting, intuitive, and memorable user interfaces for your users to enjoy.",
      },
    ],

    WhyChooseUs: [
      {
        WhyChooseUsTitle: "Why Choose Us",
        WhyChooseUs: [
          {
            icons: "",
            title: "Start with confidence.",
            para: "Our goal is to help you launch quickly and make sure you’ll want to work with us again.",
          },
          {
            icons: "",
            title: "100% Transparency",
            para: "Clear communication, flexible pricing, and full dedication to your success.",
          },
          {
            icons: "",
            title: "Excellent Code Quality",
            para: "We check the quality of our code at every step of development to ensure best results.",
          },
          {
            icons: "",
            title: "Cross-domain expertise",
            para: "Need services beyond front-end development? We will have you covered.",
          },
        ],
      },
    ],

    Forms: [
      {
        formTitle: "Get a quote today!",
        formSubtitle: "Enter your project details.",
      },
    ],
  },
  {
    id: 3,
    name: "nodejs",
    Technologies: "Back-end",
    HeroBanner: [
      {
        name: "nodejs",
        Page: "Node js",
        title: "Node js Development Services",
        breadcrums: "Back-end ",
        Link: "/technologies",
        button: "Hire Now",
        img: NodeBanner,
        image1: CmmiImage,
        image2: Union,
        image3: LogoImage,
        para: "From custom real-time web apps and chat programs to scalable network systems, collaboration tools, and APIs — our Node.js development team can build it for you.",
      },
    ],

    skill: [
      {
        PerformanceTitle: "Easy Scalability. Faster Time-to-market.",
        PerformancePara:
          "Node.js is a JavaScript framework that is very light. To speed up data processing, this event-based design uses Javascript on both the server and client sides. Our Node.js developers assist with the visioning, design, and development of Node.js web applications for business value and excellence across many disciplines.",
        PerformanceList: [
          {
            Numbers: "",
            PerformanceListTitle: "Skilled Developers",
            PerformanceListPara:
              "Whether you hire one or more Node.js developers, we guarantee that you will be working with Middle and Senior level experts only.",
          },
          {
            Numbers: "",
            PerformanceListTitle: " Node.js Projects",
            PerformanceListPara:
              "Our Node.js development outsourcing has filled our portfolio with lots of projects worthy of your attention.",
          },
          {
            Numbers: "",
            PerformanceListTitle: "Years of Experience",
            PerformanceListPara:
              "Our Agile approach, years of experience, and mature processes allow us to handle Node.js projects of any complexity and scale.",
          },
        ],
      },
    ],
    TechnologiesExpertiesTitle: "Our Technology Expertise",
    TechnologiesExpertiesPara:
      "The expert developers at Senwell Group Private Limited can provide more than just Node.js development outsourcing. We have the ability to create high-quality software utilizing a variety of modern technologies, programming languages, and tools.",

    TechnologiesExperties: [
      {
        icon: (
          <img
            src={Csharp}
            className="icon"
            style={{ i: "#4eb7ff" }}
            alt="C#"
            title= "C#"
          />
        ),
        title: "C#",
      },
      {
        icon: <DiJqueryLogo className="icon" style={{ i: "#4eb7ff" }} />,
        title: "Jquery",
      },
      {
        icon: (
          <FaNodeJs className="icon" style={{ i: "#4eb7ff", color: "green" }} />
        ),
        title: "NodeJs",
      },
      {
        icon: (
          <img
            src={Dotnet}
            className="icon"
            style={{ i: "#4eb7ff" }}
            alt="Dotnet"
            title= "Dotnet"
          />
        ),
        title: ".Net",
      },
      {
        icon: (
          <img
            src={Postman}
            className="icon"
            style={{ i: "#4eb7ff" }}
            alt="Postman"
            title= "Dotnet"
          />
        ),
        title: "Postman",
      },
      {
        icon: (
          <img
            src={Java}
            className="icon"
            style={{ i: "#4eb7ff" }}
            alt="Java"
            title= "Java"
          />
        ),
        title: "Java",
      },
    ],
    DevelopmentStep: [
      {
        DevelopmentSteptitle: "How to Get Started",
        Developmentstep: [
          {
            icon: "",
            title: "Reach out to us",
            description:
              "Fill our contact form, tell us about your project, and we’ll be in touch within one business day.",
          },
          {
            icon: "",
            title: "Outline your goals",
            description:
              "Your personal manager will provide a quote based on your project requirements.",
          },
          {
            icon: "",
            title: "Meet your team",
            description:
              "We will handpick the best suitable candidates for your Node.js development project.",
          },

          {
            icon: "",
            title: "Get to work",
            description:
              "Now that your team is formed, let’s turn your ideas into a successful Node.js solution!",
          },
        ],
      },
    ],
    GetInTouch: [
      {
        GetInTouchTitle:
          "Through Node.js development services, businesses can pursue new business prospects. We'll help you get started quickly and confidently.",
        Button: "Hire developers",
      },
    ],

    Benefits: [
      {
        title: "Benefits of Node js",
        Benefitstitle: " Development with Senwell",
        BenefitsofDevelopmentWithSenwell: [
          {
            Number: "01",
            title: "New Employee Trial Periods",
            description:
              "Before making a final decision, we provide our clients the opportunity to practise with the prospects. We want you to have faith in our ability to complete the project on time and on budget.",
          },
          {
            Number: "02",
            title: "Monitoring of Transparent Code",
            description:
              "When you hire Senwell Group Private Limited, you have access to our unique tools, which give important code quality metrics to assist your project in standardising and maintaining code quality.",
          },
          {
            Number: "03",
            title: "Rental of equipment is included",
            description:
              "You will not be charged extra for our professionals' workstations or equipment. You also receive access to over 50 real devices to test your app, ranging from smartphones to tablets.",
          },
          {
            Number: "04",
            title: "Workflows and Processes That Are Flexible",
            description:
              "Our highly skilled personnel can not only rapidly and effortlessly integrate with your team, but also assist improve development procedures for optimal project productivity.",
          },
          {
            Number: "05",
            title: "Includes system administration",
            description:
              "Don't worry about anything but the success of your project; we'll handle all administrative and IT assistance, including helping you set up servers, integrate project management software, and create effective communication, among other things.",
          },
          {
            Number: "06",
            title: "Resource Replacement in a Hurry",
            description:
              "When it comes to selecting the finest individuals for our client’s needs, we are extremely meticulous. If a discrepancy does occur, we will promptly replace it with a suitable replacement.",
          },
        ],
      },
    ],
    ProjectPortfollio: [
      {
        title: "Fooz",
        image1: Fooz,
        technologies: [" React", "Redux", "Node.js", "Bootstrap"],
        para: "The UAE-born, play-to-win online platform introduced by Arab Millionaire is offering participants the chance to “Dream Big, Give More” with the largest prizes in the Arab World",
      },
      {
        title: "LottoSocial",
        image1: Lotto,
        technologies: [" Angular", "Ionic", "Node.js", "Firebase"],
        para: "Web Application to create and manage online lottery syndicates. Increase your chances of winning big and join a lottery syndicate. We made it simple to create and manage lottery syndicates online, offering a combination of ticket types.",
      },

      {
        title: "Lumiere32- B2B Supplier Marketplace",
        image1: Lumiere,
        technologies: [" Angular", ", NestJs", "MySQL"],
        para: " We developed an effective web application that helps developers to write semantically correct code and form useful coding habits with better practices, superior tools, and proven methodologies.The application provides thorough data analysis and real-time statistics as well as features such as sorting errors by priority. The app is compatible with all popular desktop and mobile devices.",
      },

      {
        title: "VirusGeeks",
        image1: Virus,
        technologies: [" Angular", "NextJs", "NestJs"],
        para: "Healthcare Platform VirusGeeks, a BioHealth Technology platform, which provide health tech solutions such as end-to-end Covid-19 testing.",
      },
      {
        title: "Parsl",
        image1: parsl,
        technologies: [" Angular", "Node.js"],
        para: "Parsl is a platform that combines blockchain, NFC and IoT to create a track and trace technology that not only automates compliance efficiently and accurately but offers a range of tangible business benefits for every stage of the cannabis supply chain. ",
      },
      {
        title: "Cronage",
        image1: Cronage,
        technologies: [" Angular", "Bootstrap", "HTML", "CSS"],
        para: "  New businesses must strike while the iron is hot. Chasing after a fast time-to-market can make your business vulnerable to performance risks. We helped Distractify perform a fast but extremely thorough Quality Assurance process before they hit “publish” on their first viral story.",
      },
    ],
    OurClients: [
      {
        Image: cronage,
      },

      {
        Image: GreekVirous,
      },

      {
        Image: Lottosocialogo,
      },
      {
        Image: fooz,
      },
      {
        Image: Lumieres,
      },
      {
        Image: parslImg,
      },
      {
        Image: fork,
      },
    ],
    Testimonials: [
      {
        label1:
          "“They are incredibly client-focused, so we saw them as partners rather than service suppliers. Applying industry cutting edge- practices and knowledge of travel, real estate, technology, and software business domain they have managed to deliver the project on time and of the highest quality.”",
        img: "",
        name: "Derick, Hatchster Ltd. UK",
        post: "",
      },
      {
        label2:
          "“I’ve been working with Senwell Group Private Limited for several outsourcing projects for clients in Canada and US, and I  have to say that the guys here deliver really great work and oftentimes outperform the clients’ own  development teams. I’ve never met such productivity before and that’s probably one of the biggest,  although not the only one advantage of the company..”",
        img: "",
        name: "Kevin Atom, Property Hatch Ltd, United State",
        post: "",
      },
      {
        label3:
          "“We are enormously satisfied with Senwell Group Private Limited as contractor. Highly responsive team with  profound understanding of subject matter and really very, very fast code delivery that is particularly  important for our travel business. . ”",
        img: "",
        name: "Nicolo F, Italy",
        post: "",
      },
    ],
    GetInTouchSecondbanner: [
      {
        GetInTouchTitle:
          " Lean back and enjoy your successful development journey!",
        Button: "Get a Quote today",
        image: NodejsImg,
        para: "Build a scalable web app fast and within budget with expert-level Node.js development outsourcing. From a single engineer to an entire development team, we’ve got the talent you’re looking for.",
      },
    ],
    WhyChooseUs: [
      {
        WhyChooseUsTitle: "Why Choose Us",
        WhyChooseUs: [
          {
            icons: "",
            title: "Start with Confidence.",
            para: "Our goal is to help you launch quickly and make sure you’ll want to work with us again.",
          },
          {
            icons: "",
            title: "100% Transparency",
            para: "Clear communication, flexible pricing, and full dedication to your success.",
          },
          {
            icons: "",
            title: "Excellent Code Quality",
            para: "We check the quality of our code at every step of development to ensure best results.",
          },
          {
            icons: "",
            title: "Cross-domain expertise",
            para: "Need services beyond Back-End Development? We will have you covered.",
          },
        ],
      },
    ],

    Forms: [
      {
        formTitle: "Get a quote today!",
        formSubtitle: "Enter your project details.",
      },
    ],
  },
  {
    id: 4,
    name: "dot-net",
    Technologies: "Back-end",
    HeroBanner: [
      {
        Page: " .Net Development Services",
        name: "dot-net",
        title: "Hire Microsoft .Net Development",
        breadcrums: "Back-end   ",
        Link: "/technologies",
        button: "Hire Now",
        img: netBanner,
        image1: CmmiImage,
        image2: Union,
        image3: LogoImage,
        para: "We build robust applications for the web and mobile space utilizing the latest technologies including the .NET framework by Microsoft.",
      },
    ],

    skill: [
      {
        PerformanceTitle: "We target our expertise at your business issues. ",
        PerformancePara:
          "Senwell solution is a global provider of high-quality and affordable .NET development services. Our dedicated .NET experts will adapt to your exact requirements, giving top-quality .NET development services, based on their years of expertise in numerous sectors.",
        PerformanceList: [
          {
            Numbers: "",
            PerformanceListTitle: "Engineers",
            PerformanceListPara:
              "Whether you hire one or more .Net developers, we guarantee that you will be working with Middle and Senior level experts only.",
          },
          {
            Numbers: "",
            PerformanceListTitle: " Projects",
            PerformanceListPara:
              "Our portfolio is a solid demonstration of how we turn the needs of our clients into digital solutions.",
          },
          {
            Numbers: "",
            PerformanceListTitle: "Years of Experience",
            PerformanceListPara:
              "Having spent over a decade on the IT market, we understand every nuance of software development.",
          },
        ],
      },
    ],
    TechnologiesExpertiesTitle: "Tech Stack ",
    TechnologiesExpertiesPara:
      "We are experts in a variety of fields and technologies, including.NET development. Every project we work on is focused on adding value to our clients and the industry as a whole. This explains our desire for the cleanest code possible and for the best possible performance.",

    TechnologiesExperties: [
      {
        icon: (
          <img
            src={Dotnet}
            className="icon"
            style={{ i: "#4eb7ff" }}
            alt="Dontnet"
            title= "Dotnet"
          />
        ),
        title: ".Net",
      },
      // {
      //   icon: <FaPhp className="icon" style={{ i: "#4eb7ff" }} />,
      //   title: "PHP",
      // },
      // {
      //   icon: <FaPython className="icon" style={{ i: "#4eb7ff" }} />,
      //   title: "Python",
      // },
      // {
      //   icon: <DiMongodb className="icon" style={{ i: "#4eb7ff" }} />,
      //   title: "Mongodb",
      // },
      // {
      //   icon: <FaVuejs className="icon" style={{ i: "#4eb7ff" }} />,
      //   title: "Vue.Js",
      // },
      {
        icon: (
          <img
            src={Csharp}
            className="icon"
            style={{ i: "#4eb7ff" }}
            alt="Csharp"
            title="Csharp"
          />
        ),
        title: "C#",
      },
    ],
    DevelopmentStep: [
      {
        DevelopmentSteptitle: "How to Get Started",
        Developmentstep: [
          {
            icon: "",
            title: "Get to know us",
            description:
              "Fill in a quick form so we can reach out to you and discuss your software development needs.",
          },
          {
            icon: "",
            title: "Set an objective",
            description:
              "A manager assigned to your project will help you define long-term goals for your software.",
          },
          {
            icon: "",
            title: "Form a team",
            description:
              "We’ll gather the most suitable .NET developers to work full-time on your project.",
          },
          {
            icon: "",
            title: "Start working",
            description:
              "After you’ve met and approved the team, the development process is all set for the new beginning!",
          },
        ],
      },
    ],
    GetInTouch: [
      {
        GetInTouchTitle:
          "We create what you describe! We will transform your ideas into a capable software solution if you hire.NET engineers. ",
        Button: "Get In Touch",
      },
    ],

    Benefits: [
      {
        title: "Benefits of .NET",
        Benefitstitle: " Development with Senwell",
        BenefitsofDevelopmentWithSenwell: [
          {
            Number: "01",
            title: "Microsoft certification",
            description:
              "Senwell Group Private Limited is a Microsoft-certified partner, which means that all of the our.NET projects receive direct Microsoft technical support.",
          },
          {
            Number: "02",
            title: "Transparency in business",
            description:
              "From the beginning, you have unrestricted access to the outcomes of our efforts on your project.",
          },
          {
            Number: "03",
            title: "Workflow that is adaptable",
            description:
              "When we set up our technical cooperation, we'll tailor our operations to your schedule and goals.",
          },
          {
            Number: "04",
            title: "Quality comes first.",
            description:
              "We test apps in circumstances that most developers do not expect to occur since we are quality-conscious.",
          },
          {
            Number: "05",
            title: "Complete coverage",
            description:
              "System administration, equipment, and human resource management are all taken care of for you.",
          },
          {
            Number: "06",
            title: "Lifetime updates and support",
            description:
              "Our job does not end with coding; we will continue to ensure that your product is current and functional",
          },
        ],
      },
    ],
    ProjectPortfollio: [
      {
        title: "Lumiere32- B2B Supplier Marketplace",
        image1: Lumiere,
        technologies: [" Angular", ", NestJs", "MySQL"],
        para: " We developed an effective web application that helps developers to write semantically correct code and form useful coding habits with better practices, superior tools, and proven methodologies.The application provides thorough data analysis and real-time statistics as well as features such as sorting errors by priority. The app is compatible with all popular desktop and mobile devices.",
      },
      {
        title: "Fork-Freight",
        image1: Fork,
        technologies: [" Angular", "Asp.Net"],
        para: "Trucking/Transportation Web application for transportation Industry.Fork Freight is an app which provides high-end, affordable services that will help carriers, shippers, brokers, and dispatchers grow their business.",
      },
      {
        title: "LottoSocial",
        image1: Lotto,
        technologies: [" Angular", "Ionic", "Node.js", "Firebase"],
        para: "Web Application to create and manage online lottery syndicates. Increase your chances of winning big and join a lottery syndicate. We made it simple to create and manage lottery syndicates online, offering a combination of ticket types.",
      },
      {
        title: "Fork-Freight",
        image1: Fork,
        technologies: [" Angular", "Asp.Net"],
        para: "Trucking/Transportation Web application for transportation Industry.Fork Freight is an app which provides high-end, affordable services that will help carriers, shippers, brokers, and dispatchers grow their business.",
      },

      {
        title: "Fooz",
        image1: Fooz,
        technologies: [" React", "Redux", "Node.js", "Bootstrap"],
        para: "The UAE-born, play-to-win online platform introduced by Arab Millionaire is offering participants the chance to “Dream Big, Give More” with the largest prizes in the Arab World",
      },
      {
        title: "VirusGeeks",
        image1: Virus,
        technologies: [" Angular", "NextJs", "NestJs"],
        para: "Healthcare Platform VirusGeeks, a BioHealth Technology platform, which provide health tech solutions such as end-to-end Covid-19 testing.",
      },
      {
        title: "Parsl",
        image1: parsl,
        technologies: [" Angular", "Node.js"],
        para: "Parsl is a platform that combines blockchain, NFC and IoT to create a track and trace technology that not only automates compliance efficiently and accurately but offers a range of tangible business benefits for every stage of the cannabis supply chain. ",
      },
      {
        title: "Cronage",
        image1: Cronage,
        technologies: [" Angular", "Bootstrap", "HTML", "CSS"],
        para: "  New businesses must strike while the iron is hot. Chasing after a fast time-to-market can make your business vulnerable to performance risks. We helped Distractify perform a fast but extremely thorough Quality Assurance process before they hit “publish” on their first viral story.",
      },
    ],
    OurClients: [
      {
        Image: cronage,
      },

      {
        Image: GreekVirous,
      },

      {
        Image: Lottosocialogo,
      },
      {
        Image: fooz,
      },
      {
        Image: Lumieres,
      },
      {
        Image: parslImg,
      },
      {
        Image: fork,
      },
    ],
    Testimonials: [
      {
        label1:
          "“They are incredibly client-focused, so we saw them as partners rather than service suppliers. Applying industry cutting edge- practices and knowledge of travel, real estate, technology, and software business domain they have managed to deliver the project on time and of the highest quality.”",
        img: "",
        name: "Derick, Hatchster Ltd. UK",
        post: "",
      },
      {
        label2:
          "“I’ve been working with Senwell Group Private Limited for several outsourcing projects for clients in Canada and US, and I  have to say that the guys here deliver really great work and oftentimes outperform the clients’ own  development teams. I’ve never met such productivity before and that’s probably one of the biggest,  although not the only one advantage of the company..”",
        img: "",
        name: "Kevin Atom, Property Hatch Ltd, United State",
        post: "",
      },
      {
        label3:
          "“We are enormously satisfied with Senwell Group Private Limited as contractor. Highly responsive team with  profound understanding of subject matter and really very, very fast code delivery that is particularly  important for our travel business. . ”",
        img: "",
        name: "Nicolo F, Italy",
        post: "",
      },
    ],
    GetInTouchSecondbanner: [
      {
        GetInTouchTitle:
          " More Features, Same Smooth Performance ― with .NET Framework",
        Button: "Estimate my Project",
        image: dotnetImg,
        para: "Our .NET developers do not blindly focus on a single approach. They look into all the possible options and choose the one that would fit your future software solution the best. Click below to get an estimation of your project.",
      },
    ],
    WhyChooseUs: [
      {
        WhyChooseUsTitle: "Why Choose Us",
        WhyChooseUs: [
          {
            icons: "",
            title: "Quick start",
            para: "We keep our processes bureaucracy-free, so it usually doesn’t take more than a few days to start the collaboration.",
          },
          {
            icons: "",
            title: "Fair deal",
            para: "All our operations are 100% transparent and it’s only you who decides how deeply you want to be involved in the development.",
          },
          {
            icons: "",
            title: "Flawless code",
            para: "We love software testing just as much as we love coding, so keep your expectations high when it comes to QA services from us.",
          },
          {
            icons: "",
            title: "Industry experts",
            para: "If your project evolves into something more technically complex over time, no worries: so will your Senwell Group Private Limited dedicated team.",
          },
        ],
      },
    ],

    Forms: [
      {
        formTitle: "Get a quote today!",
        formSubtitle: "Enter your project details.",
      },
    ],
  },
  {
    id: 5,
    name: "react-native",
    Technologies: "front-end",
    HeroBanner: [
      {
        name: "react-native",
        Page: " React Native Development Services",
        breadcrums: "Mobile",
        title: "Hire React Native Development",
        button: "Hire Now",
        img: reactnativeBanner,
        image1: CmmiImage,
        image2: Union,
        image3: LogoImage,
        para: "Work with our skilled React Native engineers to build robust mobile applications using one of the most popular mobile development frameworks.",
      },
    ],

    skill: [
      {
        PerformanceTitle: "Fast Start,Quality Code.",
        PerformancePara:
          "React Native App Development is one of the most efficient ways to create mobile apps, By designing apps in the React Native Framework, Our technical experts can help you get them to market faster on multiple platforms, and manage them more effectively, and at a lower cost Our React Native developers use the framework to its full potential, finding the single best way to implement the features your business needs to succeed.",
        PerformanceList: [
          {
            Numbers: "",
            PerformanceListTitle: "Extensive experience",
            PerformanceListPara:
              "Years of reliable mobile application development service with a proven track record of success stories.",
          },
          {
            Numbers: "",
            PerformanceListTitle: " Accomplished professionals ",
            PerformanceListPara:
              "Qualified mobile application engineers with strong programming skills and deep expertise in various domains.",
          },
          {
            Numbers: "",
            PerformanceListTitle: "Trusted partnerships",
            PerformanceListPara:
              "Hundreds of companies around the world entrusted us with building and maintaining their apps.",
          },
        ],
      },
    ],
    TechnologiesExpertiesTitle: "Our Technology Expertise",
    TechnologiesExpertiesPara:
      "React Native is one of the main development frameworks that we work with, Whether you need a native or hybrid application, a simple project or a complex enterprise suite with cross-platform capabilities — our React Native engineers will have you covered.",
    TechnologiesExperties: [
      {
        icon: (
          <IoLogoJavascript
            className="icon"
            style={{ i: "#4eb7ff", backgroundColor: "yellow", color: "black" }}
          />
        ),
        title: "JavaScript",
      },
      {
        icon: <FaReact className="icon" style={{ i: "#4eb7ff" }} />,
        title: "React Native",
      },

      {
        icon: (
          <SiTypescript
            className="icon"
            style={{ i: "#4eb7ff", backgroundColor: "black", color: "yellow" }}
          />
        ),
        title: "TypeScript",
      },
      // {
      //   icon: <FaVuejs className="icon" style={{ i: "#4eb7ff" }} />,
      //   title: "Vue.Js",
      // },
    ],
    DevelopmentStep: [
      {
        DevelopmentSteptitle: "How to Get Started",
        Developmentstep: [
          {
            icon: "",
            title: "Reach out to us",
            description:
              "Fill out the contact form on our website providing basic information on what you need .",
          },
          {
            icon: "",
            title: "Outline your goals",
            description:
              "Now that we’ve connected and discussed the request, your personal manager will gather.",
          },
          {
            icon: "",
            title: "Meet your team",
            description:
              "We analyze your request and provide you with a list of handpicked candidates best.",
          },

          {
            icon: "",
            title: "Get to work",
            description:
              "Now that you’ve decided on your options for collaboration, the required specialists, their time.",
          },
        ],
      },
    ],
    GetInTouch: [
      {
        GetInTouchTitle:
          "Ready to conquer the digital frontier? Build a powerful native or cross-platform mobile app with experienced React Native engineers!",
        Button: "Get In Touch",
      },
    ],

    Benefits: [
      {
        title: " Building React Native ",
        Benefitstitle: "mobile apps with senwell is",
        BenefitsofDevelopmentWithSenwell: [
          {
            Number: "01",
            title: "New employee trial periods are free",
            description:
              "We allow our clients to try out candidates before making a final decision, so you can trust that the task will be completed on time and on budget.",
          },
          {
            Number: "02",
            title: "Transparent code quality monitoring",
            description:
              "You will not be charged extra for our professionals' workstations or equipment. You also receive access to over 50 real devices to test your app, ranging from smartphones to tablets.",
          },
          {
            Number: "03",
            title: "Rental of equipment is included",
            description:
              "You will not be charged extra for our professionals' workstations or equipment. You also receive access to over 50 real devices to test your app, ranging from smartphones to tablets.",
          },
          {
            Number: "04",
            title: "Includes system administration",
            description:
              "Don't worry about anything but the success of your project; we'll handle all of the administrative and IT support for you, including helping you set up effective communication, integrating project management tools, and more.",
          },
          {
            Number: "05",
            title:
              "When necessary, tailoring Senwell Group Private Limited and Company procedures",
            description:
              "Our highly skilled personnel can not only rapidly and effortlessly integrate with your team, but also assist improve development procedures for optimal project productivity.",
          },
          {
            Number: "06",
            title: "Unwanted resources are immediately replaced.",
            description:
              "When it comes to selecting the finest individuals for our client’s needs, we are extremely meticulous. If a discrepancy does occur, we will promptly replace it with a suitable replacement.",
          },
          ,
        ],
      },
    ],
    ProjectPortfollio: [
      {
        title: "Fooz",
        image1: Fooz,
        technologies: [" React", "Redux", "Node.js", "Bootstrap"],
        para: "The UAE-born, play-to-win online platform introduced by Arab Millionaire is offering participants the chance to “Dream Big, Give More” with the largest prizes in the Arab World",
      },
      {
        title: "LottoSocial",
        image1: Lotto,
        technologies: [" Angular", "Ionic", "Node.js", "Firebase"],
        para: "Web Application to create and manage online lottery syndicates. Increase your chances of winning big and join a lottery syndicate. We made it simple to create and manage lottery syndicates online, offering a combination of ticket types.",
      },
      {
        title: "Fork-Freight",
        image1: Fork,
        technologies: [" Angular", "Asp.Net"],
        para: "Trucking/Transportation Web application for transportation Industry.Fork Freight is an app which provides high-end, affordable services that will help carriers, shippers, brokers, and dispatchers grow their business.",
      },
      {
        title: "Lumiere32- B2B Supplier Marketplace",
        image1: Lumiere,
        technologies: [" Angular", ", NestJs", "MySQL"],
        para: " We developed an effective web application that helps developers to write semantically correct code and form useful coding habits with better practices, superior tools, and proven methodologies.The application provides thorough data analysis and real-time statistics as well as features such as sorting errors by priority. The app is compatible with all popular desktop and mobile devices.",
      },

      {
        title: "VirusGeeks",
        image1: Virus,
        technologies: [" Angular", "NextJs", "NestJs"],
        para: "Healthcare Platform VirusGeeks, a BioHealth Technology platform, which provide health tech solutions such as end-to-end Covid-19 testing.",
      },
      {
        title: "Parsl",
        image1: parsl,
        technologies: [" Angular", "Node.js"],
        para: "Parsl is a platform that combines blockchain, NFC and IoT to create a track and trace technology that not only automates compliance efficiently and accurately but offers a range of tangible business benefits for every stage of the cannabis supply chain. ",
      },
      {
        title: "Cronage",
        image1: Cronage,
        technologies: [" Angular", "Bootstrap", "HTML", "CSS"],
        para: "  New businesses must strike while the iron is hot. Chasing after a fast time-to-market can make your business vulnerable to performance risks. We helped Distractify perform a fast but extremely thorough Quality Assurance process before they hit “publish” on their first viral story.",
      },
    ],
    OurClients: [
      {
        Image: cronage,
      },

      {
        Image: GreekVirous,
      },

      {
        Image: Lottosocialogo,
      },
      {
        Image: fooz,
      },
      {
        Image: Lumieres,
      },
      {
        Image: parslImg,
      },
      {
        Image: fork,
      },
    ],
    Testimonials: [
      {
        label1:
          "“They are incredibly client-focused, so we saw them as partners rather than service suppliers. Applying industry cutting edge- practices and knowledge of travel, real estate, technology, and software business domain they have managed to deliver the project on time and of the highest quality.”",
        img: "",
        name: "Derick, Hatchster Ltd. UK",
        post: "",
      },
      {
        label2:
          "“I’ve been working with Senwell Group Private Limited for several outsourcing projects for clients in Canada and US, and I  have to say that the guys here deliver really great work and oftentimes outperform the clients’ own  development teams. I’ve never met such productivity before and that’s probably one of the biggest,  although not the only one advantage of the company..”",
        img: "",
        name: "Kevin Atom, Property Hatch Ltd, United State",
        post: "",
      },
      {
        label3:
          "“We are enormously satisfied with Senwell Group Private Limited as contractor. Highly responsive team with  profound understanding of subject matter and really very, very fast code delivery that is particularly  important for our travel business. . ”",
        img: "",
        name: "Nicolo F, Italy",
        post: "",
      },
    ],
    GetInTouchSecondbanner: [
      {
        GetInTouchTitle: "Have an idea? Let’s get to work!",
        Button: "Get a Quote",
        image: reactnativeImg,
        para: "Whether you need a single engineer or an entire team, our React Native developers are ready to prove themselves to you. Run a pilot project to make certain Senwell Group Private Limited is exactly what you’re looking for!",
      },
    ],
    WhyChooseUs: [
      {
        WhyChooseUsTitle: "Why Senwell",
        WhyChooseUs: [
          {
            icons: "",
            title: "Start with confidence..",
            para: "Our goal is to help you launch quickly and make sure you’ll want to work with us again.",
          },
          {
            icons: "",
            title: "100% Transparency",
            para: "Clear communication, flexible pricing, and full dedication to your success.",
          },
          {
            icons: "",
            title: "Cross-domain expertise",
            para: "Senwell portfolio holds various projects belonging to the most diverse industries, from healthcare to automotive, finance, and eCommerce.",
          },
          {
            icons: "",
            title: "Excellent Code Quality",
            para: "We check the quality of our code at every step of development to ensure best results.",
          },
        ],
      },
    ],
    Forms: [
      {
        formTitle: "Write to us.",
        formSubtitle: "Tell us about your project.",
      },
    ],
  },
  {
    id: 6,
    name: "qa",
    HeroBanner: [
      {
        name: "qa",
        Page: " QA",
        title: "Quality Assurance",
        button: "Contact us",
        image1: CmmiImage,
        image2: Union,
        image3: LogoImage,
        para: "We will help you launch software, apps, and websites that run smoothly, reliably, and with no bugs.",
      },
    ],

    skill: [
      {
        PerformanceTitle: "Smooth QA and Superb Product",
        PerformancePara:
          "Outsourcing software testing allows businesses to minimize employee onboarding time and completely eliminate the need to scout, interview, and hire QA engineers for their in-house team. Cut costs, save time, and start testing. ",
        PerformanceList: [
          {
            Numbers: "",
            PerformanceListTitle: "Projects Delivered",
            PerformanceListPara:
              "Our staff has assisted over 500 clients in developing solutions that are functional, interoperable, secure, and user-friendly, allowing them to build their businesses fast and simply.",
          },
          {
            Numbers: "",
            PerformanceListTitle: "Quality Assurance engineers",
            PerformanceListPara:
              "Our team consists of seasoned software QA professionals with vast knowledge of manual and automated testing for a wide range of software solutions in all industries.",
          },
          {
            Numbers: "",
            PerformanceListTitle: "Years of Experience",
            PerformanceListPara:
              "We've created and tested solutions for a wide range of enterprises and sectors, including healthcare, finance and banking, entertainment, and eCommerce.",
          },
        ],
      },
    ],
    TechnologiesExpertiesTitle: "Software QA Technologies",
    TechnologiesExpertiesPara:
      "Our software QA experience encompasses a wide range of cutting-edge techniques, technologies, and devices for successfully reporting issues and maintaining a high-quality software product. Our human and automated testing guarantees that your project is delivered quickly and thoroughly.",
    TechnologiesExperties: [
      {
        icon: (
          <img
            src={Selenium1}
            className="icon"
            style={{ i: "#4eb7ff" }}
            alt="Selenium1"
            title="Selenioum"
          />
        ),
        title: "Selenioum",
      },
      {
        icon: (
          <img
            src={junit}
            className="icon"
            style={{ i: "#4eb7ff" }}
            alt="junit"
            title="JUnit"
          />
        ),
        title: "JUnit",
      },
      {
        icon: (
          <img
            src={Jmeter}
            className="icon"
            style={{ i: "#4eb7ff", width: "50%" }}
            alt="Jmeter"
            title= "Jmeter"
          />
        ),
        title: "Jmeter",
      },
      {
        icon: (
          <img
            src={Postman}
            className="icon"
            style={{ i: "#4eb7ff" }}
            alt="Postman"
            title="Postman"
          />
        ),
        title: "Postman",
      },
    ],
    DevelopmentStep: [
      {
        DevelopmentSteptitle: "How to Outsource Software QA",
        Developmentstep: [
          {
            icon: "",
            title: "Get in Touch",
            description:
              "Write to us and share the overall goal of your project as well as any questions you have.",
          },
          {
            icon: "",
            title: "Define requirements",
            description:
              "The next step is to collect all the information needed to satisfy your project requirements.",
          },
          {
            icon: "",
            title: "Meet your team",
            description:
              "Then we handpick specialists who will be responsible for the deployment of your project.",
          },

          {
            icon: "",
            title: "Observe the Process",
            description:
              "We perform the tests to ensure that the bugs don’t make their way into production.",
          },
        ],
      },
    ],
    GetInTouch: [
      {
        GetInTouchTitle:
          "Are you tired of looking for a dependable, competent software quality assurance outsourcing partner?",
        Button: "Get In Touch",
      },
    ],

    Benefits: [
      {
        title: "Benefits of QA Outsourcing with Senwell",
        Benefitstitle: "with Senwell",
        BenefitsofDevelopmentWithSenwell: [
          {
            Number: "01",
            title: "Equipment Rental Included",
            description:
              "We provide over 250 common physical devices to ensure that your software solution is thoroughly tested and performs optimally across all platform",
          },
          {
            Number: "02",
            title: "Internal Training Programs",
            description:
              "Our internal educational initiatives guarantee that our software QA professionals are up to date on the newest technologies and advances in the sector.",
          },
          {
            Number: "03",
            title: "Strong Technical Control",
            description:
              "Our senior-level QA professionals are ready to assist your team in resolving technical issues and improving your project's SDLC procedures!",
          },
          {
            Number: "04",
            title: "Monitoring of Transparent Code",
            description:
              "To assure your trust in the quality and dependability of your software project, we use an organised, seamless, and transparent code review process.",
          },
          {
            Number: "05",
            title: "Workflow & Process Flexibility",
            description:
              "We're skilled in a variety of software QA and testing techniques, as well as project management processes. This allows us to immediately become familiar with your processes.",
          },
          {
            Number: "06",
            title: "Resource Replacement in a Hurry",
            description:
              "For your project team, we handpick the best engineers. We can swiftly replace team members or reinforce your team with additional specialists if difficulties develop.",
          },
        ],
      },
    ],
    ProjectPortfollio: [
      {
        title: "VirusGeeks",
        image1: Virus,
        technologies: [" Angular", "NextJs", "NestJs"],
        para: "Healthcare Platform VirusGeeks, a BioHealth Technology platform, which provide health tech solutions such as end-to-end Covid-19 testing.",
      },
      {
        title: "LottoSocial",
        image1: Lotto,
        technologies: [" Angular", "Ionic", "Node.js", "Firebase"],
        para: "Web Application to create and manage online lottery syndicates. Increase your chances of winning big and join a lottery syndicate. We made it simple to create and manage lottery syndicates online, offering a combination of ticket types.",
      },
      {
        title: "Fork-Freight",
        image1: Fork,
        technologies: [" Angular", "Asp.Net"],
        para: "Trucking/Transportation Web application for transportation Industry.Fork Freight is an app which provides high-end, affordable services that will help carriers, shippers, brokers, and dispatchers grow their business.",
      },
      {
        title: "Lumiere32- B2B Supplier Marketplace",
        image1: Lumiere,
        technologies: [" Angular", ", NestJs", "MySQL"],
        para: " We developed an effective web application that helps developers to write semantically correct code and form useful coding habits with better practices, superior tools, and proven methodologies.The application provides thorough data analysis and real-time statistics as well as features such as sorting errors by priority. The app is compatible with all popular desktop and mobile devices.",
      },
      {
        title: "Fooz",
        image1: Fooz,
        technologies: [" React", "Redux", "Node.js", "Bootstrap"],
        para: "The UAE-born, play-to-win online platform introduced by Arab Millionaire is offering participants the chance to “Dream Big, Give More” with the largest prizes in the Arab World",
      },

      {
        title: "Parsl",
        image1: parsl,
        technologies: [" Angular", "Node.js"],
        para: "Parsl is a platform that combines blockchain, NFC and IoT to create a track and trace technology that not only automates compliance efficiently and accurately but offers a range of tangible business benefits for every stage of the cannabis supply chain. ",
      },
      {
        title: "Cronage",
        image1: Cronage,
        technologies: [" Angular", "Bootstrap", "HTML", "CSS"],
        para: "  New businesses must strike while the iron is hot. Chasing after a fast time-to-market can make your business vulnerable to performance risks. We helped Distractify perform a fast but extremely thorough Quality Assurance process before they hit “publish” on their first viral story.",
      },
    ],
    OurClients: [
      {
        Image: cronage,
      },

      {
        Image: GreekVirous,
      },

      {
        Image: Lottosocialogo,
      },
      {
        Image: fooz,
      },
      {
        Image: Lumieres,
      },
      {
        Image: parslImg,
      },
      {
        Image: fork,
      },
    ],
    Testimonials: [
      {
        label1:
          "“They are incredibly client-focused, so we saw them as partners rather than service suppliers. Applying industry cutting edge- practices and knowledge of travel, real estate, technology, and software business domain they have managed to deliver the project on time and of the highest quality.”",
        img: "",
        name: "Derick, Hatchster Ltd. UK",
        post: "",
      },
      {
        label2:
          "“I’ve been working with Senwell Group Private Limited for several outsourcing projects for clients in Canada and US, and I  have to say that the guys here deliver really great work and oftentimes outperform the clients’ own  development teams. I’ve never met such productivity before and that’s probably one of the biggest,  although not the only one advantage of the company..”",
        img: "",
        name: "Kevin Atom, Property Hatch Ltd, United State",
        post: "",
      },
      {
        label3:
          "“We are enormously satisfied with Senwell Group Private Limited as contractor. Highly responsive team with  profound understanding of subject matter and really very, very fast code delivery that is particularly  important for our travel business. . ”",
        img: "",
        name: "Nicolo F, Italy",
        post: "",
      },
    ],
    GetInTouchSecondbanner: [
      {
        GetInTouchTitle: "Work with Us",
        Button: "Let's talk",
        image: QATesting,
        para: " Leverage our software QA outsourcing expertise to deliver high-quality products that will bring your business to the new heights.",
      },
    ],
    WhyChooseUs: [
      {
        WhyChooseUsTitle: "Why Clients Trust Us",
        WhyChooseUs: [
          {
            icon: (
              <FlashOnIcon
                style={{
                  color: "#3D8ED7",
                  paddingBottom: "1rem",
                  fontSize: "2.5rem",
                }}
              />
            ),
            title: "Start with confidence.",
            para: "We want you to find a team quickly and get straight to turning your vision into a reality.",
          },
          {
            icon: (
              <SearchIcon
                style={{
                  color: "#3D8ED7",
                  paddingBottom: "1rem",
                  fontSize: "2.5rem",
                }}
              />
            ),
            title: "100% Transparency",
            para: "Clear and consistent communication, flexible pricing, and full dedication to your success",
          },
          {
            icon: (
              <StarIcon
                style={{
                  color: "#3D8ED7",
                  paddingBottom: "1rem",
                  fontSize: "2.5rem",
                }}
              />
            ),
            title: "Excellent Code Quality",
            para: "Beyond QA, we ensure your code is clean and free of technical debt. ",
          },
          {
            icon: (
              <LocationOnIcon
                style={{
                  color: "#3D8ED7",
                  paddingBottom: "1rem",
                  fontSize: "2.5rem",
                }}
              />
            ),
            title: "Cross-domain expertise",
            para: "There’s no challenge we can’t handle with confidence and great aplomb.",
          },
        ],
      },
    ],
    Forms: [
      {
        formTitle: "Let’s get started.",
        formSubtitle: "Enter your project details.",
      },
    ],
  },
  {
    id: 7,
    name: "front-end",
    HeroBanner: [
      {
        name: "front-end",
        Page: "Front-End",
        title: "Hire Front-End Development",
        button: "Contact us",
        img: FrontendBanner,
        image1: CmmiImage,
        image2: Union,
        image3: LogoImage,
        para: "Build up the digital image of your business with the engaging design and functional perfection of our front-end solutions.",
      },
    ],

    skill: [
      {
        PerformanceTitle: "Rich Interfaces. Happy Users.",

        PerformancePara:
          "Leverage the capabilities of frontend development specialists with a visionary approach to designing the most prolific of user interfaces. Our developers assemble highly customized, well-informed, particularly intuitive, and engaging front-ends. These are mixed with the absolute best functionalities and features for magnificent user encounters on desktops, mobiles, tablets, and different devices. Attain high-end user interfaces that drive user commitment and interface experience to the next level, bringing about solid business growth and accomplishment of business objectives.",
        PerformanceList: [
          {
            Numbers: "",
            PerformanceListTitle: "Completed Projects",
            PerformanceListPara:
              "Over 500 satisfied clients rely on our high-quality front-end solutions to help their earnings increase and businesses thrive.",
          },
          {
            Numbers: "",
            PerformanceListTitle: "Dedicated Developers",
            PerformanceListPara:
              "Customer-obsessed professionals with broad software development experience, niche tech skills and deep industry-specific expertise.",
          },
          {
            Numbers: "",
            PerformanceListTitle: "Years of Experience",
            PerformanceListPara:
              "Over the years of reliable full-cycle software development service to our clients, we’ve honed our skills and matured our processes.",
          },
        ],
      },
    ],
    TechnologiesExpertiesTitle: "Tech Stack",
    TechnologiesExpertiesPara:
      "Our front-end development services include a variety of proven technologies, programming languages, and tools to tackle front-end development projects of any complexity and scale. We are flexible and have a wide range of expertise to help you choose the technology stack that best fits your business needs.",
    TechnologiesExperties: [
      {
        icon: (
          <IoLogoJavascript
            className="icon"
            style={{ backgroundColor: "yellow", color: "black" }}
          />
        ),
        title: "JavaScript",
      },
      {
        icon: <FaAngular className="icon" style={{ color: "red" }} />,
        title: "Angular",
      },
      {
        icon: <FaReact className="icon" />,
        title: "React",
      },
      {
        icon: (
          <SiTypescript
            className="icon"
            style={{ backgroundColor: "black", color: "yellow" }}
          />
        ),
        title: "TypeScript",
      },
      {
        icon: (
          <img
            src={Vue}
            className="icon"
            style={{ color: "green" }}
            alt="Vue"
            title="Vue"
          />
        ),
        title: "Vue.Js",
      },
    ],
    DevelopmentStep: [
      {
        DevelopmentSteptitle: "Front-End Development Step by Step",
        Developmentstep: [
          {
            icon: "",
            title: "Write to Us",
            description:
              "Tell us about your front-end development needs and we will take care of the rest.",
          },
          {
            icon: "",
            title: "Define requirements",
            description:
              "We will talk through all the necessary details, identify all tech specs and requests.",
          },
          {
            icon: "",
            title: "Meet your team",
            description:
              "We will handpick a development team that fits your needs best and start on your project at once.",
          },

          {
            icon: "",
            title: "Prepare to deploy",
            description:
              "Your project will be delivered on time and within proper budget and systematic Implementation and will continue to prosper!",
          },
        ],
      },
    ],
    GetInTouch: [
      {
        GetInTouchTitle:
          "Hire an experienced front-end development company and tailor a distinctive digital image for your business.",
        Button: "Get In Touch",
      },
    ],

    Benefits: [
      {
        title: "Advantages of Front-End ",
        Benefitstitle: "Development in Senwell",
        BenefitsofDevelopmentWithSenwell: [
          {
            Number: "01",
            title: "Top 1% IT talent ",
            description:
              "Enter an elite hub of top devoted front-end developers, UI/UX creators, QA specialists, and PMs with expert level talent effectively putting them among the top 1% of UI developers globally",
          },
          {
            Number: "02",
            title: "Time Zone Aligned ",
            description:
              "Experience cooperation more than ever, with front end designers following time zone aligned development in adherence to your enterprise project's timeframes and business objectives",
          },
          {
            Number: "03",
            title: "Experienced Team",
            description:
              "Expand your current team or leave the whole undertaking in the hands of 500+ tech experts that you can hire to tackle each business challenge across front end websites and apps",
          },
          {
            Number: "04",
            title: "Industry Best Practices ",
            description:
              "Guaranteed use of latest technology versions for predominant development assistance completely aligned to industry best practices while matching all your business objectives",
          },
          {
            Number: "05",
            title: "Focused Procedure ",
            description:
              "Hire dedicated frontend experts to emphasize your goals and match global benchmarks, thus bringing about timeframes and budgets",
          },
          {
            Number: "06",
            title: "Cost and Time Viability",
            description:
              "Access a remote team of frontend developers and optimize expense and time adequacy wiping out the requirement for protracted in-house hiring and training of development experts",
          },
        ],
      },
    ],
    ProjectPortfollio: [
      {
        title: "Cronage",
        image1: Cronage,
        technologies: [" Angular", "Bootstrap", "HTML", "CSS"],
        para: "  New businesses must strike while the iron is hot. Chasing after a fast time-to-market can make your business vulnerable to performance risks. We helped Distractify perform a fast but extremely thorough Quality Assurance process before they hit “publish” on their first viral story.",
      },
      {
        title: "Parsl",
        image1: parsl,
        technologies: [" Angular", "Node.js"],
        para: "Parsl is a platform that combines blockchain, NFC and IoT to create a track and trace technology that not only automates compliance efficiently and accurately but offers a range of tangible business benefits for every stage of the cannabis supply chain. ",
      },
      {
        title: "LottoSocial",
        image1: Lotto,
        technologies: [" Angular", "Ionic", "Node.js", "Firebase"],
        para: "Web Application to create and manage online lottery syndicates. Increase your chances of winning big and join a lottery syndicate. We made it simple to create and manage lottery syndicates online, offering a combination of ticket types.",
      },
      {
        title: "Fork-Freight",
        image1: Fork,
        technologies: [" Angular", "Asp.Net"],
        para: "Trucking/Transportation Web application for transportation Industry.Fork Freight is an app which provides high-end, affordable services that will help carriers, shippers, brokers, and dispatchers grow their business.",
      },
      {
        title: "Lumiere32- B2B Supplier Marketplace",
        image1: Lumiere,
        technologies: [" Angular", ", NestJs", "MySQL"],
        para: " We developed an effective web application that helps developers to write semantically correct code and form useful coding habits with better practices, superior tools, and proven methodologies.The application provides thorough data analysis and real-time statistics as well as features such as sorting errors by priority. The app is compatible with all popular desktop and mobile devices.",
      },
      {
        title: "Fooz",
        image1: Fooz,
        technologies: [" React", "Redux", "Node.js", "Bootstrap"],
        para: "The UAE-born, play-to-win online platform introduced by Arab Millionaire is offering participants the chance to “Dream Big, Give More” with the largest prizes in the Arab World",
      },
      {
        title: "VirusGeeks",
        image1: Virus,
        technologies: [" Angular", "NextJs", "NestJs"],
        para: "Healthcare Platform VirusGeeks, a BioHealth Technology platform, which provide health tech solutions such as end-to-end Covid-19 testing.",
      },
    ],
    OurClients: [
      {
        Image: cronage,
      },

      {
        Image: GreekVirous,
      },

      {
        Image: Lottosocialogo,
      },
      {
        Image: fooz,
      },
      {
        Image: Lumieres,
      },
      {
        Image: parslImg,
      },
      {
        Image: Forkfreight,
      },
    ],
    Testimonials: [
      {
        label1:
          "“They are incredibly client-focused, so we saw them as partners rather than service suppliers. Applying industry cutting edge- practices and knowledge of travel, real estate, technology, and software business domain they have managed to deliver the project on time and of the highest quality.”",
        img: "",
        name: "Derick, Hatchster Ltd. UK",
        post: "",
      },
      {
        label2:
          "“I’ve been working with Senwell Group Private Limited for several outsourcing projects for clients in Canada and US, and I  have to say that the guys here deliver really great work and oftentimes outperform the clients’ own  development teams. I’ve never met such productivity before and that’s probably one of the biggest,  although not the only one advantage of the company..”",
        img: "",
        name: "Kevin Atom, Property Hatch Ltd, United State",
        post: "",
      },
      {
        label3:
          "“We are enormously satisfied with Senwell Group Private Limited as contractor. Highly responsive team with  profound understanding of subject matter and really very, very fast code delivery that is particularly  important for our travel business. . ”",
        img: "",
        name: "Nicolo F, Italy",
        post: "",
      },
    ],
    GetInTouchSecondbanner: [
      {
        GetInTouchTitle: "Start your front-end development with confidence!",
        Button: "Let's talk",
        image: frontdevImg,
        para: "Leverage the broad technical skills and cross-domain expertise of a front-end development company proven by years of reliable service and 800+ success stories.",
      },
    ],
    WhyChooseUs: [
      {
        WhyChooseUsTitle: "Why Choose Us ",
        WhyChooseUs: [
          {
            icon: (
              <FlashOnIcon
                style={{
                  color: "#3D8ED7",
                  paddingBottom: "1rem",
                  fontSize: "2.5rem",
                }}
              />
            ),
            title: "Start with confidence.",
            para: "We want you to find a team quickly and get straight to turning your vision into a reality.",
          },
          {
            icon: (
              <SearchIcon
                style={{
                  color: "#3D8ED7",
                  paddingBottom: "1rem",
                  fontSize: "2.5rem",
                }}
              />
            ),
            title: "100% Transparency",
            para: "Clear and consistent communication, flexible pricing, and full dedication to your success",
          },
          {
            icon: (
              <StarIcon
                style={{
                  color: "#3D8ED7",
                  paddingBottom: "1rem",
                  fontSize: "2.5rem",
                }}
              />
            ),
            title: "Excellent Code Quality",
            para: "Beyond QA, we ensure your code is clean and free of technical debt. ",
          },
          {
            icon: (
              <LocationOnIcon
                style={{
                  color: "#3D8ED7",
                  paddingBottom: "1rem",
                  fontSize: "2.5rem",
                }}
              />
            ),
            title: "Cross-domain expertise",
            para: "There’s no challenge we can’t handle with confidence and great aplomb.",
          },
        ],
      },
    ],
    Forms: [
      {
        formTitle: "Write to us.",
        formSubtitle: "Tell us about your project.",
      },
    ],
  },
  {
    id: 8,
    name: "back-end",
    HeroBanner: [
      {
        name: "back-end",
        Page: "Back-End",
        title: "Hire Back-End Development",
        button: "Hire Now",
        img: Backend,
        image1: CmmiImage,
        image2: Union,
        image3: LogoImage,
        para: "Hire backend developers to complete your software with seamless performance, efficient data processing, and impenetrable security.",
      },
    ],

    skill: [
      {
        PerformanceTitle: "Efficient Processes. Outstanding Results.",
        PerformancePara:
          "To handle everything on the app, most systems or applications require a powerful backend. We offer backend development services that are both secure and reliable. Our in-house developers are skilled at creating a reliable and secure system. Now is the time to hire our experienced backend engineers to design your backend system!",
        PerformanceList: [
          {
            Numbers: "",
            PerformanceListTitle: "Years of back-end development",
            PerformanceListPara:
              "Extensive experience in building powerful and secure back-end solutions for various domains and applications.",
          },
          {
            Numbers: "",
            PerformanceListTitle: "Accomplished professionals",
            PerformanceListPara:
              "Our experienced backend engineers are skilled in a wide range of technologies and domains.",
          },
          {
            Numbers: "",
            PerformanceListTitle: "Success stories",
            PerformanceListPara:
              "With hundreds of projects completed, our qualified engineers are ready for jobs of any complexity and scale",
          },
        ],
      },
    ],
    TechnologiesExpertiesTitle: "Our Technology Expertise",
    TechnologiesExpertiesPara:
      "Senwell Group Private Limited has the expertise to assist you in developing powerful back-end solutions. Hire backend developers that are skilled in a variety of cutting-edge technologies, programming languages, and tools, whether you require a basic online API or a huge custom-made CRM system.",
    TechnologiesExperties: [
      {
        icon: (
          <img
            src={Java}
            className="icon"
            style={{ i: "#4eb7ff" }}
            alt="java"
            title="java"
          />
        ),
        title: "Java",
      },
      {
        icon: <FaPhp className="icon" />,
        title: "PHP",
      },
      {
        icon: (
          <img
            src={Dotnet}
            className="icon"
            style={{ i: "#4eb7ff" }}
            alt="Dotnet"
            title= "Dotnet"
          />
        ),
        title: ".Net",
      },
      {
        icon: (
          <FaNodeJs className="icon" style={{ i: "#4eb7ff", color: "green" }} />
        ),
        title: "NodeJs",
      },
      {
        icon: <img src={Python} className="icon" alt="Python" title="Python"/>,
        title: "Phython",
      },
    ],
    DevelopmentStep: [
      {
        DevelopmentSteptitle: "How to Get Started",
        Developmentstep: [
          {
            icon: "",
            title: "Reach out to us",
            description:
              "Fill our contact form, tell us about your project, and we’ll be in touch within one business day.",
          },
          {
            icon: "",
            title: "Outline your goals",
            description:
              "Your personal manager will provide a quote based on your project requirements.",
          },
          {
            icon: "",
            title: "Meet your team",
            description:
              "We will handpick the best suitable candidates for your Node.js development project.",
          },

          {
            icon: "",
            title: "Get to work",
            description:
              "Now that your team is formed, let’s turn your ideas into a successful Node.js solution!",
          },
        ],
      },
    ],
    GetInTouch: [
      {
        GetInTouchTitle:
          "Do you need assistance creating a strong engine to power your software solution? Hire Sewell Solutions backend developer!",
        Button: "Get In Touch",
      },
    ],

    Benefits: [
      {
        title: "Benefits of Back-End  ",
        Benefitstitle: "Development with Senwell",
        BenefitsofDevelopmentWithSenwell: [
          {
            Number: "01",
            title: "New Employee Trial Periods",
            description:
              "We allow our clients to try out candidates before making a final decision, so you can trust that the task will be completed on time and on budget.",
          },
          {
            Number: "02",
            title: "Monitoring of Transparent Code",
            description:
              "When hiring from Senwell Group Private Limited, you get access to our proprietary tools that provide useful code quality metrics to help your project standardize the quality of code and maintain it on a high level.",
          },
          {
            Number: "03",
            title: "Includes system administration",
            description:
              "Don't worry about anything but the success of your project; we'll handle all administrative and IT assistance, including helping you set up servers, integrate project management software, and create effective communication, among other things.",
          },
          {
            Number: "04",
            title: "Process & Workflow Flexibility",
            description:
              "Our highly skilled personnel can not only rapidly and effortlessly integrate with your team, but also assist improve development procedures for optimal project productivity.",
          },
          {
            Number: "05",
            title: "Rental Equipment Is Included",
            description:
              "You will not be charged extra for our professionals' workstations or equipment. You also have access to over 250 real devices to test your software, ranging from smartphones to tablets.",
          },
          {
            Number: "06",
            title: "Resource Replacement in a Hurry",
            description:
              "When it comes to selecting the finest individuals for our client’s needs, we are extremely meticulous. If a discrepancy does occur, we will promptly replace it with a suitable replacement.",
          },
        ],
      },
    ],
    ProjectPortfollio: [
      {
        title: "LottoSocial",
        image1: Lotto,
        technologies: [" Angular", "Ionic", "Node.js", "Firebase"],
        para: "Web Application to create and manage online lottery syndicates. Increase your chances of winning big and join a lottery syndicate. We made it simple to create and manage lottery syndicates online, offering a combination of ticket types.",
      },
      {
        title: "Fork-Freight",
        image1: Fork,
        technologies: [" Angular", "Asp.Net"],
        para: "Trucking/Transportation Web application for transportation Industry.Fork Freight is an app which provides high-end, affordable services that will help carriers, shippers, brokers, and dispatchers grow their business.",
      },
      {
        title: "Lumiere32- B2B Supplier Marketplace",
        image1: Lumiere,
        technologies: [" Angular", ", NestJs", "MySQL"],
        para: " We developed an effective web application that helps developers to write semantically correct code and form useful coding habits with better practices, superior tools, and proven methodologies.The application provides thorough data analysis and real-time statistics as well as features such as sorting errors by priority. The app is compatible with all popular desktop and mobile devices.",
      },
      {
        title: "Fooz",
        image1: Fooz,
        technologies: [" React", "Redux", "Node.js", "Bootstrap"],
        para: "The UAE-born, play-to-win online platform introduced by Arab Millionaire is offering participants the chance to “Dream Big, Give More” with the largest prizes in the Arab World",
      },
      {
        title: "VirusGeeks",
        image1: Virus,
        technologies: [" Angular", "NextJs", "NestJs"],
        para: "Healthcare Platform VirusGeeks, a BioHealth Technology platform, which provide health tech solutions such as end-to-end Covid-19 testing.",
      },
      {
        title: "Parsl",
        image1: parsl,
        technologies: [" Angular", "Node.js"],
        para: "Parsl is a platform that combines blockchain, NFC and IoT to create a track and trace technology that not only automates compliance efficiently and accurately but offers a range of tangible business benefits for every stage of the cannabis supply chain. ",
      },
      {
        title: "Cronage",
        image1: Cronage,
        technologies: [" Angular", "Bootstrap", "HTML", "CSS"],
        para: "  New businesses must strike while the iron is hot. Chasing after a fast time-to-market can make your business vulnerable to performance risks. We helped Distractify perform a fast but extremely thorough Quality Assurance process before they hit “publish” on their first viral story.",
      },
    ],
    OurClients: [
      {
        Image: cronage,
      },

      {
        Image: GreekVirous,
      },

      {
        Image: Lottosocialogo,
      },
      {
        Image: fooz,
      },
      {
        Image: Lumieres,
      },
      {
        Image: parslImg,
      },
      {
        Image: fork,
      },
    ],
    Testimonials: [
      {
        label1:
          "“They are incredibly client-focused, so we saw them as partners rather than service suppliers. Applying industry cutting edge- practices and knowledge of travel, real estate, technology, and software business domain they have managed to deliver the project on time and of the highest quality.”",
        img: "",
        name: "Derick, Hatchster Ltd. UK",
        post: "",
      },
      {
        label2:
          "“I’ve been working with Senwell Group Private Limited for several outsourcing projects for clients in Canada and US, and I  have to say that the guys here deliver really great work and oftentimes outperform the clients’ own  development teams. I’ve never met such productivity before and that’s probably one of the biggest,  although not the only one advantage of the company..”",
        img: "",
        name: "Kevin Atom, Property Hatch Ltd, United State",
        post: "",
      },
      {
        label3:
          "“We are enormously satisfied with Senwell Group Private Limited as contractor. Highly responsive team with  profound understanding of subject matter and really very, very fast code delivery that is particularly  important for our travel business. . ”",
        img: "",
        name: "Nicolo F, Italy",
        post: "",
      },
    ],
    GetInTouchSecondbanner: [
      {
        GetInTouchTitle: "We’ve got your back",
        Button: "Get in touch",
        image: backendImage,
        para: "Hire our experienced backend developers and we’ll make sure your applications, databases, and servers work seamlessly with each other.",
      },
    ],
    WhyChooseUs: [
      {
        WhyChooseUsTitle: "Why Senwell",
        WhyChooseUs: [
          {
            icon: (
              <FlashOnIcon
                style={{
                  color: "#3D8ED7",
                  paddingBottom: "1rem",
                  fontSize: "2.5rem",
                }}
              />
            ),
            title: "Start With Finish",
            para: "We want you to find a team quickly and get straight to turning your vision into a reality.",
          },
          {
            icon: (
              <SearchIcon
                style={{
                  color: "#3D8ED7",
                  paddingBottom: "1rem",
                  fontSize: "2.5rem",
                }}
              />
            ),
            title: "100% Transparency",
            para: "Clear and consistent communication, flexible pricing, and full dedication to your success",
          },
          {
            icon: (
              <StarIcon
                style={{
                  color: "#3D8ED7",
                  paddingBottom: "1rem",
                  fontSize: "2.5rem",
                }}
              />
            ),
            title: "Excellent Code Quality",
            para: "Beyond QA, we ensure your code is clean and free of technical debt. ",
          },
          {
            icon: (
              <LocationOnIcon
                style={{
                  color: "#3D8ED7",
                  paddingBottom: "1rem",
                  fontSize: "2.5rem",
                }}
              />
            ),
            title: "Cross-domain expertise",
            para: "There’s no challenge we can’t handle with confidence and great aplomb.",
          },
        ],
      },
    ],
    Forms: [
      {
        formTitle: "Get a quote today!",
        formSubtitle: "Enter your project details.",
      },
    ],
  },
  {
    id: 10,
    name: "mobile",
    HeroBanner: [
      {
        name: "mobile",
        Page: "Mobile",
        title: "Hire Mobile App Development",
        button: "Hire Now",
        img: Mobile,
        image1: CmmiImage,
        image2: Union,
        image3: LogoImage,
        para: "Android, iOS, or cross-platform, we can help you build and grow a flourishing mobile application with flawless performance and great user experience.",
      },
    ],

    skill: [
      {
        PerformanceTitle: "Passionate People. Powerful Solutions.",
        PerformancePara:
          "Our team of app developers has the technical competence needed to complete large-scale, multi-disciplinary mobile app development projects. The Hidden Brains team has a thorough grasp of mobile technology, combining unrivalled experience with global procedures and delivery strategies.",
        PerformanceList: [
          {
            Numbers: "",
            PerformanceListTitle: "Extensive experience",
            PerformanceListPara:
              "Years of reliable mobile application development service with a proven track record of success stories.",
          },
          {
            Numbers: "",
            PerformanceList: "Accomplished professionals",
            PerformanceListPara:
              "Qualified mobile application engineers with strong programming skills and deep expertise in various domains.",
          },
          {
            Numbers: "",
            PerformanceList: "Trusted partnerships",
            PerformanceListPara:
              "Hundreds of companies around the world entrusted us with building and maintaining their apps.",
          },
        ],
      },
    ],
    TechnologiesExpertiesTitle: "Our Technology Expertise",
    TechnologiesExpertiesPara:
      "Our mobile app developers are skilled in React Native, Swift, Kotlin, and a variety of other powerful programming languages, frameworks, and technologies. Our designers and developers will take care of you whether you need a native or cross-platform app, a basic solution, or a big enterprise suite.",
    TechnologiesExperties: [
      {
        icon: <FaAndroid className="icon" style={{ color: "green" }} />,
        title: "Android",
      },
      {
        icon: (
          <DiSwift
            className="icon"
            style={{ backgroundColor: "red", color: "white" }}
          />
        ),
        title: "Swift",
      },
      {
        icon: <DiIonic className="icon" style={{ color: "blue" }} />,
        title: "Ionic",
      },
      {
        icon: <img src={Kotlin1} className="icon" alt="kotline" title="kotline"/>,
        title: "Kotlin",
      },
      {
        icon: (
          <img
            src={Java}
            className="icon"
            style={{ i: "#4eb7ff" }}
            alt="Java"
            title="Java"
          />
        ),
        title: "Java",
      },
    ],
    DevelopmentStep: [
      {
        DevelopmentSteptitle: "How to Get Started",
        Developmentstep: [
          {
            icon: "",
            title: "Reach out to us",
            description:
              "Fill our contact form, tell us about your project, and we’ll be in touch within one business day.",
          },
          {
            icon: "",
            title: "Outline your goals",
            description:
              "Your personal manager will provide a quote based on your project requirements.",
          },
          {
            icon: "",
            title: "Meet your team",
            description:
              "We will handpick the best suitable candidates for your Node.js development project.",
          },

          {
            icon: "",
            title: "Get to work",
            description:
              "Now that your team is formed, let’s turn your ideas into a successful Node.js solution!",
          },
        ],
      },
    ],
    GetInTouch: [
      {
        GetInTouchTitle:
          "Hire mobile app developers that have specialized programming abilities, industry knowledge, and stringent code quality requirements.",
        Button: "Get In Touch",
      },
    ],

    Benefits: [
      {
        title: "Benefits of Mobile App",
        Benefitstitle: " Development with Senwell",
        BenefitsofDevelopmentWithSenwell: [
          {
            Number: "01",
            title: "Free trial periods for new employees.",
            description:
              "We provide our clients with the opportunity to have a test run with the candidates before they finalize their choice—for you to be confident we’ll get the job done on time and within budget.",
          },
          {
            Number: "02",
            title: "Transparent monitoring of code quality.",
            description:
              "When you hire Senwell Group Private Limited, you have access to our unique tools, which give important code quality metrics to assist your project in standardizing and maintaining code quality.",
          },
          {
            Number: "03",
            title: "Equipment rental included",
            description:
              "You will not be charged extra for our professionals' workstations or equipment.You also receive access to over 250 real devices to test your app, ranging from smartphones to tablets.",
          },
          {
            Number: "04",
            title: "System management incorporated.",
            description:
              "Don't worry about anything but the success of your project; we'll handle all administrative and IT assistance, including helping you set up effective communication routes and integrating project management tools.",
          },
          {
            Number: "05",
            title: "Company processes when required",
            description:
              "Our highly skilled personnel can not only rapidly and effortlessly integrate with your team, but also assist improve development procedures for optimal project productivity.",
          },
          {
            Number: "06",
            title: "Unwanted resources are immediately replaced.",
            description:
              "When it comes to selecting the finest individuals for our client’s needs, we are extremely meticulous.  If a discrepancy does occur, we will promptly replace it with a suitable replacement.",
          },
        ],
      },
    ],
    ProjectPortfollio: [
      {
        title: "LottoSocial",
        image1: Lotto,
        technologies: [" Angular", "Ionic", "Node.js", "Firebase"],
        para: "Web Application to create and manage online lottery syndicates. Increase your chances of winning big and join a lottery syndicate. We made it simple to create and manage lottery syndicates online, offering a combination of ticket types.",
      },
      {
        title: "Fork-Freight",
        image1: Fork,
        technologies: [" Angular", "Asp.Net"],
        para: "Trucking/Transportation Web application for transportation Industry.Fork Freight is an app which provides high-end, affordable services that will help carriers, shippers, brokers, and dispatchers grow their business.",
      },
      {
        title: "Lumiere32- B2B Supplier Marketplace",
        image1: Lumiere,
        technologies: [" Angular", ", NestJs", "MySQL"],
        para: " We developed an effective web application that helps developers to write semantically correct code and form useful coding habits with better practices, superior tools, and proven methodologies.The application provides thorough data analysis and real-time statistics as well as features such as sorting errors by priority. The app is compatible with all popular desktop and mobile devices.",
      },
      {
        title: "Fooz",
        image1: Fooz,
        technologies: [" React", "Redux", "Node.js", "Bootstrap"],
        para: "The UAE-born, play-to-win online platform introduced by Arab Millionaire is offering participants the chance to “Dream Big, Give More” with the largest prizes in the Arab World",
      },
      {
        title: "VirusGeeks",
        image1: Virus,
        technologies: [" Angular", "NextJs", "NestJs"],
        para: "Healthcare Platform VirusGeeks, a BioHealth Technology platform, which provide health tech solutions such as end-to-end Covid-19 testing.",
      },
      {
        title: "Parsl",
        image1: parsl,
        technologies: [" Angular", "Node.js"],
        para: "Parsl is a platform that combines blockchain, NFC and IoT to create a track and trace technology that not only automates compliance efficiently and accurately but offers a range of tangible business benefits for every stage of the cannabis supply chain. ",
      },
      {
        title: "Cronage",
        image1: Cronage,
        technologies: [" Angular", "Bootstrap", "HTML", "CSS"],
        para: "  New businesses must strike while the iron is hot. Chasing after a fast time-to-market can make your business vulnerable to performance risks. We helped Distractify perform a fast but extremely thorough Quality Assurance process before they hit “publish” on their first viral story.",
      },
    ],
    OurClients: [
      {
        Image: cronage,
      },

      {
        Image: GreekVirous,
      },

      {
        Image: Lottosocialogo,
      },
      {
        Image: fooz,
      },
      {
        Image: Lumieres,
      },
      {
        Image: parslImg,
      },
      {
        Image: fork,
      },
    ],
    Testimonials: [
      {
        label1:
          "“They are incredibly client-focused, so we saw them as partners rather than service suppliers. Applying industry cutting edge- practices and knowledge of travel, real estate, technology, and software business domain they have managed to deliver the project on time and of the highest quality.”",
        img: "",
        name: "Derick, Hatchster Ltd. UK",
        post: "",
      },
      {
        label2:
          "“I’ve been working with Senwell Group Private Limited for several outsourcing projects for clients in Canada and US, and I  have to say that the guys here deliver really great work and oftentimes outperform the clients’ own  development teams. I’ve never met such productivity before and that’s probably one of the biggest,  although not the only one advantage of the company..”",
        img: "",
        name: "Kevin Atom, Property Hatch Ltd, United State",
        post: "",
      },
      {
        label3:
          "“We are enormously satisfied with Senwell Group Private Limited as contractor. Highly responsive team with  profound understanding of subject matter and really very, very fast code delivery that is particularly  important for our travel business. . ”",
        img: "",
        name: "Nicolo F, Italy",
        post: "",
      },
    ],
    GetInTouchSecondbanner: [
      {
        GetInTouchTitle: "Stand out from the crowd",
        Button: "Get a quote today",
        image: MobileImg,
        para: "Ensure visibility and positive reception of your mobile solution by hiring mobile application developers from Senwell Group Private Limited — a trusted tech partner of multiple Fortune 500 companies.",
      },
    ],
    WhyChooseUs: [
      {
        WhyChooseUsTitle: "Why Senwell",
        WhyChooseUs: [
          {
            icon: (
              <FlashOnIcon
                style={{
                  color: "#3D8ED7",
                  paddingBottom: "1rem",
                  fontSize: "2.5rem",
                }}
              />
            ),
            title: "Start with confidence.",
            para: "We want you to find a team quickly and get straight to turning your vision into a reality.",
          },
          {
            icon: (
              <SearchIcon
                style={{
                  color: "#3D8ED7",
                  paddingBottom: "1rem",
                  fontSize: "2.5rem",
                }}
              />
            ),
            title: "100% Transparency",
            para: "Clear and consistent communication, flexible pricing, and full dedication to your success",
          },
          {
            icon: (
              <StarIcon
                style={{
                  color: "#3D8ED7",
                  paddingBottom: "1rem",
                  fontSize: "2.5rem",
                }}
              />
            ),
            title: "Excellent Code Quality",
            para: "Beyond QA, we ensure your code is clean and free of technical debt. ",
          },
          {
            icon: (
              <LocationOnIcon
                style={{
                  color: "#3D8ED7",
                  paddingBottom: "1rem",
                  fontSize: "2.5rem",
                }}
              />
            ),
            title: "Cross-domain expertise",
            para: "There’s no challenge we can’t handle with confidence and great aplomb.",
          },
        ],
      },
    ],
    Forms: [
      {
        formTitle: "Write to us.",
        formSubtitle: "Tell us about your project.",
      },
    ],
  },

  {
    id: 11,
    name: "cms-crm-erp",
    HeroBanner: [
      {
        name: "cms-crm-erp",
        Page: "Mobile",
        title: "  CMS, CRM, ERP Development",
        button: "Get a Quote",
        img: Mobile,
        image1: CmmiImage,
        image2: Union,
        image3: LogoImage,
        para: "Give your employees the tools they need to successfully manage content, clients, and enterprise resources. We will tailor any CMS, CRM, or ERP system to suit the unique needs of your business.",
      },
    ],

    skill: [
      {
        PerformanceTitle:
          "You may depend on these tools. We can vouch for the quality.",
        PerformancePara:
          "While our own organization (including this website) works on software and systems we've customized to our own requirements, we can vouch for the quality of our content and business management tools. Our quest does not finish there. Huff Post’s platform was customized by us. Our CRM developers created specialized software for companies in the e-commerce, FinTech, and hospitality industries. We designed ERP solutions to assist start-ups in becoming global leaders. Join the race by hiring professional CRM/ERP developers. Give your company the resources it requires to succeed!",
        PerformanceList: [
          {
            Numbers: "",
            PerformanceListTitle: "Years of Experience",
            PerformanceListPara:
              "We've spent years honing our approach to software development and customer satisfaction.",
          },
          {
            Numbers: "",
            PerformanceListTitle: "Skilled Engineers",
            PerformanceListPara:
              "No one individual can accomplish it all. However, we have a vast pool of engineers with specialized talents and domain knowledge.",
          },
          {
            Numbers: "",
            PerformanceListTitle: "Global Clients",
            PerformanceListPara:
              "We've produced websites, applications, and software suites for clients all across the world throughout the years.",
          },
        ],
      },
    ],
    TechnologiesExpertiesTitle: "Global Clients",
    TechnologiesExpertiesPara:
      "Our mobile app developers are skilled in React Native, Swift, Kotlin, and a variety of other powerful programming languages, frameworks, and technologies. Our designers and developers will take care of you whether you need a native or cross-platform app, a basic solution, or a big enterprise suite.",
    TechnologiesExperties: [
      {
        icon: (
          <IoLogoJavascript
            className="icon"
            style={{ backgroundColor: "yellow", color: "black" }}
          />
        ),
        title: "JavaScript",
      },
      // {
      //   icon: <FaAngular className="icon" style={{color:"red"}} />,
      //   title: "Angular",
      // },
      {
        icon: <FaReact className="icon" />,
        title: "React",
      },
      {
        icon: (
          <SiTypescript
            className="icon"
            style={{ backgroundColor: "black", color: "yellow" }}
          />
        ),
        title: "TypeScript",
      },
      {
        icon: (
          <img
            src={Vue}
            className="icon"
            style={{ color: "green" }}
            alt="Vue"
            title="Vue"
          />
        ),
        title: "Vue.Js",
      },
    ],
    DevelopmentStep: [
      {
        DevelopmentSteptitle: "Let’s empower your business",
        Developmentstep: [
          {
            icon: "",
            title: "Get in touch",
            description:
              "Describe the tools your business needs. What needs fixing? What needs to be built from scratch?",
          },
          {
            icon: "",
            title: "Define objectives",
            description:
              "We’ll come up with a quote and start assembling a team based on your technical requirements.",
          },
          {
            icon: "",
            title: "Meet your team",
            description:
              "We will handpick the best suitable candidates for your Node.js development project.",
          },

          {
            icon: "",
            title: "Get to work",
            description:
              "Now that your team is formed, let’s turn your ideas into a successful Node.js solution!",
          },
        ],
      },
    ],
    GetInTouch: [
      {
        GetInTouchTitle:
          "Hire mobile app developers that have specialized programming abilities, industry knowledge, and stringent code quality requirements.   ",
        Button: "Get In Touch",
      },
    ],

    Benefits: [
      {
        title: "We go above and beyond",
        BenefitsofDevelopmentWithSenwell: [
          {
            Number: "01",
            title: "Business Analysis ",
            description:
              "We provide more than just web development. Our analysts can examine your company and assist you in selecting the tools and software that will make your operations work more smoothly.",
          },
          {
            Number: "02",
            title: "Mentorship programs for employees",
            description:
              "Our company's most experienced Senior CRM, CMS, and ERP developers are available to jump on board your project and give assistance at no additional cost, regardless of who is on your development team.",
          },
          {
            Number: "03",
            title: "Quality control with transparency",
            description:
              "Our usage of proprietary code-quality tracking tools benefits everyone who works with Senwell Group Private Limited. You will be able to obtain a report on your project's code at every step of development.",
          },
          {
            Number: "04",
            title: "Workflows and procedures that are adaptable",
            description:
              "Our highly skilled professionals will seamlessly integrate with your team and help to the improvement of development procedures for optimal project efficiency.",
          },
          {
            Number: "05",
            title: "Rapid replenishment of resources",
            description:
              "We stand behind every developer and QA engineer, however, if you need to replace someone on your team, we will be able to assist you immediately and at no additional cost.",
          },
          {
            Number: "06",
            title: "Knowledge transmission that is seamless",
            description:
              "Every procedure, test, and workflow is well recorded. Every ERP, CMS, or CRM we provide comes with detailed instructions on how to utilize it to reach your business goals.",
          },
        ],
      },
    ],
    ProjectPortfollio: [
      {
        title: "Parsl",
        image1: parsl,
        technologies: [" Angular", "Node.js"],
        para: "Parsl is a platform that combines blockchain, NFC and IoT to create a track and trace technology that not only automates compliance efficiently and accurately but offers a range of tangible business benefits for every stage of the cannabis supply chain. ",
      },
      {
        title: "LottoSocial",
        image1: Lotto,
        technologies: [" Angular", "Ionic", "Node.js", "Firebase"],
        para: "Web Application to create and manage online lottery syndicates. Increase your chances of winning big and join a lottery syndicate. We made it simple to create and manage lottery syndicates online, offering a combination of ticket types.",
      },
      {
        title: "Fork-Freight",
        image1: Fork,
        technologies: [" Angular", "Asp.Net"],
        para: "Trucking/Transportation Web application for transportation Industry.Fork Freight is an app which provides high-end, affordable services that will help carriers, shippers, brokers, and dispatchers grow their business.",
      },
      {
        title: "Lumiere32- B2B Supplier Marketplace",
        image1: Lumiere,
        technologies: [" Angular", ", NestJs", "MySQL"],
        para: " We developed an effective web application that helps developers to write semantically correct code and form useful coding habits with better practices, superior tools, and proven methodologies.The application provides thorough data analysis and real-time statistics as well as features such as sorting errors by priority. The app is compatible with all popular desktop and mobile devices.",
      },
      {
        title: "Fooz",
        image1: Fooz,
        technologies: [" React", "Redux", "Node.js", "Bootstrap"],
        para: "The UAE-born, play-to-win online platform introduced by Arab Millionaire is offering participants the chance to “Dream Big, Give More” with the largest prizes in the Arab World",
      },
      {
        title: "VirusGeeks",
        image1: Virus,
        technologies: [" Angular", "NextJs", "NestJs"],
        para: "Healthcare Platform VirusGeeks, a BioHealth Technology platform, which provide health tech solutions such as end-to-end Covid-19 testing.",
      },

      {
        title: "Cronage",
        image1: Cronage,
        technologies: [" Angular", "Bootstrap", "HTML", "CSS"],
        para: "  New businesses must strike while the iron is hot. Chasing after a fast time-to-market can make your business vulnerable to performance risks. We helped Distractify perform a fast but extremely thorough Quality Assurance process before they hit “publish” on their first viral story.",
      },
    ],
    OurClients: [
      {
        Image: cronage,
      },

      {
        Image: GreekVirous,
      },

      {
        Image: Lottosocialogo,
      },
      {
        Image: fooz,
      },
      {
        Image: Lumieres,
      },
      {
        Image: parslImg,
      },
      {
        Image: fork,
      },
    ],
    Testimonials: [
      {
        label1:
          "“They are incredibly client-focused, so we saw them as partners rather than service suppliers. Applying industry cutting edge- practices and knowledge of travel, real estate, technology, and software business domain they have managed to deliver the project on time and of the highest quality.”",
        img: "",
        name: "Derick, Hatchster Ltd. UK",
        post: "",
      },
      {
        label2:
          "“I’ve been working with Senwell Group Private Limited for several outsourcing projects for clients in Canada and US, and I  have to say that the guys here deliver really great work and oftentimes outperform the clients’ own  development teams. I’ve never met such productivity before and that’s probably one of the biggest,  although not the only one advantage of the company..”",
        img: "",
        name: "Kevin Atom, Property Hatch Ltd, United State",
        post: "",
      },
      {
        label3:
          "“We are enormously satisfied with Senwell Group Private Limited as contractor. Highly responsive team with  profound understanding of subject matter and really very, very fast code delivery that is particularly  important for our travel business. . ”",
        img: "",
        name: "Nicolo F, Italy",
        post: "",
      },
    ],
    GetInTouchSecondbanner: [
      {
        GetInTouchTitle:
          "Boost your revenue with efficient business management",
        Button: "Let's Talk",
        image: ReactGetinTouchBanner,
        para: "The secret to increasing your bottom line is spending more time on creating value and less time on daily minutiae. Hire our CRM, CMS, and ERP developers and build the tools you need to make that happen.",
      },
    ],
    WhyChooseUs: [
      {
        WhyChooseUsTitle: "Why Senwell",
        WhyChooseUs: [
          {
            icon: (
              <FlashOnIcon
                style={{
                  color: "#3D8ED7",
                  paddingBottom: "1rem",
                  fontSize: "2.5rem",
                }}
              />
            ),
            title: "Start with confidence.",
            para: "We want you to find a team quickly and get straight to turning your vision into a reality.",
          },
          {
            icon: (
              <SearchIcon
                style={{
                  color: "#3D8ED7",
                  paddingBottom: "1rem",
                  fontSize: "2.5rem",
                }}
              />
            ),
            title: "100% Transparency",
            para: "Clear and consistent communication, flexible pricing, and full dedication to your success",
          },
          {
            icon: (
              <StarIcon
                style={{
                  color: "#3D8ED7",
                  paddingBottom: "1rem",
                  fontSize: "2.5rem",
                }}
              />
            ),
            title: "Excellent Code Quality",
            para: "Beyond QA, we ensure your code is clean and free of technical debt. ",
          },
          {
            icon: (
              <LocationOnIcon
                style={{
                  color: "#3D8ED7",
                  paddingBottom: "1rem",
                  fontSize: "2.5rem",
                }}
              />
            ),
            title: "Cross-domain expertise",
            para: "There’s no challenge we can’t handle with confidence and great aplomb.",
          },
        ],
      },
    ],
    Forms: [
      {
        formTitle: "Write to us.",
        formSubtitle: "Tell us about your project.",
      },
    ],
  },

  {
    id: 12,
    name: "Java",
    Technologies: "Back-end",
    HeroBanner: [
      {
        Page: " Java Development Services",
        name: "Java",
        title: "Hire Java Development",
        breadcrums: "Back-end   ",
        Link: "/technologies",
        button: "Hire Now",
        img: netBanner,
        image1: CmmiImage,
        image2: Union,
        image3: LogoImage,
        para: "Senwell Group Private Limited offers top-notch Java development services. We create scalable and reliable Java software to solve business problems and offer top-notch DevOps services that maximize benefits for a range of industry clients.",
      },
    ],

    skill: [
      {
        PerformanceTitle:
          "We focus our knowledge on the problems facing your company.",
        PerformancePara:
          "Senwell Group Private Limited offers top-notch Java development services. We create scalable and reliable Java software to solve business problems and offer top-notch DevOps services that maximize benefits for a range of industry clients.",
        PerformanceList: [
          {
            Numbers: "",
            PerformanceListTitle: "Engineers",
            PerformanceListPara:
              "Some of the most talented and experienced Java developers in Eastern Europe work for our company.",
          },
          {
            Numbers: "",
            PerformanceListTitle: " Projects",
            PerformanceListPara:
              "Our portfolio is full of projects that deserve your consideration thanks to Java development outsourcing.",
          },
          {
            Numbers: "",
            PerformanceListTitle: "Years of Experience",
            PerformanceListPara:
              "Despite our extensive experience, we still have a strong enthusiasm for cutting-edge tools like the Spring Boot framework.",
          },
        ],
      },
    ],
    TechnologiesExpertiesTitle: "Tech Stack ",
    TechnologiesExpertiesPara:
      "We are experts in many fields and state-of-the-art technologies, and we can demonstrate our knowledge of Java development. Our goal with every project is to provide significant value to our clients as well as the industry at large. This demonstrates our steadfast dedication to creating code that is as clean as possible and attaining peak performance.",

    TechnologiesExperties: [
      {
        icon: (
          <img
            src={javaNew}
            className="icon"
            style={{ i: "#4eb7ff" }}
            alt="JavaNew"
            title="Java"
          />
        ),
        title: "Java",
      },
      {
        icon: (
          <img
            src={springBoot}
            className="icon"
            style={{ i: "#4eb7ff" }}
            alt="Springboot"
            title="SpringBoot"
          />
        ),
        title: "SpringBoot",
      },
      {
        icon: (
          <img
            src={Hibernate}
            className="icon"
            style={{ i: "#4eb7ff" }}
            alt="Hibernate"
            title="Hibernate"
          />
        ),
        title: "Hibernate",
      },
    ],
    DevelopmentStep: [
      {
        DevelopmentSteptitle: "How to Get Started",
        Developmentstep: [
          {
            icon: "",
            title: "Get to know us",
            description:
              "Complete this brief form to let us know about your needs for software development.",
          },
          {
            icon: "",
            title: "Set an objective",
            description:
              "Design and implement a scalable Java app meeting specified requirements, ensuring optimal performance and lifecycle maintainability.",
          },
          {
            icon: "",
            title: "Form a team",
            description:
              "We will assemble a dedicated team of skilled Java developers, committed to working full-time on your project",
          },
          {
            icon: "",
            title: "Start working",
            description:
              "Once you've met and endorsed the team, the development process is poised for a fresh start!",
          },
        ],
      },
    ],
    GetInTouch: [
      {
        GetInTouchTitle:
          "What you describe is what we make! If you are a Java Engineer, we can turn your ideas into a functional software solution.",
        Button: "Get In Touch",
      },
    ],

    Benefits: [
      {
        title: "Benefits of Java",
        Benefitstitle: "Development with Senwell",
        BenefitsofDevelopmentWithSenwell: [
          {
            Number: "01",
            title: "Reduced Time-To-Market",
            description:
              "Boost the distribution and use of resources to expedite development cycles and bring your product or service to market more quickly.",
          },
          {
            Number: "02",
            title: "Easy App Designing, Implementation & Deployment",
            description:
              "Provide an aesthetically pleasing and intuitive user interface that makes interacting with it easier and improves the user experience in general.",
          },
          {
            Number: "03",
            title: "Easy Integration with Distributed System Operation",
            description:
              "Implement a microservices architecture to break down the application into smaller, independent services, enabling seamless integration with distributed systems.",
          },
          {
            Number: "04",
            title: "Cross Platform Operation with Platform Independence",
            description:
              "Embrace the Java philosophy of WORA to ensure your application can run seamlessly across diverse platforms without modification.",
          },
          {
            Number: "05",
            title: "Multithreading Benefits",
            description:
              "Enhance user experience by separating time-consuming tasks from the main thread, ensuring the application remains responsive and interactive.",
          },
          {
            Number: "06",
            title: "Inexpensive App Development ",
            description:
              "Utilize cost-effective cloud services for hosting, storage, and computing, allowing you to pay for only the resources you use and eliminating the need for significant upfront investments.",
          },
        ],
      },
    ],
    ProjectPortfollio: [
      {
        title: "Lumiere32- B2B Supplier Marketplace",
        image1: Lumiere,
        technologies: [" Angular", ", NestJs", "MySQL"],
        para: " We developed an effective web application that helps developers to write semantically correct code and form useful coding habits with better practices, superior tools, and proven methodologies.The application provides thorough data analysis and real-time statistics as well as features such as sorting errors by priority. The app is compatible with all popular desktop and mobile devices.",
      },
      {
        title: "Fork-Freight",
        image1: Fork,
        technologies: [" Angular", "Asp.Net"],
        para: "Trucking/Transportation Web application for transportation Industry.Fork Freight is an app which provides high-end, affordable services that will help carriers, shippers, brokers, and dispatchers grow their business.",
      },
      {
        title: "LottoSocial",
        image1: Lotto,
        technologies: [" Angular", "Ionic", "Node.js", "Firebase"],
        para: "Web Application to create and manage online lottery syndicates. Increase your chances of winning big and join a lottery syndicate. We made it simple to create and manage lottery syndicates online, offering a combination of ticket types.",
      },
      {
        title: "Fork-Freight",
        image1: Fork,
        technologies: [" Angular", "Asp.Net"],
        para: "Trucking/Transportation Web application for transportation Industry.Fork Freight is an app which provides high-end, affordable services that will help carriers, shippers, brokers, and dispatchers grow their business.",
      },

      {
        title: "Fooz",
        image1: Fooz,
        technologies: [" React", "Redux", "Node.js", "Bootstrap"],
        para: "The UAE-born, play-to-win online platform introduced by Arab Millionaire is offering participants the chance to “Dream Big, Give More” with the largest prizes in the Arab World",
      },
      {
        title: "VirusGeeks",
        image1: Virus,
        technologies: [" Angular", "NextJs", "NestJs"],
        para: "Healthcare Platform VirusGeeks, a BioHealth Technology platform, which provide health tech solutions such as end-to-end Covid-19 testing.",
      },
      {
        title: "Parsl",
        image1: parsl,
        technologies: [" Angular", "Node.js"],
        para: "Parsl is a platform that combines blockchain, NFC and IoT to create a track and trace technology that not only automates compliance efficiently and accurately but offers a range of tangible business benefits for every stage of the cannabis supply chain. ",
      },
      {
        title: "Cronage",
        image1: Cronage,
        technologies: [" Angular", "Bootstrap", "HTML", "CSS"],
        para: "  New businesses must strike while the iron is hot. Chasing after a fast time-to-market can make your business vulnerable to performance risks. We helped Distractify perform a fast but extremely thorough Quality Assurance process before they hit “publish” on their first viral story.",
      },
    ],
    OurClients: [
      {
        Image: cronage,
      },

      {
        Image: GreekVirous,
      },

      {
        Image: Lottosocialogo,
      },
      {
        Image: fooz,
      },
      {
        Image: Lumieres,
      },
      {
        Image: parslImg,
      },
      {
        Image: fork,
      },
    ],
    Testimonials: [
      {
        label1:
          "“They are incredibly client-focused, so we saw them as partners rather than service suppliers. Applying industry cutting edge- practices and knowledge of travel, real estate, technology, and software business domain they have managed to deliver the project on time and of the highest quality.”",
        img: "",
        name: "Derick, Hatchster Ltd. UK",
        post: "",
      },
      {
        label2:
          "“I’ve been working with Senwell Group Private Limited for several outsourcing projects for clients in Canada and US, and I  have to say that the guys here deliver really great work and oftentimes outperform the clients’ own  development teams. I’ve never met such productivity before and that’s probably one of the biggest,  although not the only one advantage of the company..”",
        img: "",
        name: "Kevin Atom, Property Hatch Ltd, United State",
        post: "",
      },
      {
        label3:
          "“We are enormously satisfied with Senwell Group Private Limited as contractor. Highly responsive team with  profound understanding of subject matter and really very, very fast code delivery that is particularly  important for our travel business. . ”",
        img: "",
        name: "Nicolo F, Italy",
        post: "",
      },
    ],
    GetInTouchSecondbanner: [
      {
        GetInTouchTitle:
          "With the Java Framework, More Features, Same Smooth Performance",
        Button: "Estimate my Project",
        image: javaBanner,
        para: "Our Java developers don't adhere to a single strategy mindlessly. They consider every option available and select the one that best fits your future software solution. To receive a project estimate, click the button below.",
      },
    ],
    WhyChooseUs: [
      {
        WhyChooseUsTitle: "Why Choose Us",
        WhyChooseUs: [
          {
            icons: "",
            title: "Quick start",
            para: "It typically only takes a few days to begin the collaboration because we don't use bureaucracy in our processes.",
          },
          {
            icons: "",
            title: "Fair deal",
            para: "You are the only one who can determine how involved in the development you want to be, and all of our operations are completely transparent.",
          },
          {
            icons: "",
            title: "Flawless code",
            para: "We are passionate about software testing just as much as we are about coding, so you should have high standards for our QA services.",
          },
          {
            icons: "",
            title: "Industry experts",
            para: "Don't worry if your project becomes more technically complex over time—your Senwell Group Private Limited team will adapt accordingly.",
          },
        ],
      },
    ],

    Forms: [
      {
        formTitle: "Get a quote today!",
        formSubtitle: "Enter your project details.",
      },
    ],
  },

  {
    id: 13,
    name: "Nest",
    Technologies: "Back-end",
    HeroBanner: [
      {
        Page: " Nest Development Services",
        name: "Nest",
        title: "Hire Nest Development",
        breadcrums: "Back-end   ",
        Link: "/technologies",
        button: "Hire Now",
        img: netBanner,
        image1: CmmiImage,
        image2: Union,
        image3: LogoImage,
        para: "Senwell Group Private Limited offers top-notch Nest js development services. We create scalable and reliable Nest js software to solve business problems and offer top-notch DevOps services that maximize benefits for a range of industry clients.",
      },
    ],

    skill: [
      {
        PerformanceTitle:
          "Exceptional Performance. Outstanding User Experience.",
        PerformancePara:
          "Senwell Group Private Limited offers state-of-the-art Nest js development services, creating scalable and trustworthy Nest js applications to solve business problems. We also provide outstanding DevOps services to maximize benefits for customers in a variety of sectors.",
        PerformanceList: [
          {
            Numbers: "",
            PerformanceListTitle: "Engineers",
            PerformanceListPara:
              "We promise that you will only work with middle and senior level engineers whether you hire one or eight Nest JS developers.",
          },
          {
            Numbers: "",
            PerformanceListTitle: " Nest js Projects",
            PerformanceListPara:
              "Our outstanding Nest.js development outsourcing has allowed us to produce a plethora of notable projects that deserve your attention and are included in our portfolio.",
          },
          {
            Numbers: "",
            PerformanceListTitle: "Years of Experience",
            PerformanceListPara:
              "Despite our extensive experience, we remain devoted to cutting-edge tools, as evidenced by our enthusiastic support for the Nest.js framework.",
          },
        ],
      },
    ],
    TechnologiesExpertiesTitle: "Tech Stack ",
    TechnologiesExpertiesPara:
      "Skilled in a variety of domains and state-of-the-art technologies, we demonstrate our proficiency in Nest.js development. Delivering significant value to our clients and the industry at large is our main goal with every assignment. This demonstrates our steadfast dedication to generating the cleanest possible code and reaching optimal performance.",

    TechnologiesExperties: [
      {
        icon: (
          <img
            src={Nestjs}
            className="icon"
            style={{ i: "#4eb7ff" }}
            alt="NestjsNew"
            title="Nest Js"
          />
        ),
        title: "Nest js",
      },
      {
        icon: (
          <IoLogoJavascript
            className="icon"
            style={{ i: "#4eb7ff", backgroundColor: "yellow", color: "black" }}
          />
        ),
        title: "JavaScript",
      },
      {
        icon: (
          <SiTypescript
            className="icon"
            style={{ i: "#4eb7ff", backgroundColor: "black", color: "yellow" }}
          />
        ),
        title: "TypeScript",
      },
    ],
    DevelopmentStep: [
      {
        DevelopmentSteptitle: "How to Get Started",
        Developmentstep: [
          {
            icon: "",
            title: "Get to know us",
            description:
              "Give us the details of your backend development needs, and we'll take care of the rest.",
          },
          {
            icon: "",
            title: "Set an objective",
            description:
              "Create and implement a scalable Nest.js application that satisfies specifications, guaranteeing peak performance and maintainability over its long life.",
          },
          {
            icon: "",
            title: "Form a team",
            description:
              "We will put together a devoted group of knowledgeable Nest JS developers who are willing to work on your project full-time.",
          },
          {
            icon: "",
            title: "Start working",
            description:
              "The development process is ready for a new beginning once you've had a chance to meet and approve the team!",
          },
        ],
      },
    ],
    GetInTouch: [
      {
        GetInTouchTitle:
          "We bring your ideas to life! As a Nest js Engineer, entrust us to turn your concepts into functional software solutions.",
        Button: "Get In Touch",
      },
    ],

    Benefits: [
      {
        title: "Benefits of Nest js",
        Benefitstitle: "Development with Senwell",
        BenefitsofDevelopmentWithSenwell: [
          {
            Number: "01",
            title: "Dependency Injection",
            description:
              "Dependency injection is used by Nest.js to improve testability, resulting in code that is reliable and simple to test.",
          },
          {
            Number: "02",
            title: "Scalability",
            description:
              "Because Nest.js is designed to be scalable, applications can expand seamlessly as demand rises.",
          },
          {
            Number: "03",
            title: "Middleware Integration",
            description:
              "Performance is improved when middleware is integrated seamlessly and HTTP requests are handled effectively.",
          },
          {
            Number: "04",
            title: "Real-Time Applications",
            description:
              "The WebSocket gateway included in Nest.js makes it easier to create applications for bidirectional, real-time communication.",
          },
          {
            Number: "05",
            title: "Built-in Testing Utilities",
            description:
              "Nest.js ensures the dependability of your application by streamlining the testing process with integrated utilities.",
          },
          {
            Number: "06",
            title: "DevOps-Friendly",
            description:
              "Nest.js facilitates a DevOps-friendly environment by streamlining the development workflow with features like hot-reloading.",
          },
        ],
      },
    ],
    ProjectPortfollio: [
      {
        title: "Lumiere32- B2B Supplier Marketplace",
        image1: Lumiere,
        technologies: [" Angular", ", NestJs", "MySQL"],
        para: " We developed an effective web application that helps developers to write semantically correct code and form useful coding habits with better practices, superior tools, and proven methodologies.The application provides thorough data analysis and real-time statistics as well as features such as sorting errors by priority. The app is compatible with all popular desktop and mobile devices.",
      },
      {
        title: "Fork-Freight",
        image1: Fork,
        technologies: [" Angular", "Asp.Net"],
        para: "Trucking/Transportation Web application for transportation Industry.Fork Freight is an app which provides high-end, affordable services that will help carriers, shippers, brokers, and dispatchers grow their business.",
      },
      {
        title: "LottoSocial",
        image1: Lotto,
        technologies: [" Angular", "Ionic", "Node.js", "Firebase"],
        para: "Web Application to create and manage online lottery syndicates. Increase your chances of winning big and join a lottery syndicate. We made it simple to create and manage lottery syndicates online, offering a combination of ticket types.",
      },
      {
        title: "Fork-Freight",
        image1: Fork,
        technologies: [" Angular", "Asp.Net"],
        para: "Trucking/Transportation Web application for transportation Industry.Fork Freight is an app which provides high-end, affordable services that will help carriers, shippers, brokers, and dispatchers grow their business.",
      },

      {
        title: "Fooz",
        image1: Fooz,
        technologies: [" React", "Redux", "Node.js", "Bootstrap"],
        para: "The UAE-born, play-to-win online platform introduced by Arab Millionaire is offering participants the chance to “Dream Big, Give More” with the largest prizes in the Arab World",
      },
      {
        title: "VirusGeeks",
        image1: Virus,
        technologies: [" Angular", "NextJs", "NestJs"],
        para: "Healthcare Platform VirusGeeks, a BioHealth Technology platform, which provide health tech solutions such as end-to-end Covid-19 testing.",
      },
      {
        title: "Parsl",
        image1: parsl,
        technologies: [" Angular", "Node.js"],
        para: "Parsl is a platform that combines blockchain, NFC and IoT to create a track and trace technology that not only automates compliance efficiently and accurately but offers a range of tangible business benefits for every stage of the cannabis supply chain. ",
      },
      {
        title: "Cronage",
        image1: Cronage,
        technologies: [" Angular", "Bootstrap", "HTML", "CSS"],
        para: "  New businesses must strike while the iron is hot. Chasing after a fast time-to-market can make your business vulnerable to performance risks. We helped Distractify perform a fast but extremely thorough Quality Assurance process before they hit “publish” on their first viral story.",
      },
    ],
    OurClients: [
      {
        Image: cronage,
      },

      {
        Image: GreekVirous,
      },

      {
        Image: Lottosocialogo,
      },
      {
        Image: fooz,
      },
      {
        Image: Lumieres,
      },
      {
        Image: parslImg,
      },
      {
        Image: fork,
      },
    ],
    Testimonials: [
      {
        label1:
          "“They are incredibly client-focused, so we saw them as partners rather than service suppliers. Applying industry cutting edge- practices and knowledge of travel, real estate, technology, and software business domain they have managed to deliver the project on time and of the highest quality.”",
        img: "",
        name: "Derick, Hatchster Ltd. UK",
        post: "",
      },
      {
        label2:
          "“I’ve been working with Senwell Group Private Limited for several outsourcing projects for clients in Canada and US, and I  have to say that the guys here deliver really great work and oftentimes outperform the clients’ own  development teams. I’ve never met such productivity before and that’s probably one of the biggest,  although not the only one advantage of the company..”",
        img: "",
        name: "Kevin Atom, Property Hatch Ltd, United State",
        post: "",
      },
      {
        label3:
          "“We are enormously satisfied with Senwell Group Private Limited as contractor. Highly responsive team with  profound understanding of subject matter and really very, very fast code delivery that is particularly  important for our travel business. . ”",
        img: "",
        name: "Nicolo F, Italy",
        post: "",
      },
    ],
    GetInTouchSecondbanner: [
      {
        GetInTouchTitle:
          "Nest.js is a backend framework for building scalable and maintainable server-side applications.",
        Button: "Estimate my Project",
        image: NestBanner,
        para: "Our Nest.js developers don't blindly adhere to a single strategy. They meticulously evaluate every available option, choosing the one that best aligns with your prospective software solution.",
      },
    ],
    WhyChooseUs: [
      {
        WhyChooseUsTitle: "Why Choose Us",
        WhyChooseUs: [
          {
            icons: "",
            title: "Quick start",
            para: "Our work together usually starts in a few days because we work around bureaucratic procedures in our workflows.",
          },
          {
            icons: "",
            title: "Fair deal",
            para: "You are the only one who can determine how involved in the development you want to be, and all of our operations are completely transparent.",
          },
          {
            icons: "",
            title: "Flawless code",
            para: "We hold software testing and coding to the same high standards, and we are passionate about both. Our dedication to quality assurance should be in line with your expectations.",
          },
          {
            icons: "",
            title: "Industry experts",
            para: "Rest assured, should your project evolve into greater technical complexity over time, the Senwell Group Private Limited team will adeptly adjust to meet the challenge.",
          },
        ],
      },
    ],

    Forms: [
      {
        formTitle: "Get a quote today!",
        formSubtitle: "Enter your project details.",
      },
    ],
  },

  {
    id: 14,
    name: "Next",
    Technologies: "Front-end",
    HeroBanner: [
      {
        Page: " Next Development Services",
        name: "Next",
        title: "Hire Next Development",
        breadcrums: "Front-end",
        Link: "/technologies",
        button: "Hire Now",
        img: netBanner,
        image1: CmmiImage,
        image2: Union,
        image3: LogoImage,
        para: "Senwell Group Private Limited offers top-notch Next js development services. We create scalable and reliable Next js software to solve business problems and offer top-notch DevOps services that maximize benefits for a range of industry clients.",
      },
    ],

    skill: [
      {
        PerformanceTitle: "Seamless Performance. Exceptional User Experience.",
        PerformancePara:
          "Senwell Group Private Limited excels in providing cutting-edge Next.js development services, delivering scalable and reliable applications tailored to solve diverse business challenges. Our expertise extends to offering exceptional DevOps services, ensuring optimal benefits for clients across a wide array of industries.",
        PerformanceList: [
          {
            Numbers: "",
            PerformanceListTitle: "Engineers",
            PerformanceListPara:
              "Engage with us for a skilled Next.js team—middle and senior engineers committed to quality in every development facet.",
          },
          {
            Numbers: "",
            PerformanceListTitle: " Next js Projects",
            PerformanceListPara:
              "Explore our portfolio showcasing Next.js development excellence. Witness our dedication to impactful solutions and outsourcing service quality.",
          },
          {
            Numbers: "",
            PerformanceListTitle: "Years of Experience",
            PerformanceListPara:
              "Despite experience, our commitment to innovation is evident in enthusiastic Next.js support. Staying updated, we ensure projects leverage the latest tech, fostering efficiency—your reliable development partner.",
          },
        ],
      },
    ],
    TechnologiesExpertiesTitle: "Tech Stack ",
    TechnologiesExpertiesPara:
      "Proficient across diverse domains and cutting-edge technologies, we exhibit expertise in Next.js development. Our paramount goal in every assignment is to deliver substantial value to clients and the industry. This emphasizes our unwavering commitment to crafting immaculate code for optimal performance, serving as a testament to our dedication to excellence in each project",

    TechnologiesExperties: [
      {
        icon: (
          <img
            src={Nextjs}
            className="icon"
            style={{ i: "#4eb7ff" }}
            alt="NextjsNew"
            title="Next Js"
          />
        ),
        title: "Next js",
      },

      {
        icon: (
          <SiTypescript
            className="icon"
            style={{ i: "#4eb7ff", backgroundColor: "black", color: "yellow" }}
          />
        ),
        title: "TypeScript",
      },
      {
        icon: (
          <IoLogoJavascript
            className="icon"
            style={{ i: "#4eb7ff", backgroundColor: "yellow", color: "black" }}
          />
        ),
        title: "JavaScript",
      },
    ],
    DevelopmentStep: [
      {
        DevelopmentSteptitle: "How to Get Started",
        Developmentstep: [
          {
            icon: "",
            title: "Get to know us",
            description:
              "Share your front-end needs; our adept team will handle the rest. We specialize in crafting tailored solutions to elevate your digital presence.",
          },
          {
            icon: "",
            title: "Set an objective",
            description:
              "Design a scalable Next.js software for optimal efficiency and long-term upkeep. Industry standards are given top priority by our team, who guarantee efficiency from concept to exceed your expectations.",
          },
          {
            icon: "",
            title: "Form a team",
            description:
              "We put together a devoted group of knowledgeable Next.js developers who are ready to work on your project full-time.",
          },
          {
            icon: "",
            title: "Start working",
            description:
              "Once you've met and approved the team, the development process is poised for a fresh start!",
          },
        ],
      },
    ],
    GetInTouch: [
      {
        GetInTouchTitle:
          "Make your ideas a reality! You can rely on us as Next.js Engineers to translate your ideas into workable software solutions that are customized to meet your needs.",
        Button: "Get In Touch",
      },
    ],

    Benefits: [
      {
        title: "Benefits of Next js",
        Benefitstitle: "Development with Senwell",
        BenefitsofDevelopmentWithSenwell: [
          {
            Number: "01",
            title: "SEO-Friendly Architecture",
            description:
              "Optimize your search engine rankings by using static site generation.",
          },
          {
            Number: "02",
            title: "Improved Performance",
            description:
              "Utilize server-side rendering to improve performance and speed up page loads.",
          },
          {
            Number: "03",
            title: " TypeScript Support",
            description:
              "Gain improved code quality and maintainability with TypeScript integration.",
          },
          {
            Number: "04",
            title: "Flexibility in Gathering Data",
            description:
              "provides a variety of ways to retrieve data, meeting the requirements of various projects.",
          },
          {
            Number: "05",
            title: "Seamless Integration with APIs",
            description:
              "Integrate with backend services and APIs with ease to enable seamless communication.",
          },
          {
            Number: "06",
            title: "Integration of Vercel Deployment",
            description:
              "Simple hosting and deployment combined with a smooth integration with the Vercel platform.",
          },
        ],
      },
    ],
    ProjectPortfollio: [
      {
        title: "Lumiere32- B2B Supplier Marketplace",
        image1: Lumiere,
        technologies: [" Angular", ", NestJs", "MySQL"],
        para: " We developed an effective web application that helps developers to write semantically correct code and form useful coding habits with better practices, superior tools, and proven methodologies.The application provides thorough data analysis and real-time statistics as well as features such as sorting errors by priority. The app is compatible with all popular desktop and mobile devices.",
      },
      {
        title: "Fork-Freight",
        image1: Fork,
        technologies: [" Angular", "Asp.Net"],
        para: "Trucking/Transportation Web application for transportation Industry.Fork Freight is an app which provides high-end, affordable services that will help carriers, shippers, brokers, and dispatchers grow their business.",
      },
      {
        title: "LottoSocial",
        image1: Lotto,
        technologies: [" Angular", "Ionic", "Node.js", "Firebase"],
        para: "Web Application to create and manage online lottery syndicates. Increase your chances of winning big and join a lottery syndicate. We made it simple to create and manage lottery syndicates online, offering a combination of ticket types.",
      },
      {
        title: "Fork-Freight",
        image1: Fork,
        technologies: [" Angular", "Asp.Net"],
        para: "Trucking/Transportation Web application for transportation Industry.Fork Freight is an app which provides high-end, affordable services that will help carriers, shippers, brokers, and dispatchers grow their business.",
      },

      {
        title: "Fooz",
        image1: Fooz,
        technologies: [" React", "Redux", "Node.js", "Bootstrap"],
        para: "The UAE-born, play-to-win online platform introduced by Arab Millionaire is offering participants the chance to “Dream Big, Give More” with the largest prizes in the Arab World",
      },
      {
        title: "VirusGeeks",
        image1: Virus,
        technologies: [" Angular", "NextJs", "NestJs"],
        para: "Healthcare Platform VirusGeeks, a BioHealth Technology platform, which provide health tech solutions such as end-to-end Covid-19 testing.",
      },
      {
        title: "Parsl",
        image1: parsl,
        technologies: [" Angular", "Node.js"],
        para: "Parsl is a platform that combines blockchain, NFC and IoT to create a track and trace technology that not only automates compliance efficiently and accurately but offers a range of tangible business benefits for every stage of the cannabis supply chain. ",
      },
      {
        title: "Cronage",
        image1: Cronage,
        technologies: [" Angular", "Bootstrap", "HTML", "CSS"],
        para: "  New businesses must strike while the iron is hot. Chasing after a fast time-to-market can make your business vulnerable to performance risks. We helped Distractify perform a fast but extremely thorough Quality Assurance process before they hit “publish” on their first viral story.",
      },
    ],
    OurClients: [
      {
        Image: cronage,
      },

      {
        Image: GreekVirous,
      },

      {
        Image: Lottosocialogo,
      },
      {
        Image: fooz,
      },
      {
        Image: Lumieres,
      },
      {
        Image: parslImg,
      },
      {
        Image: fork,
      },
    ],
    Testimonials: [
      {
        label1:
          "“They are incredibly client-focused, so we saw them as partners rather than service suppliers. Applying industry cutting edge- practices and knowledge of travel, real estate, technology, and software business domain they have managed to deliver the project on time and of the highest quality.”",
        img: "",
        name: "Derick, Hatchster Ltd. UK",
        post: "",
      },
      {
        label2:
          "“I’ve been working with Senwell Group Private Limited for several outsourcing projects for clients in Canada and US, and I  have to say that the guys here deliver really great work and oftentimes outperform the clients’ own  development teams. I’ve never met such productivity before and that’s probably one of the biggest,  although not the only one advantage of the company..”",
        img: "",
        name: "Kevin Atom, Property Hatch Ltd, United State",
        post: "",
      },
      {
        label3:
          "“We are enormously satisfied with Senwell Group Private Limited as contractor. Highly responsive team with  profound understanding of subject matter and really very, very fast code delivery that is particularly  important for our travel business. . ”",
        img: "",
        name: "Nicolo F, Italy",
        post: "",
      },
    ],
    GetInTouchSecondbanner: [
      {
        GetInTouchTitle:
          "Next.js excels in scalable client apps with SSR and SSG.",
        Button: "Estimate my Project",
        image: NextBanner,
        para: "Our Next js developers don't follow one set approach to the letter. They carefully consider every option available, selecting the one that most closely matches your intended software solution.",
      },
    ],
    WhyChooseUs: [
      {
        WhyChooseUsTitle: "Why Choose Us",
        WhyChooseUs: [
          {
            icons: "",
            title: "Quick start",
            para: "Our collaboration typically begins within a few days as we navigate around bureaucratic procedures in our workflows.",
          },
          {
            icons: "",
            title: "Fair deal",
            para: "Your level of involvement in the development is entirely up to you, and we maintain complete transparency in all of our operations.",
          },
          {
            icons: "",
            title: "Flawless code",
            para: "We uphold coding and software testing to rigorous standards, driven by a shared passion for both. Your expectations for quality assurance align with our unwavering dedication.",
          },
          {
            icons: "",
            title: "Industry experts",
            para: "Rest assured, as your project advances in technical complexity over time, the Senwell Group Private Limited team will adeptly adapt to meet the evolving challenges",
          },
        ],
      },
    ],

    Forms: [
      {
        formTitle: "Get a quote today!",
        formSubtitle: "Enter your project details.",
      },
    ],
  },

  {
    id: 15,
    name: "Ios",
    Technologies: "Mobile",
    HeroBanner: [
      {
        Page: " Ios Development Services",
        name: "Ios",
        title: "Hire Ios Development",
        breadcrums: "Mobile",
        Link: "/Ios",
        button: "Hire Now",
        img: netBanner,
        image1: CmmiImage,
        image2: Union,
        image3: LogoImage,
        para: "Senwell Group Private Limited offers top-notch Ios development services. We create scalable and reliable Ios software to solve business problems and offer top-notch DevOps services that maximize benefits for a range of industry clients.",
      },
    ],

    skill: [
      {
        PerformanceTitle: "Quick Launch, High-Quality Code.",
        PerformancePara:
          "The Apple devices that are most widely used worldwide are the iPhone, iPad, and iMac. Numerous companies have created a plethora of iOS apps in response to their steadily rising popularity in order to keep up with the demand.",
        PerformanceList: [
          {
            Numbers: "",
            PerformanceListTitle: "Extensive experience",
            PerformanceListPara:
              "Years of dependable iOS application development services with a solid track record of satisfied clients and successful results",
          },
          {
            Numbers: "",
            PerformanceListTitle: "Accomplished professionals",
            PerformanceListPara:
              "Skilled iOS application developers who guarantee competent solutions with strong programming abilities and deep domain knowledge.",
          },
          {
            Numbers: "",
            PerformanceListTitle: "Trusted partnerships",
            PerformanceListPara:
              "Many businesses around the world have shown faith in our abilities by entrusting us with the development and maintenance of their applications.",
          },
        ],
      },
    ],
    TechnologiesExpertiesTitle: "Tech Stack ",
    TechnologiesExpertiesPara:
      "One of the mainstays of our development framework library is iOS. Our skilled iOS engineers are prepared to provide complete solutions catered to your requirements, regardless of the type of project you have in mind—from a simple project to a complex enterprise suite with cross-platform capabilities.",

    TechnologiesExperties: [
      {
        icon: (
          <DiSwift
            className="icon"
            style={{ backgroundColor: "red", color: "white" }}
          />
        ),
        title: "Swift",
      },

      {
        icon: <FaAndroid className="icon" style={{ color: "green" }} />,
        title: "Android",
      },
      {
        icon: <img src={Kotlin1} className="icon" alt="kotline" title="Kotlin"/>,
        title: "Kotlin",
      },
    ],

    DevelopmentStep: [
      {
        DevelopmentSteptitle: "How to Get Started",
        Developmentstep: [
          {
            icon: "",
            title: "Reach out to us",
            description:
              "Fill out the online contact form, providing as much information as possible about your needs.",
          },
          {
            icon: "",
            title: "Outline your goals",
            description:
              "After connecting with you and discussing the request, your assigned personal manager will now obtain more details.",
          },
          {
            icon: "",
            title: "Meet your team",
            description:
              "We carefully consider your request and provide you with a carefully selected list of candidates who best fit the position.",
          },
          {
            icon: "",
            title: "Get to work",
            description:
              "Following the team meeting and approval, the development process is prepared for an exciting start!",
          },
        ],
      },
    ],
    GetInTouch: [
      {
        GetInTouchTitle:
          "Ready to dominate the digital frontier? Create a robust native or cross-platform iOS app with our seasoned iOS engineers!",
        Button: "Get In Touch",
      },
    ],

    Benefits: [
      {
        title: "Building iOS App",
        Benefitstitle: "Development with Senwell",
        BenefitsofDevelopmentWithSenwell: [
          {
            Number: "01",
            title: "New employee trial periods are free",
            description:
              "We provide our clients the ability to evaluate candidates prior to making a final decision, giving them peace of mind that the work will be completed on schedule and within their allocated budget.",
          },
          {
            Number: "02",
            title: "Transparent code quality monitoring",
            description:
              "The use of the workstations and equipment belonging to our professionals is provided without charge. Additionally, you get access to more than 50 real devices for comprehensive app testing, ranging from smartphones to tablets.",
          },
          {
            Number: "03",
            title: "Rental of equipment is included",
            description:
              "The use of our professionals' workstations and equipment will not result in additional fees. You will also have exclusive access to a large pool of over 50 real devices, including tablets and smartphones, for app testing.",
          },
          {
            Number: "04",
            title: "Includes system administration",
            description:
              "We'll take care of all administrative and IT support duties, so you can relax and concentrate entirely on the success of your project. This entails taking care of other necessities, integrating project management tools, and supporting the establishment of smooth communication.",
          },
          {
            Number: "05",
            title:
              "When necessary, tailoring Senwell Group Private Limited and Company procedures",
            description:
              "Our highly qualified staff not only works in unison with yours but also adds to the improvement of development procedures, guaranteeing maximum project output.",
          },
          {
            Number: "06",
            title: "Unwanted resources are immediately replaced.",
            description:
              "Our process is incredibly careful when it comes to choosing people who are suited to our clients' needs. If there is ever a discrepancy, we promise to replace it as soon as possible with one that is appropriate and qualified. Our first goal is still making sure you are happy with our team.",
          },
        ],
      },
    ],
    ProjectPortfollio: [
      {
        title: "Lumiere32- B2B Supplier Marketplace",
        image1: Lumiere,
        technologies: [" Angular", ", NestJs", "MySQL"],
        para: " We developed an effective web application that helps developers to write semantically correct code and form useful coding habits with better practices, superior tools, and proven methodologies.The application provides thorough data analysis and real-time statistics as well as features such as sorting errors by priority. The app is compatible with all popular desktop and mobile devices.",
      },
      {
        title: "Fork-Freight",
        image1: Fork,
        technologies: [" Angular", "Asp.Net"],
        para: "Trucking/Transportation Web application for transportation Industry.Fork Freight is an app which provides high-end, affordable services that will help carriers, shippers, brokers, and dispatchers grow their business.",
      },
      {
        title: "LottoSocial",
        image1: Lotto,
        technologies: [" Angular", "Ionic", "Node.js", "Firebase"],
        para: "Web Application to create and manage online lottery syndicates. Increase your chances of winning big and join a lottery syndicate. We made it simple to create and manage lottery syndicates online, offering a combination of ticket types.",
      },
      {
        title: "Fork-Freight",
        image1: Fork,
        technologies: [" Angular", "Asp.Net"],
        para: "Trucking/Transportation Web application for transportation Industry.Fork Freight is an app which provides high-end, affordable services that will help carriers, shippers, brokers, and dispatchers grow their business.",
      },

      {
        title: "Fooz",
        image1: Fooz,
        technologies: [" React", "Redux", "Node.js", "Bootstrap"],
        para: "The UAE-born, play-to-win online platform introduced by Arab Millionaire is offering participants the chance to “Dream Big, Give More” with the largest prizes in the Arab World",
      },
      {
        title: "VirusGeeks",
        image1: Virus,
        technologies: [" Angular", "NextJs", "NestJs"],
        para: "Healthcare Platform VirusGeeks, a BioHealth Technology platform, which provide health tech solutions such as end-to-end Covid-19 testing.",
      },
      {
        title: "Parsl",
        image1: parsl,
        technologies: [" Angular", "Node.js"],
        para: "Parsl is a platform that combines blockchain, NFC and IoT to create a track and trace technology that not only automates compliance efficiently and accurately but offers a range of tangible business benefits for every stage of the cannabis supply chain. ",
      },
      {
        title: "Cronage",
        image1: Cronage,
        technologies: [" Angular", "Bootstrap", "HTML", "CSS"],
        para: "  New businesses must strike while the iron is hot. Chasing after a fast time-to-market can make your business vulnerable to performance risks. We helped Distractify perform a fast but extremely thorough Quality Assurance process before they hit “publish” on their first viral story.",
      },
    ],
    OurClients: [
      {
        Image: cronage,
      },

      {
        Image: GreekVirous,
      },

      {
        Image: Lottosocialogo,
      },
      {
        Image: fooz,
      },
      {
        Image: Lumieres,
      },
      {
        Image: parslImg,
      },
      {
        Image: fork,
      },
    ],
    Testimonials: [
      {
        label1:
          "“They are incredibly client-focused, so we saw them as partners rather than service suppliers. Applying industry cutting edge- practices and knowledge of travel, real estate, technology, and software business domain they have managed to deliver the project on time and of the highest quality.”",
        img: "",
        name: "Derick, Hatchster Ltd. UK",
        post: "",
      },
      {
        label2:
          "“I’ve been working with Senwell Group Private Limited for several outsourcing projects for clients in Canada and US, and I  have to say that the guys here deliver really great work and oftentimes outperform the clients’ own  development teams. I’ve never met such productivity before and that’s probably one of the biggest,  although not the only one advantage of the company..”",
        img: "",
        name: "Kevin Atom, Property Hatch Ltd, United State",
        post: "",
      },
      {
        label3:
          "“We are enormously satisfied with Senwell Group Private Limited as contractor. Highly responsive team with  profound understanding of subject matter and really very, very fast code delivery that is particularly  important for our travel business. . ”",
        img: "",
        name: "Nicolo F, Italy",
        post: "",
      },
    ],
    GetInTouchSecondbanner: [
      {
        GetInTouchTitle: "Got a concept? Let's dive in and bring it to life!",
        Button: "Estimate my Project",
        image: IosBanner,
        para: "Whether you need a single engineer or a whole team, our iOS developers are ready to show off their skills. To make sure Senwell Group Private Limited exactly matches your needs and expectations, start a pilot project!",
      },
    ],
    WhyChooseUs: [
      {
        WhyChooseUsTitle: "Why Choose Us",
        WhyChooseUs: [
          {
            icons: "",
            title: "Start with confidence..",
            para: "Our primary goal is to facilitate a swift launch and ensure that your experience with us will prompt a desire for future collaboration.",
          },
          {
            icons: "",
            title: "100% Transparency",
            para: "Transparent communication, adaptable pricing, and unwavering dedication to ensure your resounding success.",
          },
          {
            icons: "",
            title: "Cross-domain expertise",
            para: "Senwell's extensive portfolio showcases an array of projects spanning diverse industries, including healthcare, automotive, finance, and eCommerce.",
          },
          {
            icons: "",
            title: "Excellent Code Quality",
            para: "We meticulously assess the code's quality at each development stage to guarantee optimal results.",
          },
        ],
      },
    ],

    Forms: [
      {
        formTitle: "Get a quote today!",
        formSubtitle: "Enter your project details.",
      },
    ],
  },

  {
    id: 16,
    name: "Android",
    Technologies: "Android",
    HeroBanner: [
      {
        Page: " Android App Development Services",
        name: "Android",
        title: "Hire Android Development",
        breadcrums: "Mobile",
        Link: "/Android",
        button: "Hire Now",
        img: netBanner,
        image1: CmmiImage,
        image2: Union,
        image3: LogoImage,
        para: "Senwell Group Private Limited offers top-notch Android App development services. We create scalable and reliable Android  software to solve business problems and offer top-notch DevOps services that maximize benefits for a range of industry clients.",
      },
    ],

    skill: [
      {
        PerformanceTitle: "Quick Launch, High-Quality Code.",
        PerformancePara:
          "Since almost half of all smartphone users use Android apps, the Android platform is very important for creating any kind of mobile application. Senwell Group Private Limited is a reputable Android app development business committed to making your online experience better by providing engaging mobile applications.",
        PerformanceList: [
          {
            Numbers: "",
            PerformanceListTitle: "Extensive experience",
            PerformanceListPara:
              "Many years of dependable Android app development experience, a strong portfolio of satisfied clients, and positive results.",
          },
          {
            Numbers: "",
            PerformanceListTitle: "Accomplished professionals",
            PerformanceListPara:
              "Strong programming skills and a wide range of domain knowledge are attributes of competent Android application developers, who guarantee competent solutions.",
          },
          {
            Numbers: "",
            PerformanceListTitle: "Trusted partnerships",
            PerformanceListPara:
              "Many companies throughout the world have shown their faith in our abilities by giving us the responsibility for developing and managing their applications.",
          },
        ],
      },
    ],
    TechnologiesExpertiesTitle: "Tech Stack ",
    TechnologiesExpertiesPara:
      "Android is a key component of our development framework library. Our skilled Android engineers are prepared to provide complete solutions that are customized to your particular needs. We are prepared to realize your vision, no matter how complex the project or how simple it is. We offer cross-platform capabilities for our enterprise suite.",

    TechnologiesExperties: [
      {
        icon: <FaAndroid className="icon" style={{ color: "green" }} />,
        title: "Android",
      },
      {
        icon: <img src={Kotlin1} className="icon" alt="kotline" title="Kotlin"/>,
        title: "Kotlin",
      },

      {
        icon: (
          <img
            src={Java}
            className="icon"
            style={{ i: "#4eb7ff" }}
            alt="Java"
          />
        ),
        title: "Java",
      },

      {
        icon: <DiIonic className="icon" style={{ color: "blue" }} />,
        title: "Ionic",
      },
    ],

    DevelopmentStep: [
      {
        DevelopmentSteptitle: "How to Get Started",
        Developmentstep: [
          {
            icon: "",
            title: "Reach out to us",
            description:
              "Fill out the online form, providing as much information as possible about what you need.",
          },
          {
            icon: "",
            title: "Outline your goals",
            description:
              "After our conversation and in-depth review of your request, your personal manager will now collect more information.",
          },
          {
            icon: "",
            title: "Meet your team",
            description:
              "We carefully consider your request and provide you with a carefully selected list of candidates who have been carefully selected to best fit the role.",
          },
          {
            icon: "",
            title: "Get to work",
            description:
              "The development process is about to get underway with great excitement following the team meeting and unanimous approval!",
          },
        ],
      },
    ],
    GetInTouch: [
      {
        GetInTouchTitle:
          "Are you prepared to lead in the digital age? With the help of our skilled and knowledgeable Android engineers, create a robust app!",
        Button: "Get In Touch",
      },
    ],

    Benefits: [
      {
        title: "Benefits Android App",
        Benefitstitle: "Development with Senwell",
        BenefitsofDevelopmentWithSenwell: [
          {
            Number: "01",
            title: "Free trial periods for new employees.",
            description:
              "We give our clients the opportunity to interview candidates in advance of making a final decision, giving them peace of mind that the work will be finished on schedule and within the allocated budget.",
          },
          {
            Number: "02",
            title: "Transparent monitoring of code quality",
            description:
              "Engaging Senwell Group Private Limited gives you access to our unique tools, which offer crucial code quality metrics to help your project standardize and continuously maintain ideal standards for code quality.",
          },
          {
            Number: "03",
            title: "Equipment rental included",
            description:
              "Using the workstations and equipment of our professionals is not subject to any additional fees. You also get access to more than 51 real devices for comprehensive app testing, ranging from tablets to smartphones.",
          },
          {
            Number: "04",
            title: "System management incorporated.",
            description:
              "We'll take care of all administrative and IT support duties, including helping you set up efficient communication channels and incorporate state-of-the-art project management tools, so you can relax and concentrate entirely on the success of your project.",
          },
          {
            Number: "05",
            title: "Company processes when required",
            description:
              "Our highly qualified staff can work quickly and easily with your team to improve development processes for maximum project productivity.",
          },
          {
            Number: "06",
            title: "Unwanted resources are immediately replaced.",
            description:
              "We take great care when choosing people for our clients. In the event of inconsistencies, a prompt and appropriate substitute is ensured.",
          },
        ],
      },
    ],
    ProjectPortfollio: [
      {
        title: "Lumiere32- B2B Supplier Marketplace",
        image1: Lumiere,
        technologies: [" Angular", ", NestJs", "MySQL"],
        para: " We developed an effective web application that helps developers to write semantically correct code and form useful coding habits with better practices, superior tools, and proven methodologies.The application provides thorough data analysis and real-time statistics as well as features such as sorting errors by priority. The app is compatible with all popular desktop and mobile devices.",
      },
      {
        title: "Fork-Freight",
        image1: Fork,
        technologies: [" Angular", "Asp.Net"],
        para: "Trucking/Transportation Web application for transportation Industry.Fork Freight is an app which provides high-end, affordable services that will help carriers, shippers, brokers, and dispatchers grow their business.",
      },
      {
        title: "LottoSocial",
        image1: Lotto,
        technologies: [" Angular", "Ionic", "Node.js", "Firebase"],
        para: "Web Application to create and manage online lottery syndicates. Increase your chances of winning big and join a lottery syndicate. We made it simple to create and manage lottery syndicates online, offering a combination of ticket types.",
      },
      {
        title: "Fork-Freight",
        image1: Fork,
        technologies: [" Angular", "Asp.Net"],
        para: "Trucking/Transportation Web application for transportation Industry.Fork Freight is an app which provides high-end, affordable services that will help carriers, shippers, brokers, and dispatchers grow their business.",
      },

      {
        title: "Fooz",
        image1: Fooz,
        technologies: [" React", "Redux", "Node.js", "Bootstrap"],
        para: "The UAE-born, play-to-win online platform introduced by Arab Millionaire is offering participants the chance to “Dream Big, Give More” with the largest prizes in the Arab World",
      },
      {
        title: "VirusGeeks",
        image1: Virus,
        technologies: [" Angular", "NextJs", "NestJs"],
        para: "Healthcare Platform VirusGeeks, a BioHealth Technology platform, which provide health tech solutions such as end-to-end Covid-19 testing.",
      },
      {
        title: "Parsl",
        image1: parsl,
        technologies: [" Angular", "Node.js"],
        para: "Parsl is a platform that combines blockchain, NFC and IoT to create a track and trace technology that not only automates compliance efficiently and accurately but offers a range of tangible business benefits for every stage of the cannabis supply chain. ",
      },
      {
        title: "Cronage",
        image1: Cronage,
        technologies: [" Angular", "Bootstrap", "HTML", "CSS"],
        para: "  New businesses must strike while the iron is hot. Chasing after a fast time-to-market can make your business vulnerable to performance risks. We helped Distractify perform a fast but extremely thorough Quality Assurance process before they hit “publish” on their first viral story.",
      },
    ],
    OurClients: [
      {
        Image: cronage,
      },

      {
        Image: GreekVirous,
      },

      {
        Image: Lottosocialogo,
      },
      {
        Image: fooz,
      },
      {
        Image: Lumieres,
      },
      {
        Image: parslImg,
      },
      {
        Image: fork,
      },
    ],
    Testimonials: [
      {
        label1:
          "“They are incredibly client-focused, so we saw them as partners rather than service suppliers. Applying industry cutting edge- practices and knowledge of travel, real estate, technology, and software business domain they have managed to deliver the project on time and of the highest quality.”",
        img: "",
        name: "Derick, Hatchster Ltd. UK",
        post: "",
      },
      {
        label2:
          "“I’ve been working with Senwell Group Private Limited for several outsourcing projects for clients in Canada and US, and I  have to say that the guys here deliver really great work and oftentimes outperform the clients’ own  development teams. I’ve never met such productivity before and that’s probably one of the biggest,  although not the only one advantage of the company..”",
        img: "",
        name: "Kevin Atom, Property Hatch Ltd, United State",
        post: "",
      },
      {
        label3:
          "“We are enormously satisfied with Senwell Group Private Limited as contractor. Highly responsive team with  profound understanding of subject matter and really very, very fast code delivery that is particularly  important for our travel business. . ”",
        img: "",
        name: "Nicolo F, Italy",
        post: "",
      },
    ],
    GetInTouchSecondbanner: [
      {
        GetInTouchTitle:
          "Have an original idea? Let's explore its nuances and make it come to life!",
        Button: "Estimate my Project",
        image: AndroidBanner,
        para: "Our skilled Android developers are ready to demonstrate their abilities, whether you need a single engineer or a whole team. To make sure Senwell Group Private Limited meets your requirements and expectations exactly, start a pilot project.",
      },
    ],
    WhyChooseUs: [
      {
        WhyChooseUsTitle: "Why Choose Us",
        WhyChooseUs: [
          {
            icons: "",
            title: "Start with confidence..",
            para: "Our main objective is to expedite the launch and make sure that working with us will make you want to work with us again in the future.",
          },
          {
            icons: "",
            title: "100% Transparency",
            para: "Open communication, flexible payment options, and unwavering commitment to guarantee your triumphant achievement.",
          },
          {
            icons: "",
            title: "Excellent Code Quality",
            para: "In addition to QA, we guarantee that your code is clear and devoid of technical debt.",
          },
          {
            icons: "",
            title: "Cross-domain expertise",
            para: "We are confident and incredibly adept at handling any challenge.",
          },
        ],
      },
    ],

    Forms: [
      {
        formTitle: "Get a quote today!",
        formSubtitle: "Enter your project details.",
      },
    ],
  },

  {
    id: 17,
    name: "Flutter",
    Technologies: "Android",
    HeroBanner: [
      {
        Page: " Flutter App Development Services",
        name: "Flutter",
        title: "Hire Flutter Development",
        breadcrums: "Mobile",
        Link: "/Flutter",
        button: "Hire Now",
        img: netBanner,
        image1: CmmiImage,
        image2: Union,
        image3: LogoImage,
        para: "Senwell Group Private Limited offers top-notch Flutter App development services. We create scalable and reliable Flutter  software to solve business problems and offer top-notch DevOps services that maximize benefits for a range of industry clients.",
      },
    ],

    skill: [
      {
        PerformanceTitle: "Swift Deployment, Exemplary Code Quality.",
        PerformancePara:
          "Flutter is a programming language that primarily uses Dart to present customizable widgets. Senwell Group Private Limited offers a wide range of state-of-the-art app development services and is a leading Flutter development company. Using the unique features that Flutter provides, we quickly and skillfully create native interfaces that are of the highest caliber on all platforms.",
        PerformanceList: [
          {
            Numbers: "",
            PerformanceListTitle: "Extensive experience",
            PerformanceListPara:
              "Several years of solid experience developing Flutter apps, a solid clientele, and fruitful outcomes.",
          },
          {
            Numbers: "",
            PerformanceListTitle: "Accomplished professionals",
            PerformanceListPara:
              "Seasoned Flutter application engineers who guarantee excellence in every project with their strong programming abilities and broad knowledge across a variety of domains.",
          },
          {
            Numbers: "",
            PerformanceListTitle: "Trusted partnerships",
            PerformanceListPara:
              "Many businesses all over the world have given us the development and management of their applications, demonstrating their faith in our abilities.",
          },
        ],
      },
    ],
    TechnologiesExpertiesTitle: "Tech Stack ",
    TechnologiesExpertiesPara:
      "One of the main components of our development framework library is Flutter. Our skilled Flutter engineers are prepared to provide solutions that are specifically designed to meet your needs. Regardless of how simple or complicated the project is, we are prepared to realize your vision.",

    TechnologiesExperties: [
      {
        icon: (
          <img
            src={Flutter}
            className="icon"
            style={{ i: "#4eb7ff" }}
            alt="Flutter"
            title="Flutter"
          />
        ),
        title: "Flutter",
      },
      {
        icon: (
          <img
            src={Dart}
            className="icon"
            style={{ i: "#4eb7ff" }}
            alt="Dart"
            title="Dart"
          />
        ),
        title: "Dart",
      },
    ],

    DevelopmentStep: [
      {
        DevelopmentSteptitle: "How to Get Started",
        Developmentstep: [
          {
            icon: "",
            title: "Reach out to us",
            description:
              "Fill out the online form on our website, providing as much information as possible about your preferences and needs.",
          },
          {
            icon: "",
            title: "Outline your goals",
            description:
              "After our lengthy discussion and careful examination of your request, your assigned personal manager will now compile any more relevant data.",
          },
          {
            icon: "",
            title: "Meet your team",
            description:
              "We carefully consider your request and provide you with a carefully selected list of candidates who best fit the job description.",
          },
          {
            icon: "",
            title: "Get to work",
            description:
              "After the team meeting and unanimous approval, the development process is about to get underway with great excitement!",
          },
        ],
      },
    ],
    GetInTouch: [
      {
        GetInTouchTitle:
          "Empower your journey in the digital age by leveraging our adept and knowledgeable team of Flutter engineers to craft a resilient and feature-rich app!",
        Button: "Get In Touch",
      },
    ],

    Benefits: [
      {
        title: "Benefits of Flutter App",
        Benefitstitle: "Development with Senwell",
        BenefitsofDevelopmentWithSenwell: [
          {
            Number: "01",
            title: "Free trial periods for new employees.",
            description:
              "We provide our clients with the opportunity to trial potential candidates before they decide on one, so you can be confident that we will successfully complete the project on time and within the allocated budget.",
          },
          {
            Number: "02",
            title: "Transparent monitoring of code quality",
            description:
              "When you work with Senwell Group Private Limited, you get access to our proprietary toolkit, which includes crucial metrics for code quality that will help your project maintain and develop coding standards.",
          },
          {
            Number: "03",
            title: "Equipment rental included",
            description:
              "The use of our professionals' workstations and equipment is provided without additional cost. You also get access to more than 50 real devices for app testing, which includes tablets and smartphones.",
          },
          {
            Number: "04",
            title: "System management incorporated.",
            description:
              "You can relax knowing that we will take care of all administrative and IT support, including helping to set up effective channels of communication and incorporating cutting-edge project management software, so your entire focus can be on the success of your project.",
          },
          {
            Number: "05",
            title: "Company processes when required",
            description:
              "Our highly qualified staff members quickly and easily become a part of your team while also improving development processes to guarantee the highest level of project productivity.",
          },
          {
            Number: "06",
            title: "Unwanted resources are immediately replaced.",
            description:
              "In the meticulous process of selecting the most exceptional individuals for our client's requirements, we exercise utmost diligence. Should any discrepancy arise, we will swiftly replace it with a qualified alternative.",
          },
        ],
      },
    ],
    ProjectPortfollio: [
      {
        title: "Lumiere32- B2B Supplier Marketplace",
        image1: Lumiere,
        technologies: [" Angular", ", NestJs", "MySQL"],
        para: " We developed an effective web application that helps developers to write semantically correct code and form useful coding habits with better practices, superior tools, and proven methodologies.The application provides thorough data analysis and real-time statistics as well as features such as sorting errors by priority. The app is compatible with all popular desktop and mobile devices.",
      },
      {
        title: "Fork-Freight",
        image1: Fork,
        technologies: [" Angular", "Asp.Net"],
        para: "Trucking/Transportation Web application for transportation Industry.Fork Freight is an app which provides high-end, affordable services that will help carriers, shippers, brokers, and dispatchers grow their business.",
      },
      {
        title: "LottoSocial",
        image1: Lotto,
        technologies: [" Angular", "Ionic", "Node.js", "Firebase"],
        para: "Web Application to create and manage online lottery syndicates. Increase your chances of winning big and join a lottery syndicate. We made it simple to create and manage lottery syndicates online, offering a combination of ticket types.",
      },
      {
        title: "Fork-Freight",
        image1: Fork,
        technologies: [" Angular", "Asp.Net"],
        para: "Trucking/Transportation Web application for transportation Industry.Fork Freight is an app which provides high-end, affordable services that will help carriers, shippers, brokers, and dispatchers grow their business.",
      },

      {
        title: "Fooz",
        image1: Fooz,
        technologies: [" React", "Redux", "Node.js", "Bootstrap"],
        para: "The UAE-born, play-to-win online platform introduced by Arab Millionaire is offering participants the chance to “Dream Big, Give More” with the largest prizes in the Arab World",
      },
      {
        title: "VirusGeeks",
        image1: Virus,
        technologies: [" Angular", "NextJs", "NestJs"],
        para: "Healthcare Platform VirusGeeks, a BioHealth Technology platform, which provide health tech solutions such as end-to-end Covid-19 testing.",
      },
      {
        title: "Parsl",
        image1: parsl,
        technologies: [" Angular", "Node.js"],
        para: "Parsl is a platform that combines blockchain, NFC and IoT to create a track and trace technology that not only automates compliance efficiently and accurately but offers a range of tangible business benefits for every stage of the cannabis supply chain. ",
      },
      {
        title: "Cronage",
        image1: Cronage,
        technologies: [" Angular", "Bootstrap", "HTML", "CSS"],
        para: "  New businesses must strike while the iron is hot. Chasing after a fast time-to-market can make your business vulnerable to performance risks. We helped Distractify perform a fast but extremely thorough Quality Assurance process before they hit “publish” on their first viral story.",
      },
    ],
    OurClients: [
      {
        Image: cronage,
      },

      {
        Image: GreekVirous,
      },

      {
        Image: Lottosocialogo,
      },
      {
        Image: fooz,
      },
      {
        Image: Lumieres,
      },
      {
        Image: parslImg,
      },
      {
        Image: fork,
      },
    ],
    Testimonials: [
      {
        label1:
          "“They are incredibly client-focused, so we saw them as partners rather than service suppliers. Applying industry cutting edge- practices and knowledge of travel, real estate, technology, and software business domain they have managed to deliver the project on time and of the highest quality.”",
        img: "",
        name: "Derick, Hatchster Ltd. UK",
        post: "",
      },
      {
        label2:
          "“I’ve been working with Senwell Group Private Limited for several outsourcing projects for clients in Canada and US, and I  have to say that the guys here deliver really great work and oftentimes outperform the clients’ own  development teams. I’ve never met such productivity before and that’s probably one of the biggest,  although not the only one advantage of the company..”",
        img: "",
        name: "Kevin Atom, Property Hatch Ltd, United State",
        post: "",
      },
      {
        label3:
          "“We are enormously satisfied with Senwell Group Private Limited as contractor. Highly responsive team with  profound understanding of subject matter and really very, very fast code delivery that is particularly  important for our travel business. . ”",
        img: "",
        name: "Nicolo F, Italy",
        post: "",
      },
    ],
    GetInTouchSecondbanner: [
      {
        GetInTouchTitle:
          "Got a spark of inspiration? Come on, let's unleash our creativity!",
        Button: "Estimate my Project",
        image: FlutterBanner,
        para: "Regardless of the size of your team—one engineer or several—our skilled Flutter developers are ready to demonstrate their prowess. Start a pilot project so that Senwell Group Private Limited can make sure it exactly fits your needs and expectations.",
      },
    ],
    WhyChooseUs: [
      {
        WhyChooseUsTitle: "Why Choose Us",
        WhyChooseUs: [
          {
            icons: "",
            title: "Database Decision-Making",
            para: "We use the information we've gathered to create creative solutions that improve the experiences of your end users while staying forward-thinking enough to adjust to the changing market dynamics.",
          },
          {
            icons: "",
            title: "Assertive Communication",
            para: "Our precise and open approach ensures assertive communication with external and internal stakeholders, resulting in a clear understanding of business goals.",
          },
          {
            icons: "",
            title: "Robust architecture",
            para: "Senwell Group Private Limited builds solutions with a specific focus on system adaptability, sustainability, and optimization. It also places a strong emphasis on important aspects of software development.",
          },
          {
            icons: "",
            title: "Proven Track Record",
            para: "By providing our clients with top-notch solutions, we have strengthened our already impressive track record. Check out our portfolio for a thorough overview.",
          },
        ],
      },
    ],

    Forms: [
      {
        formTitle: "Get a quote today!",
        formSubtitle: "Enter your project details.",
      },
    ],
  },
];
export { TechnologiesData };
