import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "../../../Pages/Services/DigitalMarketing/DigitalCarousel.css"
import { dataDigitalBestSeller } from '../../../data/DigitalData.js';

function DigitalCarousel() {
  const [defaultImage, setDefaultImage] = useState({});
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    initialSlide: 0,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="container" style={{left:"0",border:"none"}}>
      <h2 className="market-header">What we do?</h2>
      <div className="App1">
        <Slider {...settings}>
          {dataDigitalBestSeller?.map((item) => (
            <div className="card-top">
              <img
                src={item.linkImg}
                alt={"marketing"}
                title={"digital marketing"}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default DigitalCarousel;