import React from "react";
import { Grid, Container, Typography } from "@material-ui/core";
import { WorkflowImage } from "../../../images/images";
import { makeStyles } from "@material-ui/core";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import QAprocessfinish1 from "../../../../src/images/Services/QAprocessfinish1.png";
import "../../../Modules/Services/Testing&QA/OurQAProcess.css";

const useStyles = makeStyles((theme) => ({
  ImageWrapper: {
    // marginTop: "1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    // padding: "1rem",
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center'
    },
  },
  Image: {
    width: "70%",
    height: "auto",
    objectFit: "contain",
  },
  ContentWraper: {
    padding: "10rem 0rem 0rem 6rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 1rem 0rem 1rem",
    },
  },
  WorkflowTitle: {
    fontSize: "36px",
    lineHeight: "56px",
    fontWeight: "600",
    fontFamily: "'Manrope', sans-serif",

    [theme.breakpoints.down('sm')]: {
      fontSize: "33px",
      fontWeight: "600",
      lineHeight: "47px",
      marginBottom: "10px",
      textAlign:"center",
    },
  },
  WorkflowPara: {
    padding: "1.2rem 0rem 0rem 0rem",
    fontSize: "17px",
    fontWeight: 400,
    textAlign: "justify",
    fontFamily: "'Manrope', sans-serif",

    [theme.breakpoints.down('sm')]: {
      fontSize: "16px",
      wordSpacing: "-0.1em",
      hyphens: "auto",
    },
  },

  WorkflowsubPara: {
    color: "#5a5a5a",
    fontSize: "17px",
    fontWeight: 400,
    padding: "0px 5px",
    fontFamily: "'Manrope', sans-serif",
  },
  newDesk: {
    [theme.breakpoints.up('sm')]: {
      width: '90%',
      margin: 'auto',
      padding: '1rem 0'
    },
  },
  StartFastText: {
    color: "#3D8ED7",
  },
  Mainwrapper: {
    marginBottom: "2rem",
    backgroundColor: "#f2f2f2",
  },
}));
const OurQAProcess = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  const classNamees = useStyles();
  return (
    <>
      <div
        data-aos="fade-up"
        data-aos-duration="2000"
        className={classNamees.Mainwrapper}
      >
        <Container maxWidth="lg">
          <div className={classNamees.newDesk}>
            <Grid container>
              <Grid xs={12} sm={6}>
                <div className={classNamees.ContentWraper}>
                  <Typography variant="h3" className={classNamees.WorkflowTitle}>
                    Our QA Process
                  </Typography>
                  <p className={classNamees.WorkflowPara}>
                    We default to Agile as an industry-standard development
                    model. However, because every software requires a different
                    methodology and some businesses demand a specialized
                    validation process, we also work with Waterfall, V-Model,
                    Spiral, and Iterative models.
                  </p>
                  <p className={classNamees.WorkflowPara}>
                    We strategize based on your company goals, arrange software
                    QA services based on your project requirements, and generate
                    test documentation with maximum clarity.
                  </p>
                  <p className={classNamees.WorkflowPara}>
                    No stalling. No vendor lock.
                  </p>
                </div>
              </Grid>
              <Grid xs={12} sm={6} style={{display:" flex",justifyContent:"center",alignItems: "center"}}>
                <div className={classNamees.ImageWrapper}>
                  <img src={QAprocessfinish1} className={classNamees.Image} style={{width:"90%"}} id="qaprocess-image" alt="QAprocessfinish1" title="QA process"/>
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
    </>
  );
};

export default OurQAProcess;
