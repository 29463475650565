import React, { useEffect, useState } from "react";
import { Container, Grid, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Person, ArrowForward, LocationOn } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore/lite";
import db from "../../../Pages/Components/firebase.config";
import { data } from "autoprefixer";

const useStyle = makeStyles((theme) => ({
  hiringHeading: {
    fontSize: "2.125rem",
    fontWeight: 400,
    paddingTop: "0rem",
    lineHeight: 1.235,
    fontFamily: "'Manrope', sans-serif",

    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
      fontWeight: "400",
      lineHeight: "1.235",
      paddingTop: "0rem",
      marginBottom: "26px",
    },
  },
  hiringParagraph: {
    fontFamily: "'Manrope', sans-serif",
    fontSize: "18px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "17px",
      textAlign: "center",
    },
  },
  jobLocation: {
    display: "flex",
    margin: "1rem 0 0 0",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
  },
  jobList: {
    backgroundColor: "#fafafa",
    padding: "1rem",
    margin: "1rem",
    height: "77%",
    width: "90%",
    borderBottom: "4px solid #fafafa",
    boxShadow: "1px 1px 10px 0px #bfb6b6",
    "&:hover": {
      borderBottom: "4px solid #2191cd",
    },
    [theme.breakpoints.down("sm")]: {
      height: "88%",
    },
  },
  designation: {
    display: "flex",
    justifyContent: "space-between",
    fontFamily: "'Manrope', sans-serif",
    fontSize: "23px",
  },
  Textdesignation: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "17px",
    },
  },
  location: {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
    alignItems: "center",
    fontFamily: "'Manrope', sans-serif",
  },
  designationLocation: {
    display: "flex",
    alignItems: "center",
    fontFamily: "'Manrope', sans-serif",
  },
  cities: {
    margin: "0 3px 10px 3px",
    padding: "4px 10px 4px 10px",
    boxShadow: "3px 3px 5px -1px #94a4ba",
    fontWeight: "600",
    fontFamily: "'Manrope', sans-serif",
    backgroundColor: "#eeeeee",
    borderRadius: "10px",
  },
  workingCity: {
    margin: "0 3px 0 3px",
    fontSize: "15px",
    fontWeight: "600",
  },
  MainWrapper: {},
  openingsWrapper: {
    marginTop: "1rem",
    padding: "0rem 5rem 4rem 5rem",
    width: "90%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      padding: "2rem",
    },
  },
  opening: {
    fontFamily: "'Manrope', sans-serif",
    fontSize: "23px",
  },
}));

const HiringPage = () => {
  const classNamees = useStyle();
  const [jobposition, setjobposition] = useState([]);
  const postref = collection(db, "jobpost");
  useEffect(() => {
    const getdata = async () => {
      const data = await getDocs(postref);
      // console.log("the res are ", data);
      setjobposition(data.docs?.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getdata();
  }, []);

  return (
    <div>
      <Container
        maxWidth="lg"
        className={classNamees.openingsWrapper}
        id="openings"
      >
        <h2 className={classNamees.hiringHeading}>
          We are Looking out for you!
        </h2>
        <p className={classNamees.hiringParagraph}>
          Find a job that you are looking for and let’s grow each other
        </p>
        <Grid container justifyContent="space-between">
          {jobposition
            .filter((i) => i.isactive === true)
            ?.sort((b, a) => a.srNo - b.srNo)
            ?.map((data, index) => (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className={classNamees.jobList}>
                  <div className={classNamees.designation}>
                    <h3 className={classNamees.Textdesignation}>
                      {data.designation}
                    </h3>
                    <Person style={{ width: "45px", height: "45px" }} />
                  </div>
                  <h4 className={classNamees.opening}> </h4>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <LocationOn
                        style={{
                          width: "30px",
                          height: "30px",
                          color: "#3D8ED7",
                          fontFamily: "'Manrope', sans-serif",
                        }}
                      />
                      <div className={classNamees.designationLocation}>
                        <span className={classNamees.workingCity}>
                          Kharadi, Pune - 411014
                        </span>
                      </div>
                    </div>
                    <Link
                      to={`/job/jobposition/${data.id}`}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <h6 style={{ color: "#3D8ED7" }}>learn more</h6>
                        <span>
                          <ArrowForward
                            style={{
                              width: "54px",
                              height: "25px",
                              color: "#3D8ED7",
                            }}
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                </Box>
              </Grid>
            ))}
        </Grid>
      </Container>
    </div>
  );
};

export default HiringPage;
