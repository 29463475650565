import React from "react";
import "../SharedModule/Achievments.css";
import clutch from "../images/Award/Clutch1.png";
import ISTQB from "../images/Award/ISTQB1.png";
import IOS27 from "../images/Award/IOS27.png";
import TopIt from "../images/Award/TopCompany.png";

const Achievements = () => {
  return (
    <>
      <section style={{ padding: "50px 0px", backgroundColor: "black" }}>
        <div className="container" style={{ left: "0", border: "none" }}>
          <div className="row">
            <div className="col-lg-4 col-md-12 Rewardsfirst-h3">
              <h3 className="Rewardsfirst-h3">Rewards &</h3>
              <h3 className="Recognitionsecond-h3"> Recognition</h3>
            </div>
            <div className="col-lg-8 col-md-12">
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-12 second-sub-div">
                  <img
                    src={clutch}
                    className="partners-img"
                    style={{ width: "90%" }}
                    alt="imglogo"
                    title="clutch logo"
                  />
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 second-sub-div d-flex justify-content-center flex-column align-items-center">
                  <img
                    src={ISTQB}
                    alt="Img"
                    title="ISTQB logo"
                    className="partners-img"
                    style={{ width: "90%" }}
                  />
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 second-sub-div">
                  <img
                    src={IOS27}
                    alt="Img"
                    title="IOS27 logo"
                    className="partners-img"
                    style={{ width: "90%" }}
                  />
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 second-sub-div">
                  <img
                    src={TopIt}
                    className="partners-img"
                    title="TopIt"
                    alt="Img"
                    style={{ width: "90%", marginTop: "10px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Achievements;
