import React from "react";
import { makeStyles } from "@material-ui/core";
import { Container, Grid } from "@material-ui/core";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import useMediaQuery from "@mui/material/useMediaQuery";
const useStyles = makeStyles({
  heading: {
    marginBottom: "2rem",
    fontSize: "32px",
    lineHeight: "24px",
    fontWeight: "600",
    textAlign: "center",
    
    fontFamily: "Alatsi, sans-serif !important",
  },
  para: {
    fontSize: "18px",
    lineHeight: "21px",
    fontFamily: "Alatsi, sans-serif !important",
    textAlign: "justify",
  },
  closeIcon: {
    position: "absolute",
    top: "1rem",
    right: "1rem",
    cursor: "pointer",
    color: "#000",
    
  },
  imgBox: {
    width: "100%",
    "& img": {
      borderRadius: "10px",
      width: " 100%",
      height: "100%",
    },
  },
});

const EstimateForm = ({ open, close, data }) => {
  const classNamees = useStyles();
  const isMobile = useMediaQuery("(min-width:600px)");
  const [checked, setChecked] = React.useState(true);

  const handleChecked = (event) => {
    setChecked(event.target.checked);
  };
  
  const style = {
    position: "absolute",
    
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
    
  };

  return (
    <Container maxWidth="lg">
      <div className={classNamees.formContainer}>
        <Modal
          open={open}
          // aria-labelledby="modal-modal-title"
          // aria-describedby="modal-modal-description"
          aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
        >
          <Box sx={style}>
            <h1 className={classNamees.heading}>{data.exploretitle}</h1>
            <Grid container spacing={5} className={classNamees.infoWrapper}>
              {isMobile && (
                <Grid item xs={12} sm={12} md={6} className={classNamees.imgBox}>
                  <img src={data.img} alt="mobile screen" title="modal"/>
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={6} className={classNamees.textBox}>
                <p className={classNamees.para}>{data.exploresubtitle}</p>
                <p className={classNamees.para}>{data.explorepara}</p>
              </Grid>
              <CloseIcon
                className={classNamees.closeIcon}
                onClick={() => close(false)}
              />
            </Grid>
          </Box>
        </Modal>
      </div>
    </Container>
  );
};

export default EstimateForm;
